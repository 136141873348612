import React, { useEffect, useState } from "react";
import classes from "./InsideHubSection.module.css";
import SpaceComponent from "./SpaceComponent";


const InsideHubSection = () => {


    useEffect(()=> {
        let timer = setInterval(automate, 2000);

        return () => {
            clearInterval(timer);
        };

    });
   

    const automate = () => {
        setCounter(counter < 5 ? counter + 1 : 0);
    };



    const [counter, setCounter] = useState(0);

    

    const left = (window.innerWidth <= 1024) && (counter < 5) && -368 * counter + "px";



    const spaces = [
                        {
                            key: 1,
                            img_src: "two_sitter.png",
                            title: "2 Sitter Cubicle",
                            to: "/",
                            space_body: "The two sitter cubible is build to house two people per business. ",
                        },
                        {
                            key: 2,
                            img_src: "personal.png",
                            title: "a personal Box",
                            to: "/",
                            space_body: "A space just for you only.",
                        },
                        {
                            key: 3,
                            img_src: "meeting_room.png",
                            title: "A meeting Room",
                            to: "/",
                            space_body: "20 people or more can use this space.",
                        },
                        {
                            key: 4,
                            img_src: "sound_proof.png",
                            title: "Sound Proof Office",
                            to: "/",
                            space_body: "For discrete meetings , this room is most preferable.",
                        },
                        {
                            key: 5,
                            img_src: "board_room.png",
                            title: "Board Room",
                            to: "/",
                            space_body: "For Important board meetings, this space is yours.",
                        },
                        {
                            key: 6,
                            img_src: "private_room.png",
                            title: "Private Room",
                            to: "/",
                            space_body: "A similar room to the personal box.",
                        },

                    ];

    return (
        <div className={classes['container']}>
            <div className={classes['heading']}>
                <p className={classes['title']}>Inside the Hub</p>
            </div>
            <p className={classes['text_body']}>We've created a thriving ecosystem of resources specially designed to empower women entrepreneurs. At the heart of this empowering space, you'll discover our state-of-the-art co-working spaces, each carefully curated to provide a vibrant atmosphere that fuels productivity, creativity, and collaboration.</p>
            <div className={classes['sub_container']} style={{marginLeft: left}}>
                {spaces.map((space) => <SpaceComponent space = {space} key = {space.key} />)}
                
            </div>
        </div>
    );
};

export default InsideHubSection;