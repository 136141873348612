import React from "react";
import classes from "./testimonal.module.css";

const BussSuppTestimonial = () => {
    return (
        <div className={classes['banner__container']}>
            <p className={classes['title']}>Testimonials</p>
            <div className={classes['test_row']}>

                <div className={classes['test_col']} >
                   <p className={classes['word_three']}>"After my Dream to business program with Shecluded, I got my business registered, my business revenue increased and I also learnt how to perfect my pitch,  by stating the problem my Business was solving - which is what made me a N500,000 grant, thank you Shecluded team, for preparing me” </p>
                   <div className={classes['test_speaker']} >
                   <div className={classes['']} >
                   <img src="/images/buss_supp_test_person_1.png" className={classes['test_speaker_image']}></img>
                   </div>
                   <div className={classes['test_speaker_name']} >
                   <h1 className={classes['story-title']}>Bariat Lawal</h1>
                   <p className={classes['buss_name']}>CEO, BaricrownCastle</p>
                   </div>
                   </div>
                </div>

                <div className={classes['test_col']} >
                   <p className={classes['word_three']}>"Shecluded" transformed my mindset about building a business. The lectures and mentorship during the CYFE Dream to Business Cohort 2 encouraged me to confidently face the marketing world and tackle business challenges as a small business owner. I feel truly inspired! Thank you, SHECLUDED!" </p>
                   <div className={classes['test_speaker']} >
                   <div className={classes['']} >
                   <img src="/images/buss_supp_test_person_1.png" className={classes['test_speaker_image']}></img>
                   </div>
                   <div className={classes['test_speaker_name']} >
                   <h1 className={classes['story-title']}>Adedoyin Oyinkasola</h1>
                   <p className={classes['buss_name']}>CEO, Majinfotek</p>
                   </div>
                   </div>
                </div>

                <div className={classes['test_col']} >
                   <p className={classes['word_three']}>"SHECLUDED delivered the best business training I’ve ever received. They went all out to provide massive value at no cost to participants, which is truly amazing. I’m deeply grateful for the privilege of joining the 8-week intensive CYFE Dream to Business Cohort 2. Thank you, SHECLUDED, for all you do for women entrepreneurs. Lots of hugs!" </p>
                   <div className={classes['test_speaker']} >
                   <div className={classes['']} >
                   <img src="/images/buss_supp_test_person_2.png" className={classes['test_speaker_image']}></img>
                   </div>
                   <div className={classes['test_speaker_name']} >
                   <h1 className={classes['story-title']}>Susan Banjo Oladosu</h1>
                   <p className={classes['buss_name']}>Ceo, SBO Food & Aridunubrand</p>
                   </div>
                   </div>
                </div>

               
            </div>
            </div>
    )
}

export default BussSuppTestimonial;