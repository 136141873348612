import React, { useEffect, useState } from "react";
import InPageLink from "../UI/InPageLink";
import classes from "./EmployeeStoriesSection.module.css";
import { useSwipeable } from "react-swipeable";


const EmployeeStoriesSection = () => {


    useEffect(()=> {
        let timer = setInterval(automate, 200);
 
         return () => {
             clearInterval(timer);
         };
 
     });
    
     const [currentIndex, setCurrentIndex] = useState(0);
 
     const automate = () => {
         setCurrentIndex(currentIndex >= -(980 - window.innerWidth) ? currentIndex - 7 : 50);
     };
 
  
     const swipingHandler = (e) => {
        
        if(e.deltaX > 0) {
            setCurrentIndex(currentIndex < 0 ? currentIndex + 7 : currentIndex);
        } else {
            setCurrentIndex(currentIndex >= -(985 - window.innerWidth) ? currentIndex - 7 : currentIndex);
            
        }
    };


   const handlers = useSwipeable({
    onSwiping: (e) => {window.innerWidth <= 1024 && swipingHandler(e)},
    trackMouse: true,
   });
 
   const linkStyle = {
        color: "#EB2590",
    };
 


    return (
        <div className={classes['container']}>
            <p className={classes['tagline']}>EMPLOYEE STORIES</p>
            <p className={classes['title']}>
                <span className={classes['next_line']}>Read Stories from people</span>
                <span className={classes['next_line']}> who <span className={classes['pink']}>cherish</span> their work here</span>
                <span className={classes['next_line']}> at Shecluded</span>
            </p>

            <div {...handlers} className={classes['sub_container']} style={{marginLeft: window.innerWidth <= 1024 && currentIndex}}>
                <div className={classes['partition']}>
                    <div className={classes['card_container']}>
                        <img src="/images/Abidemi.png" alt="" className={classes['img']} />
                        <div className={classes['text_container']}>
                            <p className={classes['text_title']}>Enhance my Skills</p>
                            <p className={classes['details']}>I enjoy the opportunity to enhance my skills by
                                taking on various challenges and exploring
                                different tasks, which contribute to the
                                improvement of my work experience. In my role, I
                                actively learn from colleagues across all
                                departments and teams. I appreciate it all
                            </p>
                            <p className={classes['link']}>
                                <span className={classes['story']}>Abidemi</span>
                                <span className={classes['instagram']}>Community Manager</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes['partition']}>
                    <div className={classes['card_container']}>
                        <img src="/images/Chidera.png" alt="" className={classes['img']} />
                        <div className={classes['text_container']}>
                            <p className={classes['text_title']}>A wonderful experience</p>
                            <p className={classes['details']}>Working at Shecluded has been an amazing
                                experience, it has challenged me a lot as a person
                                & has helped develop my skills in so many area's
                                of my life. I am grateful for the environment I
                                work in and my colleagues for making it a
                                wonderful experience
                            </p>
                            <p className={classes['link']}>
                                <span className={classes['story']}>Chidera</span>
                                <span className={classes['instagram']}>Human Resources</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes['partition']}>
                    <div className={classes['card_container']}>
                        <img src="/images/Joyce.png" alt="" className={classes['img']} />
                        <div className={classes['text_container']}>
                            <p className={classes['text_title']}>I'm really glad to be here</p>
                            <p className={classes['details']}>Shecluded has provided a foundation
                                for deep and experimental growth for
                                me and I'm really glad to be a part of
                                the beautiful team
                            </p>
                            <p className={classes['link']}>
                                <span className={classes['story']}>Joyce</span>
                                <span className={classes['instagram']}>Finance Officer</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default EmployeeStoriesSection;