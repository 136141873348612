import React from "react";
import classes from "./IndemnificationSection.module.css";
import TitleComponent from "./TitleComponent";


const IndemnificationSection = () => {
    return (
        <>
            <TitleComponent num = {8} text = {" Indemnification"} />
            <ol className={classes['ol']}>
                <li className={classes['li']}>You agree to indemnify, defend and hold harmless Shecluded and its affiliates, officers, directors, employees, consultants, agents and licensors from any and all third-party claims, liability, damages and/or costs (including but not limited to reasonable attorneys’ fees) arising from your failure to comply with the Terms and Conditions.</li>
            </ol>
        </>
    );
};

export default IndemnificationSection;