import React from "react";
import classes from "./HubTitleSection.module.css";


const HubTitleSection = () => {
    return (
        <div className={classes['container']}>
            <p className={classes['title']}>Register at Shecluded Hub</p>
        </div>
    );
};

export default HubTitleSection;