import React from "react";
import GetStartedSection from "../components/new components/LoanApplicationComponents/GetStartedSection";
import LoanApplicationBannerSection from "../components/new components/LoanApplicationComponents/LoanApplicationBannerSection";
import LoanApplicationForm from "../components/new components/LoanApplicationComponents/LoanApplicationForm";


const LoanApplicationPage = () => {
    return (
        <>
            <LoanApplicationBannerSection />
            <GetStartedSection />
            <LoanApplicationForm />
        </>
    );
};

export default LoanApplicationPage;