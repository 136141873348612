
import React from 'react';


const SectionSix = () => {
  return (
    <div className="mx-auto max-w-[90vw] lg:max-w-[75em] lg:px-40 pb-5">
      <p className="font-playfair font-[400] text-[24px] lg:text-[37px] leading-[31.99px] lg:leading-[49.32px] text-center w-full">
        We envision a world where no woman has to navigate{" "}
        <span className="font-[700]">her entrepreneurial</span> journey alone. Where they have a safe
        space to find friendship and be vulnerable
      </p>
    </div>
  );
};
export default SectionSix;
