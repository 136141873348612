import React from "react";
import BlogServicesItemComponent from "./BlogServicesItemComponent";
import HeadingComponent from "./HeadingComponent";


const BlogServicesUpdatesSection = () => {
    return (
        <>
            <HeadingComponent title = "Blog & Service Updates" text_body = "With new product features and updates to the services we " 
                    next_line = "offer, we have it listed here." />
            <BlogServicesItemComponent />
            
        </>
    );
};

export default BlogServicesUpdatesSection;