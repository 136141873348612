import React from "react";
import Ellipse from "../UI/Ellipse";
import classes from "./SecondQuoteSection.module.css";


const SecondQuoteSection = () => {
    return (
        <div className={classes['container']}>
            <Ellipse style = {{position: "absolute", height: "36.438em", width: "36.438em", zIndex: "1", border: "25px solid rgba(235, 37, 144, 0.05)",
                    marginLeft: window.innerWidth <= 1024 ? "-30.063em" : "-32.063em", marginTop:  window.innerWidth <= 1024 ? "-7em" : "-8em"}} />
            <p className={classes['quote']}>
                The hub is many things. However, what stood out for me is how so many women from various backgrounds work with so much <span className={classes['highlight']}>synergy. It is exciting.</span>
            </p>
            <p className={classes['quoter']}>-Mary-Jane Nnaji</p>
        </div>
    );
};
export default SecondQuoteSection;