import React, { useState } from "react";
import classes from "./AcceleratorProgramsSection.module.css";



const AcceleratorProgramsSection = () => {

    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className={classes['container']}>
            <div className={classes['top']}>
                <img src={window.innerWidth <= 1024 ? "images/accelerator_program_mobile.png" :"/images/accelerator_top.png"} alt="" className={classes['img']} />
                <div className={classes['top_text']}>
                    <div className={classes['top_title']}>
                        <p>Accelerator Programs</p>
                    </div>
                    <div className={classes['top_body']}>
                        <p>
                            For Business starters and young operating businesses, we offer accelerator programs annually at Shecluded Hub
                        </p>
                        <p className={classes['link']}><a href="/">Register for the 2024 program</a></p>
                    </div>

                </div>
            </div>
            <div className={classes['tab']}>
                <div onClick={() => { setActiveTab(0) }} className={ activeTab === 0 ? classes['tab1'] + " " + classes['active']: classes['tab1']}>
                    Applications
                </div>
                <div onClick={() => { setActiveTab(1) }} className={ activeTab === 1 ? classes['tab2'] +  " " + classes['active']: classes['tab2']}>
                    Sessions
                </div>
            </div>
            <div className={classes['sub_container']} style={{marginLeft: activeTab === 0 ? (window.innerWidth <= 1024) && "0" : (window.innerWidth <= 1024) && "-100vw"}}> 
                <div className={classes['applications']}>
                    <p className={classes['sub_logo']}></p>
                    <p className={classes['sub_title']}>Applications </p>
                    <p className={classes['text_body']}>Entrepreneurs, take the next step by completing our application form on the registration page. Join the Shecluded Hub Accelerator Program to supercharge your business aspirations. Your journey to success starts here—apply today!</p>
                </div>
                <div className={classes['sessions']}>
                <p className={classes['sub_logo']}></p>
                    <p className={classes['sub_title']}>Sessions</p>
                    <p className={classes['text_body']}>Our immersive 12-week Accelerator Program is now virtual, accessible via a private YouTube link. Attend from anywhere, engage with seasoned mentors, and propel your business journey to new heights with Shecluded Hub. Join us for this transformative experience!</p>
                </div>
            </div>
        </div>
    );
};

export default AcceleratorProgramsSection;