import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const NavBar = () => {

    const handleClick = (e) => {
        const navList = document.getElementById('nav-List')
        const signInBtn = document.getElementById('sign-in-hidden')
        const navUl = document.getElementById('nav-ul')
        const closeIcon = document.getElementById('close-icon')

        const removeFunc = () => {
                navList.classList.remove('visible')
                signInBtn.classList.remove('visible')
                navUl.classList.remove('visible')
        }
        {
            navList.classList.add('visible')
            signInBtn.classList.add('visible')
            navUl.classList.add('visible')
        }

        { 
            navList.addEventListener('click', () => {removeFunc ()})
        }
        {
            closeIcon.addEventListener('click', (e) => {
                e.stopPropagation()
                removeFunc()
            })
        }
    }

  return (
    <div className='navBar-container '>

        <div className="logo-container">
            <Link to="/">
                <img src={require ('../../assets/newRedesign-assets/shecluded-logo.png')} />
            </Link>
        </div>

        <div className="nav-list" id='nav-List'>
            <i className="uil uil-times active"  id='close-icon' onClick={handleClick}></i>
            <ul id='nav-ul'>
                <li className="nav-list-items pb-3 pb-lg-0"><Link to={'/about'}> About us</Link></li>
                <li className="nav-list-items pb-3 pb-lg-0"><Link to={'/loan'}>Loan</Link> </li>
                {/* <li className="nav-list-items pb-3 pb-lg-0"><Link to={'/wealth'}>Wealth Advisory</Link> </li> */}
                <li className="nav-list-items pb-3 pb-lg-0"><a href="https://blog.shecluded.com" target="_blank">Blog</a> </li>
                {/* <li className="nav-list-items pb-3 pb-lg-0"><a href="https://www.subscribepage.com/shecludedhmo" target="_blank">Health Insurance</a></li> */}
            </ul>

            {!sessionStorage.getItem('userData') ? (
                <ul id='sign-in-hidden'>
                    <li>
                        <Link to={'/login'}>
                                <div className="sign-in-div" id='sign-in-div'>
                                        <button className="sign-in-btn">Sign In</button>
                                </div>
                        </Link>
                    </li>
                        <li>
                            <Link to={'/register'}>
                                <div className="sign-in-div" id='sign-up-div'>
                                    <button className=" sign-up-btn">Register </button>
                                </div>
                            </Link>
                        </li>
                    </ul>
                ) : (
                            <ul lassName='signIn-ul' id='dashboard-sm-Screen'>
                                <li>
                                    <Link to={'/dashboard'}>
                                        <button className=" sign-in-btn">Dashboard </button>
                                    </Link>
                                </li>
                            </ul>
                            )
            }
            </div>

        {!sessionStorage.getItem('userData') ? (
            <ul className='signIn-ul'>
                <li>
                    <Link to={'/login'}>
                            <div className="sign-in-div" id='sign-in-div'>
                                    <button className="sign-in-btn">Sign In</button>
                            </div>
                    </Link>
                </li>
                <li>
                    <Link to={'/register'}>
                        <div className="sign-in-div" id='sign-up-div'>
                            <button className=" sign-up-btn">Register </button>
                        </div>
                    </Link>
                </li>
            </ul>
            ) : (
            <ul className='signIn-ul' id='hide-dashboard'>
                <li>
                    <Link to={'/dashboard'}>
                        <button className=" sign-in-btn">Dashboard </button>
                    </Link>
                </li>
            </ul>
            )
        }

        <div className='hamburger-div'>
            <i className="uil uil-bars hamburger-img" onClick={() => handleClick()}></i>
        </div>
    </div>
  )
}

export default withRouter(NavBar);


