import React, { Component, lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ChakraProvider, CSSReset, Box, Button } from '@chakra-ui/react';



import "./styles/bootstrap.min.css";
import "./styles/font-awesome/css/all.css";
import "./styles/animate.css";
// import "./styles/scss/styles.scss";
import "./styles/scss/responsive.scss";
import './App.css';



// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';




import Preloader from "./components/shared/preloader";
import ErrorBoundary from "./components/shared/error-boundary";
import SourcesOfIncome from "./components/dashboard/sources-of-income";
import ContactUs from "./components/home/contact-us";
import Homepage from  "./pages/HomePage";
import Loader from "./components/new components/UI/Loader";
// import HomePage from "./pages/HomePage";
import StoryLeadershipPage from "./pages/StoryLeadershipPage";
import NotFoundPage from "./pages/NotFoundPage";
import Header from "./components/new components/Header/Header";
import Footer from "./components/new components/Footer/Footer";
import AboutLoansPage from "./pages/AboutLoansPage";
import FAQPage from "./pages/FAQPage";
import SupportPage from "./pages/SupportPage";
import HubPage from "./pages/HubPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import JobsPage from "./pages/JobsPage";
import RolePage from "./pages/RolePage";
import TestimonialsPage from "./pages/TestimonialsPage";
import HubRegisterationPage from "./pages/HubRegisterationPage";
import LoanApplicationPage from "./pages/LoanApplicationPage";
import EventsPage from "./pages/EventsPage";
import TempHiringPage from "./pages/TempHiringPage";
import RoI from "./components/home/roi-calculator";
import CalculatorPage from "./pages/CalculatorPage";
import NewHomePage from "./pages/NewHomePage";
import BussinessSupport from "./pages/bussinessSupport";

// import Header from "./components/new components/Header/Header";

const LoginPage = lazy(() => import("./components/auth/login"));
const LoginWealthPage = lazy(() => import("./components/auth/loginWealth"));
const RegisterPage = lazy(() => import("./components/auth/register"));
const RegisterPageWealth = lazy(() => import("./components/auth/registerWealth"));
const CompleteRegistration = lazy(() =>
  import("./components/auth/complete-registration")
);
const VerifyEmail = lazy(() =>
  import("./components/auth/verify-email")
);
const CompleteRegistrationWealth = lazy(() =>
  import("./components/auth/complete-registration-wealth")
);
const ResetPasswordPage = lazy(() =>
  import("./components/auth/reset-password")
);
const ForgotPassword = lazy(() => import("./components/auth/forgot-password"));
// const NewHomepage = lazy(() => import("./components/home/NewHomepage"));
const RedesignHome = lazy(() => import("./components/redesignComponents/redesignHomeComponents/RedesignHome"));
// const Homepage = lazy(() => import("./components/home/homepage"));
const OurStory = lazy(() => import("./components/home/OurStory"));
// const ShecludedDashboard = lazy(() => import("./components/ShecludedDashboard"));
const ShecludedDashboard = lazy(() => import("./components/ShecludedDashboard"));
const OurTeam = lazy(() => import("./components/home/Teams"));
const AddBusiness = lazy(() => import("./components/home/AddBusiness"));
// const Blog = lazy(() => import("./components/home/Blog"));
// const BlogDetails = lazy(() => import("./components/home/BlogDetials"));
// const ContactUs = lazy(() => import("./components/home/contact-us"));
const RedesignedContactUS = lazy(() => import("./components/redesignComponents/redesignContactUs"));
const BusinessListing = lazy(() => import("./components/home/BusinessList"));
const Chat = lazy(() => import("./components/home/Chat"));
// const Faq = lazy(() => import("./components/home/faq"));
const RedesignedFaq = lazy(() => import("./components/redesignComponents/redesignFAQ"));
const LoansStatic = lazy(() => import("./components/home/loans"));
const RedesignedAboutUs = lazy(() => import("./components/redesignComponents/redesignAboutUs"));
// const AboutUs = lazy(() => import("./components/home/about-us"));
const UserGuard = lazy(() => import("./components/guards/user-guard"));
const ScrollTop = lazy(() => import("./components/shared/scroll-top"));
const WomensDay = lazy(() => import("./components/home/womensday"));
const BackToWork = lazy(() => import("./components/home/BackToWork"));

// const Error404 = lazy(() => import("./components/errors/error404"));
const RedesignedError404 = lazy(() => import("./components/redesignComponents/redesignError"));
// const Terms = lazy(() => import("./components/home/Terms"));
const RedesignedTerms = lazy(() => import("./components/redesignComponents/redesignTerms"));
const Wealth = lazy(() => import("./components/wealth"));
const WealthPay = lazy(() => import("./components/wealth/WealthPay"));
const Calculate = lazy(() => import("./components/wealth/Calculate"));
// nihojix379@javadoq.com





function App() {


    return (
      <Router>
            <Header />
            <ErrorBoundary>
              <Suspense fallback={<Loader />}>
                <Switch>
                  {/* <Route exact path="/" component={RedesignHome} /> */}
                  <Route exact path="/" component={Homepage} />
                  <Route exact path="/new" component={NewHomePage} />
                  <Route exact path="/about" component={StoryLeadershipPage} />
                  <Route exact path="/loan" component={AboutLoansPage} />
                  <Route exact path="/faq" component={FAQPage} />
                  <Route exact path="/support" component={SupportPage} />
                  <Route exact path="/shecluded_hub" component={HubPage} /> 
                  <Route exact path="/bussiness_support" component={BussinessSupport} /> 
                  <Route exact path="/terms_of_use" component={TermsOfUsePage} />
                  <Route exact path="/jobs" component={JobsPage} />
                  <Route exact path="/hiring/:position" component={RolePage} />
                  <Route exact path="/testimonials" component={TestimonialsPage} />
                  <Route exact path="/bookspace" component={HubRegisterationPage} />
                  <Route exact path="/loan_application" component={LoanApplicationPage} />
                  <Route exact path="/events" component={EventsPage} />
                  <Route exact path="/calculator" component={CalculatorPage} />
                  <Route exact path="/roi_calculator" component={RoI} />
                  







                  {/* <Route exact path="/redesignHome" component={RedesignHome} />
                  <Route exact path="/welcome" component={RedesignHome} />
                  <Route exact path="/story" component={OurStory} />
                  <Route exact path="/team" component={OurTeam} />
                  <Route exact path="/add-business" component={AddBusiness} />
                  <Route exact path="/business-showcase" component={BusinessListing} />
                  <Route exact path="/chat" component={Chat} /> */}
                  {/* <Route exact path="/blog" component={Blog} /> */}
                  {/* <Route path="/hiring/:position" component={TempHiringPage} /> */}
                  {/* <Route exact path="/loan" component={LoansStatic} /> */}
                  {/* <Route exact path="/blog/:id" component={BlogDetails} /> */}
                  {/* <Route exact path="/about" component={RedesignedAboutUs} /> */}
                  {/* <Route exact path="/contact" component={ContactUs} /> */}
                  <Route exact path="/contact" component={RedesignedContactUS} />
                  {/* <Route exact path="/faq" component={RedesignedFaq} /> */}
                  <Route exact path="/login" component={LoginPage} />
                  <Route exact path="/login/wealth" component={LoginWealthPage} />
                  <Route exact path="/womensday" component={WomensDay} />
                  <Route exact path="/register" component={RegisterPage} />
                  <Route exact path="/register/wealth" component={RegisterPageWealth} />
                  <Route exact path="/back-to-work" component={BackToWork} />
                  <Route exact path="/verify-email" component={VerifyEmail} />
                  <Route exact path="/wealth" component={Wealth} />
                  <Route exact path="/wealthpay" component={WealthPay} />
                  <Route exact path="/calculate" component={Calculate} />

              

                  <Route exact path="/loanapplication" component={ShecludedDashboard} />

                  <Route exact path="/dashboard" component={ShecludedDashboard} />
                  <Route exact path="/loansdashboard" component={ShecludedDashboard} />
                  <Route exact path="/loans/:id" component={ShecludedDashboard} />
                  <Route exact path="/profile" component={ShecludedDashboard} />
                  <Route exact path="/change-password" component={ShecludedDashboard} />
                  <Route exact path="/settings" component={ShecludedDashboard} />
                  <Route exact path="/complaints" component={ShecludedDashboard} />
                  <Route exact path="/wealthapp" component={ShecludedDashboard} />
                  <Route exact path="/wealth-goals" component={ShecludedDashboard} />
                  <Route exact path="/wealth-results" component={ShecludedDashboard} />
                  {/* <Route exact path="/community" component={ShecludedDashboard} /> */}
                  {/* <Route exact path="/loans" component={ShecludedDashboard} />
                  
                  
                  
                  <Route exact path="/commingsoon" component={ShecludedDashboard} />
                  
                  <Route exact path="/savings" component={ShecludedDashboard} />
                  
                  <Route exact path="/roi-calculator" component={ShecludedDashboard} /> */}

            
                  <Route exact path="/terms" component={RedesignedTerms} />
                  <Route
                    exact
                    path="/complete-registration"
                    component={CompleteRegistration}
                  />
                  <Route
                    exact
                    path="/complete-registration-wealth"
                    component={CompleteRegistrationWealth}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    path="/password-reset/:id/:token"
                    component={ResetPasswordPage}
                  />
                  <Route path="/user" component={UserGuard} />
                  <Route path="/welcome-page" component={UserGuard} />
                  <Route path="/user/dashboard" component={UserGuard} />
                  <Route path="/user/request-loan" component={UserGuard} />
                  <Route path="/user/loans" component={UserGuard} />
                  <Route path="/user/loans/:id" component={UserGuard} />
                  <Route path="/user/transactions" component={UserGuard} />
                  <Route
                    path="/user/source-of-income"
                    component={SourcesOfIncome}
                  />
                  <Route path="/user/verify-card" component={UserGuard} />
                  <Route path="/user/manage-profile" component={UserGuard} />
                  <Route path="/user/change-password" component={UserGuard} />
                  <Route path="/user/roi-calculator/" component={UserGuard} />
                  <Route
                    path="/user/roi-calculator/inventory"
                    component={UserGuard}
                  />
                  <Route
                    path="/user/roi-calculator/equipment"
                    component={UserGuard}
                  />
                  <Route
                    path="/user/roi-calculator/marketing"
                    component={UserGuard}
                  />
                  <Route path="*" component={NotFoundPage} />
                 
                </Switch>
                <ScrollTop />
              </Suspense>
            </ErrorBoundary>
          <Footer />
            
          </Router>
         
          );
        
        
  
  }


export default App;
library.add(fab, fas, far)