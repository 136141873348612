import React from 'react';
import { NavLink } from "react-router-dom";
import classes from "./Button.module.css";


const Button = (props) => {
    const btn = props.out ? <a href={props.to}><button className={classes.call_to_action} style={props.styles}  >{props.text}</button></a>:<NavLink to={props.to} style={{textDecoration: "none",}} ><button className={classes.call_to_action} style={props.styles}>{props.text}</button></NavLink>
    return(
        btn
    );
};



export default Button;