import React from "react";
import AboutHubSection from "../components/new components/HubRegistrationComponents/AboutHubSection";
import BookingForm from "../components/new components/HubRegistrationComponents/BookingForm";
import HubTitleSection from "../components/new components/HubRegistrationComponents/HubTitleSection";
import InsideHubSection from "../components/new components/HubRegistrationComponents/InsideHubSection";


const HubRegisterationPage = () => {
    return (
        <>
            <HubTitleSection />
            <AboutHubSection />
            <InsideHubSection />
            <BookingForm />
        </>
    );
};

export default HubRegisterationPage;