import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import "./Socials.module.css";


const Socials = () => {
    const styles = {width: "1em", height: "1em", padding: ".4em", margin:"0 .5em 0 .5em", borderRadius: "50px", boxShadow: "0px 0px 1px 1px rgba(0,0,0, .3)", color: "#000"};
    return(
        <>
            <a href="https://www.instagram.com/p/CpxZ0uVjyoZ/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="><FontAwesomeIcon icon={['fab', 'instagram']} size="lg" style={styles} /></a>
            <a href="https://twitter.com/shecluded/status/1679831121876361217?s=20"><FontAwesomeIcon icon={['fab', 'twitter']} size="lg" style={styles} /></a>
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7100023012741005312"><FontAwesomeIcon icon={['fab', 'linkedin']} size="lg" style={styles} /></a>
            <a href="https://www.youtube.com/watch?v=DsefRpkZbh4"><FontAwesomeIcon icon={['fab', 'youtube']} size="lg" style={styles} /></a>
            <a href="https://shecluded.medium.com/"><FontAwesomeIcon icon={['fab', 'medium']} size="lg" style={styles} /></a>
        </>
    );
};


export default Socials;