import React from "react";
import LoanWhoCanApplySection from "../components/new components/AboutLoansComponents/LoanWhoCanApplySection";
import LoansBannerSection from "../components/new components/AboutLoansComponents/LoansBannerSection";
import LoansFAQ from "../components/new components/AboutLoansComponents/LoansFAQ";
// import LoansGetStarted from "../components/new components/AboutLoansComponents/LoansGetStarted";
// import LoansSignUpSection from "../components/new components/AboutLoansComponents/LoansSignUpSection";
import LoansStatsSection from "../components/new components/AboutLoansComponents/LoansStatsSection";
import LoansStepsSection from "../components/new components/AboutLoansComponents/LoansStepsSection";
import LoansTestimonialSection from "../components/new components/AboutLoansComponents/LoansTestimonialSection";


const AboutLoansPage = () => {
    return (
        <>
            <LoansBannerSection />
            <LoansStatsSection />
            <LoansStepsSection />
            {/* <LoansGetStarted /> */}
            {/* <LoansSignUpSection /> */}
            <LoanWhoCanApplySection />
            <LoansTestimonialSection />
            <LoansFAQ />
        </>
    );
};

export default AboutLoansPage;