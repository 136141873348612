import React from "react";
import AccordionFAQ from "../UI/Accordion/AccordionFAQ";

const FAQ = (props) => {
    const pStyle = {
        color: '#000',
        fontFamily: "'Lato', sans-serif",
        fontSize: window.innerWidth <= 1024 ? '26px' : '32px',
        fontStyle: 'normal',
        fontWeight: '800',
        lineHeight: 'normal',
        padding: '2.375em .5em 0',
        ...props.pStyle,
        // textAlign:  window.innerWidth <= 1024 && "center"
    }

    const contStyle = {
        padding: window.innerWidth <= 1024 ? "0" : '3.375em 0 4em',
        maxWidth: window.innerWidth > 1024 && "75em",
        display: window.innerWidth > 1024 && "block",
        margin: window.innerWidth > 1024 && "auto",
        ...props.contStyle,
    }


    const titleStyle = {
        color: '#000',
        fontFamily: "'Lato', sans-serif",
        fontSize: window.innerWidth <= 1024 ? '14px' : '24px',
        fontStyle: 'normal',
        fontWeight: '600',
        wordBreak: "keep-all",
        // width: window.innerWidth <= 1024 && "21em",
        // border : "solid",
    
    }
    const bodyStyle = {
        color: '#000',
        fontFamily: "'Lato', sans-serif",
        fontSize: window.innerWidth <= 1024 ? '12px' : '19px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '33px', /* 30.94px */
    }

    

    return (
        <div style={contStyle}>
            <p style={pStyle}>{props.title}</p>
            <AccordionFAQ titleStyle = {titleStyle} bodyStyle = {bodyStyle} accordionData = {props.accordionData} 
            plusMinus = {props.plusMinus} black = {window.innerWidth > 1024 && props.black} />
        </div>
    );
};

export default FAQ;