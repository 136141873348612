import React, { Component } from 'react';
import classes from "./FooterNav.module.css";


const ContactDetails = (props) => {

 

    const contactUl = (props.navAccordionState) ? classes['footer-nav__ul'] + " " + classes['display-block'] : classes['footer-nav__ul'];
    const contactTitle = classes['footer-nav__title'];
    return (
        <div className={classes['footer-nav__container']}>
            <p className={contactTitle} onClick={props.onClick}>Contact</p>        
            <ul className={contactUl}>
                <li className={classes['footer-nav__li']}><span>hello@shecluded.com</span></li>
                <li className={classes['footer-nav__li']}><span>+234-704-800-0892</span></li>
                <li className={classes['footer-nav__li']}>
                    <span className={classes['footer-nav__address-title']}>Address</span>
                    <p className={classes['footer-nav__address']}>TSC Building Plot 8 , <span className={classes['br']} > Rock Drive Off C & </span> <span className={classes['br']} > I Leasing Drive,</span> <span className={classes['br']}> Lekki Phase</span>
                        {/* 16A Nike Art Gallery <br /> Way, Lekki Lagos */}
                        </p>
                </li>
            </ul>
        </div>
    );

}


export default ContactDetails;