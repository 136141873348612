import React from "react";
import FAQSearchSection from "../components/new components/FAQComponents/FAQSearchSection";
import LoanFAQSection from "../components/new components/FAQComponents/LoanFAQSection";
import ShecludedHubFAQSection from "../components/new components/FAQComponents/ShecludedHubFAQSection";
import ShecludedPensionScheme from "../components/new components/FAQComponents/ShecludedPensionScheme";
import ShecludedProgramFAQSection from "../components/new components/FAQComponents/ShecludedProgramFAQSection";
import ShecludedWealthManagement from "../components/new components/FAQComponents/ShecludedWealthManagement";
import AboutShecludedFAQSection from "../components/new components/FAQComponents/AboutShecludedFAQSection";


const FAQPage = () => {
    return (
        <>
            <FAQSearchSection />
            <AboutShecludedFAQSection />
            <LoanFAQSection />
            <ShecludedHubFAQSection />
            <ShecludedPensionScheme />
            <ShecludedProgramFAQSection />
            <ShecludedWealthManagement />
        
        </>
    );
};

export default FAQPage;