import React from "react";
import Button from "../UI/Button";
import NavBar from "./NavBar";
import classes from "./Header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageSelector from "../UI/LanguageSelector";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import InPageLink from "../UI/InPageLink";





const Header = (props) => {

    const [navToggle, setNavToggle] = useState(window.innerWidth <= 1024 ? false : true);


    const styles = {
        display : window.innerWidth <= 1024 ? 'inline-block' : 'none',
        float: 'right',
        marginRight: '.5em',
        marginTop: '.8em',
        height: "13.39px",
        width: "30.78px"
    };
    
    const secondStyles = {
        display : window.innerWidth <= 1024 ? 'inline-block' : 'none',
        float: 'right',
        marginRight: '.5em',
        color: '#fff',
        marginTop: '-2.3em',
        fontSize: "24px",
        fontWeight: "100"
    };

    const switchLang = {
        textAlign: 'left',
        cursor: 'pointer',
        zIndex: '50',
        width: '100px',
        height: '29px',
        color: '#464646',
        fontFamily: "'Lato', sans-serif",
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        display: window.innerWidth <= 1024 ? 'none' : 'inline-block',
        verticalAlign: 'middle',
        // marginBottom: '1.5em',
        marginLeft: '0.5em',
        marginRight: '1em',
    };

    const location = useLocation();
    const locBool = ["/faqs", "/support", "/legal", "/terms_of_use" ].indexOf(location.pathname) > -1;
    const btnStyles = {
        display: window.innerWidth <= 1024 ? 'block' : 'inline-block', 
        // margin: window.innerWidth <= 1024 ? '0' : '',
        width: window.innerWidth <= 1024 ? 'auto' : '6em',
        height: window.innerWidth <= 1024 ? '' : '2.7em',
        marginBottom: window.innerWidth <= 1024 ? '1em' : '0',
        marginTop: window.innerWidth <= 1024 ? '1em' : '-.4em',
        marginLeft: window.innerWidth <= 1024 ? '' : '.5em',
        // border:  window.innerWidth <= 1024 ? "solid 2px #fff" : 'solid 2px #fff',
        // backgroundColor:  window.innerWidth <= 1024 && '#fff',
        backgroundColor: window.innerWidth <= 1024 ? 'var(--Primary, #EB2590)' : locBool && '#FFE7F4',
        color:  window.innerWidth <= 1024 && '#FFB6C1',
        fontWeight: window.innerWidth <= 1024 && "bolder",
        fontSize: window.innerWidth <= 1024 && "20px",
        paddingLeft: ".8em"
    }
    const addClass = locBool ? classes.header + " " + classes.second_background : classes.header;
    return (
        ["/register", "/login", "/profile", "/dashboard", 
        "/loansdashboard", "/wealthapp", "/complaints"].indexOf(location.pathname) === -1 && <div className={addClass}>
            <div className={classes['sub']}>
                <div className={classes.img_container}>
                    <NavLink to="/"><img src="/images/Shecluded Logo.svg" className={classes.logo} alt="logo" /></NavLink>
                    
                </div>
                <img src="/images/icon _menu_.png" style={styles} onClick={() => { setNavToggle(!navToggle)}} alt="" />
                {/* <FontAwesomeIcon icon={['fas', 'bars']} size="2x"   /> */}
                {<div className={ (navToggle) ? classes.__top + " " + classes['top-anim'] : classes.__top }>
                    {window.innerWidth <= 1024 && <NavLink onClick={() => { window.scrollTo(0, 0); setNavToggle(false)}} to="/"><img src="/images/Shecluded logo white.svg" className={classes.logo1} alt="logo" /></NavLink>}
                    <FontAwesomeIcon icon={['fas', 'times']}  style={secondStyles} onClick={() => { setNavToggle(!navToggle)}} /> 
                    <NavBar onClick={() => { window.scrollTo(0, 0); setNavToggle(false)}} />
                    <LanguageSelector switchLang= {switchLang} />
                    {!sessionStorage.getItem('userData') ?  <><Button text={"Sign In"} to = "/login" styles = {btnStyles} />
                    <Button text={"Register"} to = "/register" styles = {{...btnStyles, backgroundColor: "#EB2590", color: "#fff"}} /></> : 
                    <Button text={"Dashboard"} to = "/dashboard" styles = {{...btnStyles, width: "8em", height: "3em"}} />}
                </div>}
            </div>
        </div>
    );
};


export default Header;