import React from "react";
import classes from "./HeadingComponent.module.css";


const HeadingComponent = (props) => {
    return (
        <div className={classes['container']}>
            <p className={classes['title']}>{props.title}</p>
            <p className={classes['text_body']}>
                {props.text_body}
                <span className={classes['next_line']}>
                    {props.next_line}
                </span>
            </p>

        </div>
    );
};

export default HeadingComponent;