import React, { Component } from 'react';
import classes from "./FooterNav.module.css";
import FooterLink from "../UI/FooterLink";


const SupportNav = (props) => {

    const links = [
        {
            key: 1,
            to: '/faq',
            text: 'FAQ'
        },
        {
            key: 2,
            to: '/support',
            text: 'Email'
        },
        {
            key: 3,
            to: '/',
            text: 'Cookie Settings'
        },
        {
            key: 4,
            to: '/',
            text: 'Media'
        },
    ];
    

    const supportUl = (props.navAccordionState) ? classes['footer-nav__ul'] + " " + classes['display-block'] : classes['footer-nav__ul'];
    const supportTitle = classes['footer-nav__title'];
    return (
        <div className={classes['footer-nav__container']}>
            <p className={supportTitle} onClick={props.onClick}>Support</p>        
            <FooterLink links = {links} categoryUl = {supportUl} />
        </div>
    );

}


export default SupportNav;