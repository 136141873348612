import React from "react";
import HeadingComponent from "./HeadingComponent";
import NewsItemsComponent from "./NewsItemsComponent";


const NewsSection = () => {
    return (
        <>
            <HeadingComponent title = "News and Sessions" text_body = "See all Recent , past and upcoming event here. To find out " 
                    next_line = "more in detail visit the shecluded blog" />
            <NewsItemsComponent />
        </>
    );
};

export default NewsSection;