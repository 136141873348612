import React from 'react';
import classes from "./HomeTestimonialsSection.module.css";
import { NavLink } from 'react-router-dom';

const HomeTestimonialsSection = () => {
    return (
        <div className={classes['testimonial__container']}>
            <div className={classes['left_pane']}>
                <p className= {classes['title']}>
                    <span className= {classes['pink']}>Voices</span> 
                    <span className= {classes['next_line']}>from Shecluded</span>
                </p>
                <div className={classes['left__pic']}> 
                    <div className={classes['testimony__container']}>
                        <div className={classes['heading']}>   
                            <p className={classes['testifier']}>Doris Omoyeni</p>
                            <p className={classes['testifier__position']}>Diamond Plug phones & gadgets</p>
                        </div>
                        <p className={classes['testimony']}>I love Shecluded; they're one of the best for loans. God bless Shecluded, may they continue to grow and achieve even greater success.I love Shecluded; they're one of the best for loans. God bless Shecluded, may they continue to grow and achieve even greater success.</p>
                    </div>
                </div>
            </div>
            <div className={classes['right_pane']}> 
                <div className={classes['right__pic']}> 
                    <div className={classes['testimony__container']}>
                        <div className={classes['heading']}> 
                            <p className={classes['testifier__position']}>PYOOR</p>
                            <p className={classes['testifier']}>Onome Aluko</p>
                        </div>
                        <p className={classes['testimony']}>Shecluded has been a big help to my business. They've given me loans many times, and it's easy – no stress, no need for collateral, and the interest rate is fantastic. No bank or financial institution can match that. My business not only survived all seasons but is still growing, thanks to Shecluded. Also, with the free business growth tips from Pyoor, my business is doing great</p>
                    </div>
                </div>
                    <p>The Hub is packed with talented women who have so much to say</p>
                    <p className={classes['link']}><NavLink to="/testimonials" >See more</NavLink></p>
            </div>
        </div>
     

    );
}

export default HomeTestimonialsSection;