import React, { useState } from "react";
import NewsItemComponent from "./NewsItemComponent";
import classes from "./NewsItemsComponent.module.css";
import LoadMoreBtn from "./LoadMoreBtn";
import LoadLessBtn from "./LoadLessBtn";


const NewsItemsComponent = () => {

    const [displayCounter, setDisplayCounter] = useState(3);

    const newsDetails = [
        {
            key: 1,
            img_src: "news1.png",
            author: "By Shecluded Hub",
            status: "UPCOMING",
            text_body: "Women World Empowerment Financial Program for the future ",
            news_date: "SEPTEMBER 24, 2023"
        },
        {
            key: 2,
            img_src: "news2.png",
            author: "Realest Finance",
            status: "",
            text_body: "financial Inclusion for young Girls in East Africa",
            news_date: "JANUARY 12TH, 2023"
        },
        {
            key: 3,
            img_src: "news3.png",
            author: "The United Nations",
            status: "",
            text_body: "Paris Accord: How women in Finance playing a part in Climate change",
            news_date: "MARCH 18TH, 2023"
        },
        {
            key: 4,
            img_src: "news4.png",
            author: "Techpoint Nigeria",
            status: "",
            text_body: "2030 Summit : Empowering women beyond small businesses ",
            news_date: "DECEMBER 15TH, 2023"
        },
        {
            key: 5,
            img_src: "news5.png",
            author: "The Nigerian Workers Association ",
            status: "",
            text_body: "Taking Apart the Complexities in Funding. Series a, b ,c.",
            news_date: "JUNE 22ND, 2023"
        },
        {
            key: 6,
            img_src: "news6.png",
            author: "GTCO",
            status: "",
            text_body: "Supporting More Women for Managerial Roles in Banks",
            news_date: "SEPTEMBER 14TH, 2023"
        },
    ];

    const batch = newsDetails.slice(0, displayCounter);

    return (
        <div className={classes['container']}>
            {batch.map((details) => <NewsItemComponent details = {details} key = {details.key} />)}
            {displayCounter < newsDetails.length && <LoadMoreBtn onClick={() => { setDisplayCounter(displayCounter + 3) }} />}
            {displayCounter >= newsDetails.length && <LoadLessBtn onClick={() => { setDisplayCounter(displayCounter - 3) }} />}
        </div>
    );
};

export default NewsItemsComponent;