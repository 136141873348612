import classes from "./MilestonesSection.module.css";

import React from 'react';

const MilestonesSection = () => {
    return (
        <div className={classes['container']}>
            <img src="/images/location (2) 1.png" alt="" className={classes['l_2']}/>
            <p className={classes['title']}>Our Milestones</p>

            <div className= {classes['black_founders_card']}>
                <div className = {classes['left']}>
                    <p className= {classes['date']}>October 2021</p>
                    <p className= {classes['card_title']}>Awarded Google Black Founders Fund</p>
                </div>
                <div className = {classes['right']}>
                    <p className={classes['text_body']}>
                        In 2021, Shecluded was awarded equity-free funding from Google, a turning point for the innovative startup. Our journey towards digital empowerment and global impact had just begun.
                    </p>
                </div>
            </div>
            <img src="/images/direction 1.png" alt="" className={classes['d_1']}/>
            <img src="/images/Vector 7.png" alt="" className={classes['v_7']}/>
            <img src="/images/Vector 1.png" alt="" className={classes['v_1']}/>
            <img src="/images/Location 3.png" alt="" className={classes['l_3']}/>
            <img src="/images/Vector 2.png" alt="" className={classes['v_2']}/>
            <img src="/images/I - Lifestyle (3).png" alt="" className={classes['i_3']}/>
            <img src="/images/Location 3.png" alt="" className={classes['l_4']}/>
            <img src="/images/Vector 3.png" alt="" className={classes['v_3']}/>
            <img src="/images/flag (2) 1.png" alt="" className={classes['f_1']}/>
            <div className= {classes['disbursed_loan']}>
                <div className = {classes['right']}>
                    <p className={classes['text_body']}>
                        In a bold move, we extended our support, offering loans totaling 1 billion Naira to empower up to 10,000 women in their entrepreneurial pursuits through Shecluded Hub in late 2022.
                    </p>
                </div>
                <div className = {classes['left']}>
                    <p className= {classes['date']}>  November 2022</p>
                    <p className= {classes['card_title']}>Disbursed Up to 1B naira in Loans  </p>
                </div>
            </div>
            <img src="/images/monument-valley 1.png" alt="" className={classes['m_4']}/>
            <img src="/images/Vector 4.png" alt="" className={classes['v_4']}/>
            <img src="/images/Vector 5.png" alt="" className={classes['v_5']}/>
            <img src="/images/Vector 8.png" alt="" className={classes['v_8']}/>
            <img src="/images/I - Lifestyle.png" alt="" className={classes['l_1']}/>
            <img src="/images/Location 3.png" alt="" className={classes['l_5']}/>
            <img src="/images/Vector 6.png" alt="" className={classes['v_6']}/>
            <img src="/images/I - Kids.png" alt="" className={classes['k_1']}/>
            <img src="/images/flag (1) 1.png" alt="" className={classes['f_2']}/>
            <div className= {classes['launched']}>
                <div className = {classes['left']}>
                    <p className= {classes['date']}>August 2023</p>
                    <p className= {classes['card_title']}>Launched shecluded hub</p>
                </div>
                <div className = {classes['right']}>
                    <p className={classes['text_body']}>
                        In late 2022, we continued our mission with Shecluded Hub, providing invaluable support to women entrepreneurs striving to achieve their business dreams.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MilestonesSection;