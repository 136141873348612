import React from "react";
import classes from "./CohortBenefit.module.css";



const CohortBenefit = (props) => {
    return (
        <div className={classes['container']}>
            <img src={"/images/" + props.data.img_src} alt="" className={classes['img']} />
            <div className={classes['text_container']}>
                <p className={classes['title']}>{props.data.title}</p>
                <p className={classes['text_body']}>{props.data.text_body}</p>
            </div>
        </div>
    );
};
export default CohortBenefit;