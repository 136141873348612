import React from "react";
import Button from "../UI/Button";
import classes from "./JoinShecludedSection.module.css";


const JoinShecludedSection = () => {
    const btnStyles = {
        display: window.innerWidth <= 1024 ? 'inline-block' : 'inline-block', 
        margin: window.innerWidth <= 1024 ? 'auto' : 'auto',
        marginBottom: window.innerWidth <= 1024 ? '0' : '0',
        marginTop: window.innerWidth <= 1024 ? '1.063em' : '1.063em',
        border:  window.innerWidth <= 1024 && '1px solid var(--Primary, #EB2590)',
        backgroundColor: 'var(--Primary, #EB2590)',
        color: '#fff',
        width: '11.125em',
        height: '3.375em',
    };
    return (
        <div className={classes['container']}>
            {/* <div className={classes['sub_container']}>
                <div className={classes['text-partition']}>

                    <p className={classes['title']}>Ngozi was here from the very beginning</p>
                    <p className={classes['small']}>
                        Meet our first Investor Ngozi. She is a Strategy and Business development professional with over a 
                        decade experience in advising, driving and closing high value deals in the Energy sector.
                    </p>
                </div>
                <div className={classes['img-partition']}>
                    <img src="/images/ngozi.png" alt="" />
                </div>
            </div>
            <p className={classes['action_text']}>See Open Roles at Shecluded </p> */}
            <div className={classes['btn_container']}>
                <Button text={"Apply"} to = {"/jobs"} styles = {btnStyles} />
            </div>
        </div>
    );
};


export default JoinShecludedSection;