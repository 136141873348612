import React, { Component } from 'react';
import FooterLink from "../UI/FooterLink";
import classes from "./FooterNav.module.css";


const LegalNav = (props) => {

    const links = [
        {
            key: 1,
            to: '/terms_of_use',
            text: 'Term of Use'
        },
        {
            key: 2,
            to: '/',
            text: 'Privacy Policy'
        },
        {
            key: 3,
            to: '/',
            text: 'User Agreement'
        },

    ];

 

    
    const legalUl = (props.navAccordionState) ? classes['footer-nav__ul'] + " " + classes['display-block'] : classes['footer-nav__ul'];
    const legalTitle = classes['footer-nav__title'];
    return (
        <div className={classes['footer-nav__container']}>
            <p className={legalTitle} onClick={props.onClick}>Legal</p>        
            <FooterLink links = {links} categoryUl = {legalUl}  />
        </div>
    );

}


export default LegalNav;