import React from 'react';
import Button from "../UI/Button";
import classes from "./HomeHubSection.module.css";

const HomeHubSection = () => {
    const btnStyles = {
        display: window.innerWidth <= 1024 ? 'block' : 'block', 
        margin: window.innerWidth <= 1024 ? 'auto' : '0',
        marginBottom: window.innerWidth <= 1024 ? '0' : '0',
        marginTop: window.innerWidth <= 1024 ? '3em' : '2em',
        borderRadius: window.innerWidth <= 1024 ? "9px" : '16px',
        border: '2px solid var(--Primary, #EB2590)',
        background: 'var(--Primary, #EB2590)',
        color: '#fff',
        width: window.innerWidth <= 1024 ? "8.849em" :"11.125em",
        height: window.innerWidth <= 1024 ? "3em" : "3.375em",
        fontSize: window.innerWidth <= 1024 ? "16px" : '20px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
        padding: window.innerWidth <= 1024 && ".5em",
    };

    const btnStyles1 = {
        display: window.innerWidth <= 1024 ? 'block' : 'block', 
        margin: window.innerWidth <= 1024 ? 'auto' : 'auto',
        marginBottom: window.innerWidth <= 1024 ? '0' : '0',
        marginTop: window.innerWidth <= 1024 ? '3em' : '3em',
        borderRadius: window.innerWidth <= 1024 ? "9px" : '16px',
        border: '2px solid var(--Primary, #EB2590)',
        background: 'var(--Primary, #EB2590)',
        color: '#fff',
        width: window.innerWidth <= 1024 ? "8.849em" :"11.125em",
        height: window.innerWidth <= 1024 ? "3em" : "3.375em",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
    };

    return(
        <>
            {/* <div className={classes['container']}>
                <p className={classes['top_title']}>Based on Informed Research in 2019, we Sort out to <span className={classes['pink']}>Change the Status Quo</span> of Women Owned Businesses by empowering over 10,000 female entrepreneurs in Africa.</p>
                <p className={classes['top_note']}>Here's how we did it !</p>
                <Button text={"Visit Hub"} to = {"/shecluded_hub"} styles = {btnStyles1} />
            </div> */}
            <div className={classes['hub__container']}>
                <div className={classes['left_pane']}>
                    <p className={classes['hub__tag']}>Shecluded Hub</p>
                    <div className={classes['title']}>   
                        Shecluded Hub for Growth
                    </div>
                    <p className={classes['hub__small']} >We develop strategic programs with public & private organisations to deliver bespoke trainings, incubator & accelerator for women in business.
                        <Button text={"Learn More"} to = {"/shecluded_hub"} styles = {btnStyles} />
                    </p>
                </div>
                <div className={classes['right_pane']}> 
                    <img src="/images/home_hub_sec_img.png" alt="home_hub_sec_img" className={classes['hub__pic']} />
                </div>
            </div>
        </>
    );
};

export default HomeHubSection;