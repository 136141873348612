import React from "react";
import classes from "./GeneralSection.module.css";
import TitleComponent from "./TitleComponent";


const GeneralSection = () => {
    return (
        <>
            <TitleComponent num = {7} text = {" General "} />
            <ol className={classes['ol']}>
                <li className={classes['li']}>This Agreement represents the entire understanding between Shecluded and the customer.</li>
                <li className={classes['li']}>Any amendment to this Agreement shall be made by Shecluded from time to time and communicated to Customer in writing.</li>
                <li className={classes['li']}>The Borrower agrees and undertakes that for the period of this Agreement, the Borrower will not close the Borrower's specified bank account.</li>
                <li className={classes['li']}>This Agreement shall be governed and construed under the laws of the Federal Republic of Nigeria and, in the event of a dispute, shall be subject to the jurisdiction of the courts of the Federal Republic of Nigeria.</li>
                <li className={classes['li']}>Notwithstanding any other provision in this Agreement, neither the Lender, its affiliates, officers, directors, employees, attorneys or agents shall have any liability with respect to, any claim for any special, indirect, incidental, consequential damages suffered or incurred by you in connection with, arising out of, or in any way related to, a breach of this Agreement.</li>
                <li className={classes['li']}>If The Lender does not strictly enforce its rights under this Agreement (including its right to insist on the repayment of all sums due on the Repayment Due Date) or grant the Borrower an indulgence, the Lender shall not be deemed to have waived or lost those rights and will not be prevented from insisting upon its strict rights at a later date.</li>
                <li className={classes['li']}>The Lender reserves the right to transfer or assign its rights and obligations under this Agreement (including its obligation to lend money to the Borrower or the amount owed under this Agreement) to another person. The Lender will only notify the Borrower if such a transfer causes the arrangements for the administration of this Agreement to change</li>
                <li className={classes['li']}>
                    The Borrower authorises and consents to all lawful access, use or disclosure of the Borrower's particulars, data and information in the application by the Lender, which may include but shall not be limited to purposes necessary to promote or sustain the business of the Lender; and the Borrower waives any claims the Borrower may have against the Lender arising from any such access, use or disclosure
                    <ul className={classes['ul']}>
                        <li className={classes['li']}>Shecluded may revise these Terms and Conditions at any time by updating this document. You agree to be bound by subsequent revisions and agree to review these Terms and Conditions periodically for changes. The most updated version of this document will be available for your review under the Shecluded Terms, and Conditions link that appears on the website.</li>
                    </ul>
                </li>
            </ol>
        </>
    );
};

export default GeneralSection;