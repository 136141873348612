import React from "react";
import classes from "./JobBannerSection.module.css";


const JobBannerSection = () => {
    return (
        <div className={classes['container']}>
            <div className={classes['sub_container']}>
                <p className={classes['tag']}>CAREERS</p>
                <p className={classes['title']}>
                    <span className={classes['next_line']}>At Shecluded,</span> 
                    <span className={classes['next_line']}> You do work you're</span>
                    <span className={classes['next_line']}> most <span className={classes['proud']}>proud</span> of <span className={classes['dot']}>.</span> </span>
                </p>
                <p className={classes['video_link']}>See Videos from Employees</p>
            </div>
        </div>
    );
};

export default JobBannerSection;