import React from "react";
import InPageLink from "../UI/InPageLink";
import classes from "./TestimonialCardComponent.module.css";


const TestimonialCardComponent = (props) => {
    return (
        <div className={classes['card_container']}>
            <img src= {"/images/" + props.data.image_src} alt="" className={classes['img']} />
            <div className={classes['text_container']}>
                <p className={classes['text_title']}>{props.data.title}</p>
                <p className={classes['details']}>{props.data.text_body}</p>
                <div className={classes['link']}>
                    <div className={classes['story']}>{props.data.testi_name}</div>
                    <div className={classes['instagram']}>{props.data.testi_post}</div>
                </div>
            </div>
        </div>
      
    );
};

export default TestimonialCardComponent;