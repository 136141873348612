import React from "react";
import HeadingComponent from "./HeadingComponent";
import UpcomingEventsComponent from "./UpcomingEventsComponent";


const UpcomingEventsSection = () => {
   return (
        <>
            <HeadingComponent title = "Upcoming Events" text_body = "Here we share upcoming events host by us or our partners. Stay tunned " 
                    next_line = "always." />
            <UpcomingEventsComponent />
        </>
   );
};
export default UpcomingEventsSection;