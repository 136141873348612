import React from "react";
import FAQ from "./FAQ";

const LoanFAQSection = () => {
   
    const accordionData = [
        {
            'key': 1, 
            'title' : 'How do I apply for a loan ?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "You would need to register to join our community then provide us with information on your current source of income or your monthly revenue. Also, we would like to know more about your business and the stage it is in. Please click below link to proceed www.shecluded.com"
                    ]
                },
                
            ]
        },
        {
            'key': 2, 
            'title' : 'What are the loan requirements?', 
            'body' : [
                {
                    title: "For eligibility and requirements",
                    texts: [
                       "You need to have a regular source of income.",
                       "You shouldn't have any loan currently running {If you do, kindly let us know}",
                       "A valid purpose of the loan"
                    ]
                },
                {
                    title: "For terms",
                    texts: [
                       "Our interest rate is 3-4% monthly.",
                       "Our repayment plan is flexible, we are open to fitting it to your capability",
                       "Our loan processing time is fast as long as we agree on repayment terms and rate"
                    ]
                },
               
                
            ]
        },
        {
            'key': 3, 
            'title' : 'What makes Shecluded loan different from other lenders?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We prioritize you by offering transparent interest rates, along with our quick, clear, and caring services. Our team is trained to treat you with respect."
                    ]
                },
                
            ]
        },
        {
            'key': 4, 
            'title' : 'What are your loan limits?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We offer loans up to 4 million naira. If you need more, we work with qualified candidates and connect them with our financial partners."
                    ]
                },
                
                
            ]
        },
        {
            'key': 5, 
            'title' : 'What is the least amount I can borrow?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "The least amount you can borrow is 100,000"
                    ]
                },
                
                
            ]
        },
        {
            'key': 6, 
            'title' : 'What is the least amount I can borrow?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "The least amount you can borrow is 100,000"
                    ]
                },
                
                
            ]
        },
        {
            'key': 7, 
            'title' : 'What makes me eligible for a loan with Shecluded?', 
            'body' : [
                {
                    title: "To be eligible for a loan with Shecluded",
                    texts: [
                       "you must be a business woman",
                       "earn a minimum of N150,000",
                       "a valid reason to apply for a loan",
                    ]
                },
                
                
            ]
        },
        {
            'key': 8, 
            'title' : 'Is location a criteria to access loans?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "No it is not. We give loans to businesses based anywhere provided you meet our criteria.",
                    ]
                },
                
                
            ]
        },
        {
            'key': 9, 
            'title' : 'Can I apply for a loan to start up a business?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Yes, you can. Please click to proceed: https://bit.ly/shecludedloanzgmb",
                    ]
                },
                
                
            ]
        },
        {
            'key': 10, 
            'title' : 'What are the requirements for me to qualify for a loan?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "You need to have a regular source of income and a valid reason for the loan",
                    ]
                },
                
                
            ]
        },
        {
            'key': 11, 
            'title' : 'Who can get a loan from us?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We provide financing for employed individuals and growing businesses.",
                    ]
                },
                
                
            ]
        },
        {
            'key': 12, 
            'title' : 'I am a small business, can I get a loan?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Yes! Most women in our community are small business owners just like you.",
                    ]
                },
                
                
            ]
        },
        {
            'key': 13, 
            'title' : 'I am interested in Shecluded but I do not want a loan, how can I benefit?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Even if you don't need a loan, explore our resources, workshops, and community events for valuable insights and support on your financial journey. Check out our non-loan services and join our community!",
                    ]
                },
                
                
            ]
        },
        {
            'key': 14, 
            'title' : 'Asides loans, what other funding option is available?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We currently do not have any other funding options. However, we have seasonal enterprise challenges every year where the winners are awarded funding.",
                    ]
                },
                
                
            ]
        },
        {
            'key': 15, 
            'title' : 'Where is your office address?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Office address: TSC Building Plot 8, Rock Drive Off C & I Leasing Drive, Lekki Phase, Lagos State Nigeria"
                    ]
                },
                
                
            ]
        },
        {
            'key': 16, 
            'title' : 'What is the Shecluded SME support?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Shecluded is your committed partner for starting and growing your business or career. We offer tailored finance and business support. Turn to us, and let's work together to reach your goals.",
                    ]
                },
                
                
            ]
        },
        {
            'key': 17, 
            'title' : 'Why was my loan not approved?', 
            'body' : [
                {
                    title: "The reasons your loan request  was rejected  include",
                    texts: [
                       "You did not meet the income requirement ",
                       "Your income source could not be verified from your bank statement.",
                       "You are currently servicing a loan",
                       "Your idea is not viable"
                    ]
                },
                
                
            ]
        },
        {
            'key': 18, 
            'title' : 'When can I reapply?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "You can reapply for a loan upon completion of your previous loan or if you meet our eligibility requirements.",
                    ]
                },
                
                
            ]
        },
        {
            'key': 19, 
            'title' : 'How do I check my loan balance and due date?', 
            'body' : [
                {
                    title: "You can check your loan balance and due date",
                    texts: [
                       "Through your Shecluded account when you sign and click on the LOAN to see Manage Loan",
                       "Contact support for more help:  hello@shecluded.com/ +2347048000892"
                    ]
                },
                
                
            ]
        },
        {
            'key': 20, 
            'title' : 'What is the repayment like?', 
            'body' : [
                {
                    title: "We have 2 types of repayment: Automatic and Manual.",
                    texts: [
                       "You can pick the one that suits how often you need - every day, every week, every month, and so on"
                    ]
                },
                
                
            ]
        },
        {
            'key': 21, 
            'title' : 'How does automatic repayment work?', 
            'body' : [
                {
                    title: "We have 2 types of repayment: Automatic and Manual.",
                    texts: [
                       "Automatic repayment saves you from the hassle of paying your loan manually. Set it up based on how often you want - daily, weekly, monthly, etc. Just check the settings when you login to your account."
                    ]
                },
                
                
            ]
        },
        {
            'key': 22, 
            'title' : 'Can I pay back my loan earlier than the due date?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Yes, you can pay your loan earlier than the due date"
                    ]
                },
                
                
            ]
        },
        {
            'key': 23, 
            'title' : 'Why do I have to put my card?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "By inputting your card, you can automate loan repayment, saving you time and reducing stress. It also ensures that we can send your savings and returns to the correct account."
                    ]
                },
                
                
            ]
        },
        {
            'key': 24, 
            'title' : 'If I pay off my overdue loan, can I get another Loan?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Yes, you are eligible for another loan, upon completion of your previous loan."
                    ]
                },
                
                
            ]
        },
        {
            'key': 25, 
            'title' : 'Will paying late affect my credit score?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Yes, paying late affects your credit score."
                    ]
                },
                
                
            ]
        },
        {
            'key': 26, 
            'title' : 'When are approved loans disbursed?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Approved loans are disbursed every Monday, Wednesday, and Friday."
                    ]
                },
                
                
            ]
        },
        {
            'key': 27, 
            'title' : 'Does Shecluded offer a credit score rating?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Yes, all community members who have applied for a loan are eligible for a free credit score rating from Shecluded upon request."
                    ]
                },
                
                
            ]
        },
        {
            'key': 28, 
            'title' : 'What\'s the loan approval timeline?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We usually approve loans 24 hours after applications have been processed."
                    ]
                },
                
                
            ]
        },
        {
            'key': 29, 
            'title' : 'What is the interest rate?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Our interest rate ranges between 3 - 4% monthly and an APR of 36% - 48%, no hidden charges."
                    ]
                },
                
                
            ]
        },
        {
            'key': 30, 
            'title' : 'What is the meaning of APR?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "APR means an \'annual percentage rate\' of interest"
                    ]
                },
                
                
            ]
        },
        {
            'key': 31, 
            'title' : 'Do you give men loans?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "No, our loans are specifically made to help women grow and succeed in what they do."
                    ]
                },
                
                
            ]
        },
        {
            'key': 32, 
            'title' : 'What is the duration of your loan?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "The duration of our loans is between 6 months to 1 year"
                    ]
                },
                
                
            ]
        },
        {
            'key': 33, 
            'title' : 'Do you give loans to career women?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "No, we don\'t give loans to career women unless you want to buy a car or a laptop"
                    ]
                },
                
                
            ]
        },
        {
            'key': 34, 
            'title' : 'Do you give loans to students?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "No, we don\'t give loans to students"
                    ]
                },
                
                
            ]
        },
        {
            'key': 35, 
            'title' : 'How do I know which loan to apply for?', 
            'body' : [
                {
                    title: "Depending on why you want our loan, you can choose to apply for:",
                    texts: [
                       "Our growth loan; This is granted to help women increase their capacities and help them achieve more",
                       "Inventory Financing Loan; This is a short-term small business funding that has one purpose: to help our customers buy inventory for their business."
                    ]
                },
                
                
            ]
        },
        {
            'key': 36, 
            'title' : 'Is the repayment weekly or monthly?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Loan repayment is monthly. However, on special occasions, it can be adjusted to a weekly or bi-weekly basis"
                    ]
                },
                
                
            ]
        },
        {
            'key': 37, 
            'title' : 'Is it possible to clear off a loan during the repayment period & would the interest be lesser?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Yes, you can decide to clear off your loan at any point during repayment, and as a result, the interest rate will be lesser because we only charge interest on a monthly basis"
                    ]
                },
                
                
            ]
        },
        {
            'key': 38, 
            'title' : 'How much can I assess at first?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "You can assess anywhere between 50,000 and 1 million on a first time basis"
                    ]
                },
                
                
            ]
        },
        {
            'key': 39, 
            'title' : 'How do I process loan repayment?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Make a bank transfer 0240942107 Included Limited GTBank Plc"
                    ]
                },
                
                
            ]
        },
        {
            'key': 40, 
            'title' : 'How do I process loan repayment?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Make a bank transfer 0240942107 Included Limited GTBank Plc"
                    ]
                },
                
                
            ]
        },
        {
            'key': 41, 
            'title' : 'Can you have two running loans together?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "No, you can not' you have to pay back your previous loan before applying for another."
                    ]
                },
                
                
            ]
        },
        {
            'key': 42, 
            'title' : 'Can you have two running loans together?', 
            'body' : [
                {
                    title: "You can book an appointment to speak with an expert today:",
                    texts: [
                       "Phone +2347048000892",
                       "Email: hello@shecluded.com",
                       "Website:  https://shecluded.com",
                       "Office address: TSC Building Plot 8, Rock Drive Off C & I Leasing Drive, Lekki Phase, Lagos State Nigeria"
                    ]
                },
                
                
            ]
        },
       
      
        
   
    
    ];
    return (

            <FAQ title = "Shecluded Loan" contStyle= {{padding: window.innerWidth <= 1024 && "0 .5em", }} accordionData = {accordionData} black plusMinus />
        );
    };

export default LoanFAQSection;