import React, { useState } from "react";
import NewsItemComponent from "./NewsItemComponent";
import classes from "./NewsItemsComponent.module.css";
import LoadMoreBtn from "./LoadMoreBtn";
import LoadLessBtn from "./LoadLessBtn";

const BlogServicesItemComponent = () => {

    const [displayCounter, setDisplayCounter] = useState(3);

        const newsDetails = [
            {
                key: 1,
                img_src: "blog1.png",
                author: "IIOT  West Africa ",
                status: "UPCOMING",
                text_body: "Digital Wallets for Women: Simplify Transactions",
                news_date: "JANURAY 10TH, 2023"
            },
            {
                key: 2,
                img_src: "blog2.png",
                author: "First Bank ",
                status: "",
                text_body: "Women in Fintech Leadership",
                news_date: "JUNE 8TH, 2023"
            },
            {
                key: 3,
                img_src: "blog3.png",
                author: "UNSDG",
                status: "",
                text_body: "Financial Empowerment for Women",
                news_date: "JULY 21ST, 2023"
            },
            {
                key: 4,
                img_src: "blog4.png",
                author: "Opay Finance ",
                status: "",
                text_body: "Gender Pay Gap Solutions",
                news_date: "NOVEMBER 3RD, 2023"
            },
            {
                key: 5,
                img_src: "blog5.png",
                author: "The Nigerian Workers Association  ",
                status: "",
                text_body: "Women and Investment Strategies",
                news_date: "APRIL 29TH, 2023"
            },
            {
                key: 6,
                img_src: "blog6.png",
                author: "GTCO",
                status: "",
                text_body: "Fintech for Female Entrepreneurs",
                news_date: "AUGUST 9TH, 2023"
            },
        ];

    const batch = newsDetails.slice(0, displayCounter);

        return (
            <div className={classes['container']}>
                {batch.map((details) => <NewsItemComponent details = {details} key = {details.key} />)}
                {displayCounter < newsDetails.length && <LoadMoreBtn onClick={() => { setDisplayCounter(displayCounter + 3) }} />}
                {displayCounter >= newsDetails.length && <LoadLessBtn onClick={() => { setDisplayCounter(displayCounter - 3) }} />}

            </div>
        );

};

export default BlogServicesItemComponent;