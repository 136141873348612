import React from "react";
import Ellipse from "../UI/Ellipse";
import classes from "./StoryLeadershipBanner.module.css";

const StoryLeadershipBanner = () => {
    const ellipseStyle = {
        marginLeft: window.innerWidth <= 1024 ? "-18.813em" : "-8.813em",
        marginTop: window.innerWidth <= 1024 ? "0" : "-15.313em",
        flex: "0",
        width: window.innerWidth <= 1024 ? "21.438em" : "28.438em",
        border: window.innerWidth <= 1024 && "25px solid rgba(235, 37, 144, 0.05)",
        height:  window.innerWidth <= 1024 ? "30.438em" : "38.438em",
        position: window.innerWidth <= 1024 && "absolute",
        padding: "12em",
        zIndex: "1"
    };
    const ellipse2Style = {
        marginLeft:  window.innerWidth <= 1024 ? ".5em" : "auto",
        marginRight: window.innerWidth <= 1024 ? "" : "3em",
        // right: "8.625em",
        marginTop: window.innerWidth <= 1024 ? "3em" : "-8em",
        // position: "absolute",
        width: window.innerWidth <= 1024 ? "14em" : "28.438em",
        height: window.innerWidth <= 1024 ? "14em" : "28.438em",
        "zIndex": "-1",
        border: "35px solid rgba(235, 37, 144, 0.07)",
        // display: window.innerWidth <= 1024 && "none"
    };
    const ellipse3Style = {
        width: "2.25em",
        height: "2.25em",
        marginLeft: "-5em",
        marginRight: "11.625em",
        marginTop: "15.938em",
        border: "5px solid rgba(235, 37, 144, 0.07)",
        // position: "absolute",
    };
    return (
        <div className= {classes['banner__container']}>
            <Ellipse style = {ellipseStyle} />
            <div className={classes['partition'] + " " + classes['partition_left']}>
                <p className={classes['word_one']}>ABOUT US</p>
                <p className={classes['word_two']}>We Started with a vision to <span>Empower</span> women in business</p>
                <p className={classes['word_three']}>Shecluded is bringing business opportunities much 
                        closer to young women than ever before and we love the hustle that comes with it. </p>
                <Ellipse style = {ellipse3Style} />
            </div>
            <div className={classes['partition']}>
                <img src="/images/our_story_banner.png" alt="" className={classes['img']} />
                <Ellipse style = {ellipse2Style} />
            </div>
        </div>
    );
};

export default StoryLeadershipBanner;