import React from "react";
import classes from "./LoansStatsSection.module.css";

const LoansStatsSection = () => {
    return (
        <div className= {classes['container']}>
            <p className={classes['title']}>
                We've Empowered Women through tailored <span className={classes['dynamic']}>financial services</span> and fueling 
            </p>
            <div className={classes['statistics']}>
                <div className={classes['partition']}>
                    <p className={classes['number']}>$1.1m</p>
                    <p className={classes['details']}>Amount In Loans Disbursed</p>
                </div>
                <div className={classes['partition']}>
                    <p className={classes['number']}>1500+</p>
                    <p className={classes['details']}>Members in our community</p>
                </div>
                <div className={classes['partition']}>
                    <p className={classes['number']}>3500+</p>
                    <p className={classes['details']}>Women have received loans and we plan to give more</p>
                </div>
                <div className={classes['partition']}>
                    <p className={classes['number']}>400+</p>
                    <p className={classes['details']}>Businesses launched through Shecluded</p>
                </div>
            </div>
        </div>
    );
};

export default LoansStatsSection;