import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
    	<div className="div-1-footer">
			<div className="first-">
				<Link to="/">
					<img className='logo-white' src={require ('../../assets/newRedesign-assets/footerAssets/logo-white.png')} />
				</Link>
				<p>Subscribe to our newsletter</p>
				<a href='https://shecludedblog.substack.com/' target='_blank'>
					<div className='newsletter-div'>
						<p> Email address </p>
						<button> <i class="uil uil-angle-right-b"></i></button>
					</div>
				</a>
				
			</div>
			<div className="second-">
				<ul>
					<li className="footer-links first-link"><strong>Who We Are</strong></li>
					<li className="footer-links"><Link to={"/about"}>About Us</Link></li>
					<li className="footer-links"><Link to={"/about"}>What we do</Link></li>
					<li className="footer-links"><Link to={"/about"}>Who we serve</Link></li>
					{/* <li className="footer-links"><Link to={'/about'}> Our People</Link></li> */}
					<li className="footer-links"><Link to={'/about'}> Our Services</Link></li>
				</ul>
				<ul>
					<li className="footer-links first-link"><strong>Support</strong></li>
					{/* <li className="footer-links">Help Center</li> */}
					<li className="footer-links"> <Link to={'/faq'}>FAQs</Link> </li>
					<li className="footer-links"><a href='https://t.me/+DJAseXicKRRiYijRk' target='_blank'> Community</a></li>
					<li className="footer-links"><Link to={'/contact'}> Contact Us</Link></li>
					{/* <li className="footer-links"><Link to={'*'}> Careers</Link></li> */}
				</ul>
				<ul>
					<li className="footer-links first-link"><strong>Legal</strong></li>
					<li className="footer-links"><Link to={'/terms'}> Cookies Policy</Link></li>
					<li className="footer-links"><Link to={'/terms'}> Privacy Policy</Link></li>
					<li className="footer-links"><Link to={'/terms'}> Terms of Service</Link></li>
					{/* <li className="footer-links"><Link to={'*'}> Error 404</Link></li> */}
				</ul>
			</div>
			<div className="third- ">
				<div className="socials">
					<p>Connect with us</p>
					<div className="socials-img">
						<a href="https://instagram.com/shecluded" target="_blank">
							<button > <i class="uil uil-instagram"></i> </button>
						</a>
						<a href="https://twitter.com/shecluded" target="_blank">
							<button > <i class="uil uil-twitter"></i> </button>
						</a>
						<a href="https://www.youtube.com/channel/UCw-HgPM7ZkveoxnYuths-LQ" target="_blank">
							<button > <i class="uil uil-youtube"></i> </button>
						</a>
						<a href="https://www.linkedin.com/company/shecluded/" target="_blank">
							<button > <i class="uil uil-linkedin"></i> </button>
						</a>
					</div>
				</div>

				{/* Hidden Install segment */}
				{/* <div className="install-div">
					<p> Install App </p>
					<div className="install-apps">
						<img src={require ('../../assets/newRedesign-assets/footerAssets/googlePlay.png')} alt="Google Play download button" />
						<img src={require ('../../assets/newRedesign-assets/footerAssets/appStore.png')} alt="Apple Store download button" />
					</div>
				</div> */}
			
			</div>
		</div>
		<div className="div-2-footer">
			<div className="text-footer">
				<p className='cookie-policy'> This site uses cookies. <span>Cookies and Privacy.</span> I agree to the <span>terms of use, </span> and the <span>Disclaimer Policy.</span> This information will not be used for marketing purposes.</p>
				<hr></hr>
				<p className='copyright'>Copyright 2021 Shecluded Solutions Ltd.</p>
			</div>
		</div>
</div>
  )
}

export default Footer