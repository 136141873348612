import React, { useEffect, useState } from "react";
import classes from "./BrilliantMindsSection.module.css";
import TestimonialCardComponent from "./TestimonialCardComponent";


const BrilliantMindsSection = () => {
    
    useEffect(() => {
        const timer = setInterval(automate, 2000);

        return () => {clearInterval(timer)};
    });

    const [counter, setCounter] = useState(0);

    const automate = () => {
        (counter < 5) ? setCounter(counter + 1) : setCounter(0);
    };


    const testimonialLeft = (counter === 5) ? window.innerWidth <= 1024 && (-19.8 * counter) + "em" : window.innerWidth <= 1024 && (-20 * counter) + "em";



    const brilliantMinds = [
                                {
                                    key:1,
                                    title: "Enhance my Skills",
                                    text_body: "I enjoy the opportunity to enhance my skills by taking on various challenges and exploring different tasks, which contribute to the improvement of my work experience. In my role, I actively learn from colleagues across all departments and teams. I appreciate it all",
                                    testi_name: "Abidemi",
                                    testi_post: "Community Manager",
                                    image_src: "Abidemi.png"
                                },
                                {
                                    key:2,
                                    title: "A wonderful experience",
                                    text_body: "Working at Shecluded has been an amazing experience, it has challenged me a lot as a person & has helped develop my skills in so many area's of my life. I am grateful for the environment I work in and my colleagues for making it a wonderful experience",
                                    testi_name: "Chidera",
                                    testi_post: "Human Resources",
                                    image_src: "Chidera.png"
                                },
                                {
                                    key:3,
                                    title: "It's Fulfilling working here",
                                    text_body: "Shecluded has provided a foundation for deep and experimental growth for me and I'm really glad to be a part of the beautiful team",
                                    testi_name: "Matthew",
                                    testi_post: "Business Development",
                                    image_src: "Matthew.png"
                                },
                                {
                                    key:4,
                                    title: "I'm really glad to be here",
                                    text_body: "Shecluded has provided a foundation for deep and experimental growth for me and I'm really glad to be a part of the beautiful team",
                                    testi_name: "Joyce",
                                    testi_post: "Finance Officer",
                                    image_src: "Joyce.png"
                                },
                                {
                                    key:5,
                                    title: "Supportive Environment",
                                    text_body: "Working at Shecluded has been a wonderful experience. I have the opportunity to nurture my creativity and grow in an environment that supports me. It's been really great!",
                                    testi_name: "Jemima",
                                    testi_post: "Marketing Intern",
                                    image_src: "Jemima.png"
                                },
                                {
                                    key:6,
                                    title: "Shecluded creates Leaders",
                                    text_body: "Shecluded is a place where leaders are raised. The niti-grity of responsibility has been instilled into me and I've enjoyed the privileged of being a dynamic software developer!",
                                    testi_name: "Praise",
                                    testi_post: "Software Developer",
                                    image_src: "Praise.png"
                                },
                                
                            ]

    return (
        <div className={classes['container']}>
            <p className={classes['tagline']}>EMPLOYEE STORIES</p>
            <p className={classes['title']}>The Brilliants Minds at Shecluded</p>

            <div className={classes['sub_container']} style={{marginLeft: (window.innerWidth <= 1024) && testimonialLeft}}> 
                {brilliantMinds.map((data) => <TestimonialCardComponent key = {data.key} data = {data} />)}
            </div>
            <div className={classes['navigators']}>
                <img onClick={() => {(counter > 0) && setCounter(counter - 1)}} src="/images/Arrow - Left Circle.png" alt="" className={classes['nav']}  />
                <span className={classes['more']}>MORE</span> 
                <img onClick={() => {(counter < 5) && setCounter(counter + 1)}} src="/images/Arrow - Right Circle.png" alt="" className={classes['nav']} />
            </div>
        </div>
    );
};

export default BrilliantMindsSection;