import React from 'react';

import HomePartnersIcons from "../HomeComponents/HomePartnersIcons";


const SectionFive = () => {

    return (
        // <div>
            <HomePartnersIcons />
        // </div>
    );

};
export default SectionFive;