import React from "react";
import classes from "./StatSection.module.css";



const StatSection = () => {
    return (
        <div className={classes['container']}>
            <p className={classes['text']}>
                The Cohort has produced 500+ business with revenues totaling $30m  <span className={classes['pink']}>in just 3 years.</span>
            </p>
        </div>
    );
};
export default StatSection;