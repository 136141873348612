import React from "react";
import InPageLink from "../UI/InPageLink";
import classes from "./SpaceComponent.module.css"


const SpaceComponent = (props) => {
    return (
        <div className={classes['space']}>
            <img src= {"/images/" + props.space.img_src} alt="" className={classes['img']} />
            <p className={classes['space_title']}><InPageLink to= {props.space.to} text = {props.space.title} styles = {{color: "#EB2590"}} /></p>
            <p className={classes['space_description']}> {props.space.space_body} </p>
        </div>
    );
};

export default SpaceComponent;