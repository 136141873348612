import React, { useState } from "react";
import classes from "./TestimonialBannerSection.module.css";


const TestimonialBannerSection = () => {

    const [counter, setCounter] = useState(0);

    const testimonialLeft = window.innerWidth <= 1024 ? (-22 * counter) + "em" : (-32.9 * counter) + "em";

    const imgLeft = window.innerWidth <= 1024 ? (-20 * counter) + "em" : (-23.4 * counter) + "em";


    return (
        <div className={classes['container']}>
            <div className={classes['sub_container']}>
                <div className={classes['text_container']}>
                    <p className={classes['tagline']}>GROWTH LOANS</p>
                    <p className={classes['title']}>Stories that push Us <span className={classes['next_line']}>to do more</span></p>

                    <div style={{overflow: "hidden", maxWidth: window.innerWidth <= 1024 ? "22em" : "32.9em"}}>

                        <div style={{scrollBehavior:"smooth", display:"flex", marginLeft: testimonialLeft, transition: "margin-left .5s linear"}}>
                            <div className={classes['testimonial_container']}>
                                <p className={classes['heading']}>
                                    <span className={classes['name']}>Amarachi</span>
                                    <span className={classes['position']}>FOUNDER REVERB</span>
                                </p>
                                <p className={classes['testimonial_body']}>"Shecluded Hub's loan transformed my financial journey completely. Their flexible terms, low rates, and supportive team made reaching my goals a reality. Grateful beyond words."</p>
                            </div>
                            <div className={classes['testimonial_container']}>
                                <p className={classes['heading']}>
                                    <span className={classes['name']}>Doyinsole</span>
                                    <span className={classes['position']}>CEO RESWAYE</span>
                                </p>
                                <p className={classes['testimonial_body']}>"Our First Bale as a Recycling company was financed with Shecluded. We were able to employ staffs."</p>
                            </div>
                            <div className={classes['testimonial_container']}>
                                <p className={classes['heading']}>
                                    <span className={classes['name']}>Oghenetega</span>
                                    <span className={classes['position']}>CEO THEMOBILESPALAGOS</span>
                                </p>
                                <p className={classes['testimonial_body']}>"Shecluded helped us with a loan we used to purchase more beds, towels and work tools to enable her employ more therapists to deliver on demand spa services across the city of Lagos. I have also enjoyed business development support from the Shecluded team. It is amazing being part of Shecluded's community!"</p>
                            </div>
                            <div className={classes['testimonial_container']}>
                                <p className={classes['heading']}>
                                    <span className={classes['name']}>Moyinoluwa</span>
                                    <span className={classes['position']}>FOUNDER BETHMODA</span>
                                </p>
                                <p className={classes['testimonial_body']}>"Shecluded helped in the financing of our first ever equipment when we started up our jewelry company in the year 2021."</p>
                            </div>
                            <div className={classes['testimonial_container']}>
                                <p className={classes['heading']}>
                                    <span className={classes['name']}>Tabitha</span>
                                    <span className={classes['position']}>CEO VIRTUOUSPAD</span>
                                </p>
                                <p className={classes['testimonial_body']}>"Over the past 3 years, Shecluded has impacted greatly financially in my passion project for ladies -Virtuous Pads but has also empowered me financially with my numerous businesses generating a 30% revenue increase."</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes['img_container']}>
                    <div style={{marginLeft: imgLeft, gap:"1em", display: "flex", scrollBehavior:"smooth", transition: "margin-left .5s linear"}} >
                        <img src="/images/amarachi.png" alt="" className={classes['img']} />
                        <img src="/images/doyin.png" alt="" className={classes['img']} />
                        <img src="/images/tega.png" alt="" className={classes['img']} />
                        <img src="/images/beth.png" alt="" className={classes['img']} />
                        <img src="/images/tabitha.png" alt="" className={classes['img']} />
                    </div>
                </div>
            </div>
            <div className={classes['navigators']}>
                <img onClick={() => {(counter > 0) && setCounter(counter - 1)}} src="/images/Arrow - Left Circle.png" alt="" className={classes['nav']}  />
                <span className={classes['more']}>MORE</span> 
                <img onClick={() => {(counter < 4) && setCounter(counter + 1)}} src="/images/Arrow - Right Circle.png" alt="" className={classes['nav']} />
            </div>
        </div>
    );
};

export default TestimonialBannerSection;