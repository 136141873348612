import React from "react";
import FAQ from "./FAQ";

const ShecludedHubFAQSection = () => {
   

    const accordionData = [
        {
            'key': 1, 
            'title' : 'What is a co-working space?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "A co-working space is like a shared office where people, freelancers, startups, and businesses can rent a workspace for a short or long time"
                    ]
                },
                
                
            ]
        },
        {
            'key': 2, 
            'title' : 'What things and services come with your co-working space?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We provide round-the-clock access to fast internet, meeting rooms, a kitchen space, and comfortable furniture."
                    ]
                },
                
                
            ]
        },
        {
            'key': 3, 
            'title' : 'How can I reserve a co-working space at your facility?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Booking a workspace is effortless. You can select your preferred type of space and date on our website and follow the simple booking process online or contact support at +2347048000892 hello@shecluded.com"
                    ]
                },
                
                
            ]
        },
        {
            'key': 4, 
            'title' : 'Is it possible to rent a co-working space for a single day, or are only long-term options available?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We offer flexible options, including single-day rentals and various membership plans ranging from daily to yearly options.",
                       "Is it possible to rent a co-working space for a single day, or are only long-term options available?"
                    ]
                },
                
                
            ]
        },
        {
            'key': 5, 
            'title' : 'Are your co-working spaces appropriate for remote work and virtual meetings?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Our spaces come equipped with fast internet, quiet areas for remote work, and meeting rooms with video conferencing capabilities for virtual meetings."
                    ]
                },
                
                
            ]
        },
        {
            'key': 6, 
            'title' : 'Can you please confirm if you offer office equipment and supplies?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "At your chosen location, we offer a range of basic office supplies and equipment. You can check availability of specific items by contacting us at  at +2347048000892/ hello@shecluded.com"
                    ]
                },
                
                
            ]
        },
        {
            'key': 7, 
            'title' : 'Can I customize my workspace or office in your co-working facility?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "You can customize your workspace to some extent. Please consult with our community manager or staff for guidelines on personalization."
                    ]
                },
                
                
            ]
        },
        {
            'key': 8, 
            'title' : 'Can you tell me about the safety measures in your co-working spaces?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We take your safety seriously and have implemented various measures to ensure a secure environment, including security systems, surveillance cameras, and on-site staff."
                    ]
                },
                
                
            ]
        },
        {
            'key': 9, 
            'title' : 'Can I know about the possibility of hosting events or workshops in your co-working space?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Our designated event spaces are available for hosting your events and workshops. Please contact our team to discuss details and requirements."
                    ]
                },
                
                
            ]
        },
        {
            'key': 10, 
            'title' : 'What is your cancellation policy for memberships and bookings?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Our cancellation policy varies based on membership or booking type. Refer to our terms and conditions for specific cancellation details."
                    ]
                },
                
                
            ]
        },
        {
            'key': 11, 
            'title' : 'Do you provide any discounts for long-term commitments or group bookings?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We offer discounts for customers making long-term commitments and group bookings. If you're interested in learning more about these special offers, please get in touch with us at  +2347048000892/ hello@shecluded.com"
                    ]
                },
                
                
            ]
        },
        {
            'key': 12, 
            'title' : 'Can I arrange to visit your co-working space before deciding to pay?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Yes! We highly recommend a visit to experience our co-working space firsthand and get a comprehensive view of the facilities.",
                       "Feel free to contact us to arrange a convenient time for your visit.",
                       "We look forward to showcasing our space and answering any questions you may have before you make a decision."
                    ]
                },
                
                
            ]
        },
        {
            'key': 13, 
            'title' : 'How may I reach out to your customer support for additional assistance?', 
            'body' : [
                {
                    title: "",
                    texts: [
                        "Phone +2347048000892",
                        "Email: hello@shecluded.com",
                        "Website:  https://shecluded.com",
                        "Office address: TSC Building Plot 8, Rock Drive Off C & I Leasing Drive, Lekki Phase, Lagos State Nigeria"
                    ]
                },
                
                
            ]
        },

    ];

    return (
        <FAQ title = "Shecluded Hub (Co - work space)" contStyle= {{padding: window.innerWidth <= 1024 && "0 .5em", }} accordionData = {accordionData} black plusMinus />
    );
};

export default ShecludedHubFAQSection;