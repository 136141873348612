import React from 'react';
import classes from "./Ellipse.module.css";

const Ellipse = (props) => {
    return (
        // <div className= {classes["ellipse__container"]}>
            <div className={classes["ellipse__content"]} style={props.style}></div>
        // </div>
    );
};

export default Ellipse;