import React from "react";
import classes from "./StoryLeadershipVisionSection.module.css";

const StoryLeadershipVisionSection = () => {
    return (
        <div className={classes['container']}>
            <div className={classes['partition']}>
                <p className={classes['title']}>
                    Our <span className={classes['vision']}>Vision</span>
                </p>
                <p className={classes['text-body']}>
                    Our vision is to create a transformative ecosystem that
                    not only provides tailored financial solutions but also
                    nurtures a network of support, collaboration, and 
                    mentorship for women entrepreneurs.
                </p>
            </div>
            <div className={classes['partition']}>
                <img src="/images/vision.png" alt="" className={classes['img']} />
            </div>
        </div>
    );
};

export default StoryLeadershipVisionSection;