import React, { useState } from "react";
import NewsItemComponent from "./NewsItemComponent";
import classes from "./NewsItemsComponent.module.css";
import LoadMoreBtn from "./LoadMoreBtn";
import LoadLessBtn from "./LoadLessBtn";

const UpcomingEventsComponent = () => {

    const [displayCounter, setDisplayCounter] = useState(3);

    const newsDetails = [
        {
            key: 1,
            img_src: "upcoming1.png",
            author: "By Shecluded Hub ",
            status: "UPCOMING",
            text_body: "Inclusive Finance for Her",
            news_date: "OCTOBER 18TH, 2023"
        },
        {
            key: 2,
            img_src: "upcoming2.png",
            author: "COWRYWISE ",
            status: "",
            text_body: "Women in Blockchain Technology",
            news_date: "FEBRUARY 20TH, 2023"
        },
        {
            key: 3,
            img_src: "upcoming3.png",
            author: "The United Nations",
            status: "",
            text_body: "Gender-Neutral Investing Platforms",
            news_date: "MAY 5TH, 2023"
        },
    ];

    const batch = newsDetails.slice(0, displayCounter);

    return (
        <div className={classes['container']}>
            {batch.map((details) => <NewsItemComponent details = {details} key = {details.key} hasButton />)}
            {displayCounter < newsDetails.length && <LoadMoreBtn onClick={() => { setDisplayCounter(displayCounter + 3) }} />}
            {(displayCounter >= newsDetails.length && newsDetails.length > 3 ) && <LoadLessBtn onClick={() => { setDisplayCounter(displayCounter - 3) }} />}
        </div>
    );
};

export default UpcomingEventsComponent;