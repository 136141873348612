import React from "react";
// import classes from "./GetStartedSection.module.css";
import GetStartedSliderComponent from "./GetStartedSliderComponent";


const GetStartedSection = () => {
    return (
        <GetStartedSliderComponent />
    );
};

export default GetStartedSection;