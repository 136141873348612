import React from 'react';
import Button from "../UI/Button";
import classes from "./HomeLoanSection.module.css";

const HomeLoanSection = () => {
    const btnStyles = {
        display: window.innerWidth <= 1024 ? 'block' : 'block', 
        margin: window.innerWidth <= 1024 ? '0' : '0',
        marginBottom: window.innerWidth <= 1024 ? '0' : '0',
        marginTop: window.innerWidth <= 1024 ? '0' : '0',
        borderRadius: window.innerWidth <= 1024 ? "9px" : '16px',
        border: '2px solid var(--Primary, #EB2590)',
        background: 'var(--Primary, #EB2590)',
        color: '#fff',
        width: window.innerWidth <= 1024 ? '11.188em' : "17.938em",
        height: window.innerWidth <= 1024 ? "3em" : "3.625em",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
    };

    return (
        <div className={classes['loan__section']}>
            <div className={classes['sub__container']}>
                <div className={classes['text__container']}>
                    <p className={classes['loan__tagline']}>
                        We offer loans that power startups & growth
                    </p>
                    <p className={classes['loan__small']}>
                        Shecluded offers growth loans to help women-owned businesses take their 
                        businesses to the next level. These loans are available to businesses that 
                        have been in operation for at least 12 months and have a stable financial history.
                    </p>
                    <Button text={"Get a Loan"} to = {"/loan"} styles = {btnStyles} />
                </div>
                <div className={classes['logo__container']}>
                    <img src="/images/logo_white.svg" className={classes.logo} alt="logo" />
                </div>
            </div>
           
        </div>
    );
}

export default HomeLoanSection;