import React, { useEffect } from 'react';
import HomeBanner from "../components/new components/HomeComponents/HomeBanner";
import HomeHubSection from "../components/new components/HomeComponents/HomeHubSection";
import HomeJoinCommunitySection from "../components/new components/HomeComponents/HomeJoinCommunitySection";
import HomeLoanSection from "../components/new components/HomeComponents/HomeLoanSection";
import HomePartnersIcons from "../components/new components/HomeComponents/HomePartnersIcons";
import HomeTestimonialsSection from "../components/new components/HomeComponents/HomeTestimonialsSection";
import HomeWhatsNewSection from "../components/new components/HomeComponents/HomeWhatsNewSection";
// import HomeWorkSpaceSection from "../components/new components/HomeComponents/HomeWorkSpaceSection";
import HomeStatisticsSection from '../components/new components/HomeComponents/HomeStatisticsSection';
import { useLocation } from 'react-router-dom';
// import AcceleratorProgramsSection from '../components/new components/HubComponents/AcceleratorProgramsSection';

const HomePage = () => {

    const {pathname, hash, key} = useLocation();


    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                element.scrollIntoView();
                }
            }, 0);
        }
    }, [hash, pathname, key]);


    


    return (
        <>
            {/* <Header /> */}
            <HomeBanner />
            <HomeLoanSection />
            {/* <AcceleratorProgramsSection /> */}
            <HomeHubSection />
            <HomeStatisticsSection />
            <HomeTestimonialsSection />
            {/* <HomeWorkSpaceSection /> */}
            <HomeWhatsNewSection />
            {/* <HomeJoinCommunitySection /> */}
            <HomePartnersIcons />
            {/* <Footer /> */}
        </>
    );
};

export default HomePage;