import React from "react";
import BlogPostsSection from "../components/new components/TestimonialsComponents/BlogPostsSection";
import BrilliantMindsSection from "../components/new components/TestimonialsComponents/BrilliantMindsSection";
import EchoesSection from "../components/new components/TestimonialsComponents/EchoesSection";
import TestimonialBannerSection from "../components/new components/TestimonialsComponents/TestimonialBannerSection";


const TestimonialsPage = () => {
    return (
        <>
            <TestimonialBannerSection />
            <BrilliantMindsSection />
            <EchoesSection />
            <BlogPostsSection />
        </>
    );
};

export default TestimonialsPage;