import React from "react";
import classes from "./TitleComponent.module.css";

const TitleComponent = (props) => {
    return (
        <p className={classes['title']}>
            <span className={classes['num']}>{props.num}.</span> 
            <span className={classes['text']}>{props.text}.</span>
        </p>
    );
};

export default TitleComponent;