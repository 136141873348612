import React from "react";
import FAQ from "../FAQComponents/FAQ";


const HubFAQ = () => {
    const accordionData = [
        {
            'key': 1, 
            'title' : 'What is the Shecluded Hub ?', 
            'body' : 'we specialize in providing tailored loans to asset-based businesses across various dynamic industries. Our flexible financing solutions empower companies in sectors such as technology, healthcare, retail, manufacturing, education, hospitality, agriculture, and many more.'
        },
        {
            'key': 2, 
            'title' : 'Can I stay there Overnight ?', 
            'body' : 'we specialize in providing tailored loans to asset-based businesses across various dynamic industries. Our flexible financing solutions empower companies in sectors such as technology, healthcare, retail, manufacturing, education, hospitality, agriculture, and many more.'
        },
        {
            'key': 3, 
            'title' : 'Is it Free to use ?', 
            'body' : 'we specialize in providing tailored loans to asset-based businesses across various dynamic industries. Our flexible financing solutions empower companies in sectors such as technology, healthcare, retail, manufacturing, education, hospitality, agriculture, and many more.'
        },
        {
            'key': 4, 
            'title' : 'How many people currently use the Hub ?', 
            'body' : 'we specialize in providing tailored loans to asset-based businesses across various dynamic industries. Our flexible financing solutions empower companies in sectors such as technology, healthcare, retail, manufacturing, education, hospitality, agriculture, and many more.'
        },
        {
            'key': 5, 
            'title' : 'How Big is the Hub ?', 
            'body' : 'we specialize in providing tailored loans to asset-based businesses across various dynamic industries. Our flexible financing solutions empower companies in sectors such as technology, healthcare, retail, manufacturing, education, hospitality, agriculture, and many more.'
        },
    
    ];
    

    return (
        <FAQ contStyle = {{paddingLeft: ".5em", paddingRight: ".5em", }} title = "FAQ" accordionData = {accordionData} plusMinus black />
    );
};

export default HubFAQ;