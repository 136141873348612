import React, { useEffect, useState } from "react";
import Polygon from "../UI/Polygon";
import classes from "./LoansStepsSection.module.css";
import { useSwipeable } from "react-swipeable";
import { NavLink } from "react-router-dom";

const LoansStepsSection = () => {


    
    useEffect(()=> {
       let timer = setInterval(automate, 200);

        return () => {
            clearInterval(timer);
        };

    });
   
    const [currentIndex, setCurrentIndex] = useState(0);

    const automate = () => {
        setCurrentIndex(currentIndex - 7 >= -(762 - window.innerWidth) ? currentIndex - 7 : 0);
    };

    const swipingHandler = (e) => {
        
        if(e.deltaX > 0) {
            setCurrentIndex(currentIndex < 0 ? currentIndex + 7 : currentIndex);
        } else {
            setCurrentIndex(currentIndex - 7 >= -(762 - window.innerWidth) ? currentIndex - 7 : currentIndex);
            
        }
    };


   const handlers = useSwipeable({
    onSwiping: (e) => {window.innerWidth <= 875 && swipingHandler(e)},
    trackMouse: true,
   });

    const polygonStyles = {
        zIndex: "-1",
        right: window.innerWidth <= 1024 ? "" : "5em",
        marginTop: window.innerWidth <= 1024 ? "" : "-6em",
        width: window.innerWidth <= 1024 ? "1.69em" : "5.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "7.165em",
        position: "relative",
        // display: window.innerWidth <= 1024 && "none",
    }
    const polygonStyles2 = {
        zIndex: "-1",
        right: window.innerWidth <= 1024 ? "" : "24em",
        width: window.innerWidth <= 1024 ? "1.69em" : "5.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "7.165em",
        left: window.innerWidth <= 1024 ? "3.313em" : "17em",
        marginBottom: window.innerWidth <= 1024 ? "" : "-4em",
        // top: window.innerWidth <= 1024 && "3.313em",
        backgroundColor: "#FFEBF6",
        // display: window.innerWidth <= 1024 && "none",
    }
    const polygonStyles3 = {
        zIndex: "-1",
        left: window.innerWidth <= 1024 && "auto",
        right: window.innerWidth <= 1024 ? "8em" : "20em",
        width: window.innerWidth <= 1024 ? "1.69em" : "5.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "7.165em",
        marginLeft: window.innerWidth <= 1024 && "12.85em",
        marginTop: window.innerWidth <= 1024 ? "-2em" : "-6em",
        // display: window.innerWidth <= 1024 && "none",
    }
    const polygonStyles4 = {
        zIndex: "-1",
        left: window.innerWidth <= 1024 ? "auto" : "auto",
        right: window.innerWidth <= 1024 ? "4em" : "10em",
        width: window.innerWidth <= 1024 ? "1.69em" : "5.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "7.165em",
        // marginLeft: window.innerWidth <= 1024 && "15.85em",
        marginTop: window.innerWidth <= 1024 ? "" : "7em",
        backgroundColor: "#FFEBF6",
        // display: window.innerWidth <= 1024 && "none",
    }
    const polygonStyles5 = {
        zIndex: "-1",
        left: window.innerWidth <= 1024 ? "auto" : "auto",
        right: window.innerWidth <= 1024 ? "2em" : "3em",
        width: window.innerWidth <= 1024 ? "3.367em" : "7.68em",
        height: window.innerWidth <= 1024 ? "3.779em" : "9.165em",
        // marginLeft: window.innerWidth <= 1024 && "18.85em",
        // float: window.innerWidth <= 1024 && "right",
        marginTop: window.innerWidth <= 1024 ? "-4em" : "-6em",
        // display: window.innerWidth <= 1024 && "none",
    }
 
    return (
        <div className={classes['container']}>
            <div className={classes['container']}>
                <Polygon style = {polygonStyles} />
                <Polygon style = {polygonStyles2} />
                <Polygon style = {polygonStyles3} />
                <Polygon style = {polygonStyles4} />
                <Polygon style = {polygonStyles5} />
            </div>
           
            <p className={classes['title']}>How to Obtain <span className={classes['next_line']}>Shecluded Growth Loans</span> </p>
            <div {...handlers} className={classes['steps__container']} style={{marginLeft: currentIndex}}>
                <div className={classes['one']}>
                    <div className={classes['step_one']}>
                        <p className={classes['step__number']}>Step 1</p>
                        <p className={classes['step__title']}>Sign Up</p>
                        <p className={classes['step__link']}><NavLink target="_blank" to="/register">See Form</NavLink></p>
                    </div>
                </div>
                <div className={classes['two']}>
                    <div className={classes['step_two']}>
                        <p className={classes['step__number']}>Step 2</p>
                        <p className={classes['step__title']}>Apply</p>
                        <p className={classes['step__link']}><NavLink target="_blank" to="/loanapplication">See Amounts</NavLink></p>
                    </div>
                </div>
                <div className={classes['three']}>
                    <div className={classes['step_three']}>
                        <p className={classes['step__number']}>Step 3</p>
                        <p className={classes['step__title']}>Receive</p>
                        <p className={classes['step__link']}><NavLink target="_blank" to="/loansdashboard">See collection</NavLink></p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default LoansStepsSection;