import React, { useEffect } from "react";
import AboutShecludedSection from "../components/new components/HubComponents/AboutShecludedSection";
import AcceleratorIncubatorSection from "../components/new components/HubComponents/AcceleratorIncubatorSection";
import AcceleratorProgramsSection from "../components/new components/HubComponents/AcceleratorProgramsSection";
import AnnualCohortSection from "../components/new components/HubComponents/AnnualCohortSection";
import CohortBenefitsSection from "../components/new components/HubComponents/CohortBenefitsSection";
import HubBannerSection from "../components/new components/HubComponents/HubBannerSection";
import HubFAQ from "../components/new components/HubComponents/HubFAQ";
import ImpactSoFarSection from "../components/new components/HubComponents/ImpactSoFarSection";
import InsideShecludedSection from "../components/new components/HubComponents/InsideShecludedSection";
import JoinSection from "../components/new components/HubComponents/JoinSection";
import QuoteSection from "../components/new components/HubComponents/QuoteSection";
import SecondQuoteSection from "../components/new components/HubComponents/SecondQuoteSection";
import ShecludedIncubatorSection from "../components/new components/HubComponents/ShecludedIncubatorSection";
import StatSection from "../components/new components/HubComponents/StatSection";
import { useLocation } from "react-router-dom";


const HubPage = () => {

    const {pathname, hash, key} = useLocation();


    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                element.scrollIntoView();
                }
            }, 0);
        }
    }, [hash, pathname, key]);



    return (
        <>
            <HubBannerSection />
            <AboutShecludedSection />
            <AcceleratorIncubatorSection />
            <InsideShecludedSection />
            <ImpactSoFarSection />
            <AcceleratorProgramsSection />
            <QuoteSection />
            <ShecludedIncubatorSection />
            <SecondQuoteSection />
            <AnnualCohortSection />
            <StatSection />
            <CohortBenefitsSection />
            <JoinSection />
            <HubFAQ />
        </>
    );
};

export default HubPage;