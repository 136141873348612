import { duration } from 'moment';
import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useSpring, animated } from 'react-spring';

const testimonials = [
  {
    name: 'Ifeoma',
    quote: '"No stress, no need for collateral, and the interest rate is fantastic"',
    details: '"Shecluded has been a big help to my business. They\'ve given me loans many times, and it\'s easy - . No bank or financial institution can match that. My business not only survived all seasons but is still growing, thanks to Shecluded."',
    image: '/images/testi_1.png', 
  },
  {
    name: 'Damilola',
    quote: '"No stress, no need for collateral, and the interest rate is fantastic"',
    details: '"Shecluded has been a big help to my business. They\'ve given me loans many times, and it\'s easy - . No bank or financial institution can match that. My business not only survived all seasons but is still growing, thanks to Shecluded."',
    image: '/images/testi_2.png',
  },
  {
    name: 'Deborah',
    quote: '"No stress, no need for collateral, and the interest rate is fantastic"',
    details: '"Shecluded has been a big help to my business. They\'ve given me loans many times, and it\'s easy - . No bank or financial institution can match that. My business not only survived all seasons but is still growing, thanks to Shecluded."',
    image: '/images/testi_3.png',
  },
  {
    name: 'Jemima',
    quote: '"No stress, no need for collateral, and the interest rate is fantastic"',
    details: '"Shecluded has been a big help to my business. They\'ve given me loans many times, and it\'s easy - . No bank or financial institution can match that. My business not only survived all seasons but is still growing, thanks to Shecluded."',
    image: '/images/testi_4.png',
  },

];

const TestimonialCard = ({ style, name, quote, details, image }) => {
    return (
      <animated.div
        className="max-w-sm rounded overflow-hidden shadow-lg bg-white absolute w-[248.19px] h-[334.43px] rounded-[50px]"
        style={style}
      >
        <div className="px-6 py-4">
          <p className="text-gray-700 text-base">{quote}</p>
          <p className="text-gray-500 text-xs">{details}</p>
          <div className='flex justify-between items-end'>
              <div className="font-bold text-xl mb-2">{name}</div>
              <div className="w-20 h-20 rounded-full overflow-hidden">
                  <img className="object-cover w-full h-full rounded-full" src={image} alt={name} />
              </div>
          </div>
        </div>
      </animated.div>
    );
  };
  
  const TestimonialComponentMobile = () => {
    const cardOffset = 15; // This determines the vertical and horizontal offset of the cards
    const [cards, setCards] = useState(testimonials.map((t, index) => ({ ...t, zIndex: index, opacity:1 })));
  
    const moveCardToBack = () => {
      setCards(prevCards => {
        const newCards = prevCards.map(card => ({
          ...card,
          opacity: card.zIndex === 0 ? 0 : 1,
          zIndex: card.zIndex === 0 ? prevCards.length - 1 : card.zIndex - 1,
        })).sort((a, b) => a.zIndex - b.zIndex);
        return newCards;
      });
    };
  
    const moveCardToFront = () => {
      setCards(prevCards => {
        const newCards = prevCards.map(card => ({
          ...card,
          opacity: card.zIndex === prevCards.length - 1 ? 0 : 1,
          zIndex: card.zIndex === prevCards.length - 1 ? 0 : card.zIndex + 1,
        })).sort((a, b) => a.zIndex - b.zIndex);
        return newCards;
      });
    };
  
    return (
      <div className="relative my-5 lg:hidden" style={{ height: '600px' }}>
        {cards.map((card, i) => {
          const props = useSpring({

           

            to: {

                opacity:  1
               
            },

            from: {


                top: `${i * cardOffset}px`,
                left: `${i * cardOffset}px`,
                zIndex: cards.length - card.zIndex, // Ensures correct stacking
                transform: `translate(${i * cardOffset}px, ${i * cardOffset}px)`,
                opacity: card.opacity
            

            },
            config: {
                duration: 800
            },

            reset: true,
          });
  
          return <TestimonialCard key={card.name} {...card} style={props} />;
        })}
  
        <div className='absolute bottom-[80px] flex justify-between w-full px-4 py-2 text-[#A7A7A7]'>
          <button onClick={moveCardToFront}>
            <FaAngleLeft size={24} />
          </button>
          <button onClick={moveCardToBack}>
            <FaAngleRight size={24} />
          </button>
        </div>
      </div>
    );
  };
  
  export default TestimonialComponentMobile;