import React from "react";
import classes from "./StaffReviewBannerSection.module.css";

const StaffReviewBannerSection = () => {
    return (
        <div className={classes['container']}>
            <div className={classes['img_container']}>
                <img src="images/theresa.png" alt="" className={classes['img']} />
            </div>
            <div className={classes['text_container']}>
                <div className={classes['sub_container']}>
                    <p className={classes['statement']}>"Being part of Shecluded isn't just work; it's a transformative journey. Witnessing the positive impact on lives makes it truly enjoyable. Unlock your potential with Shecluded."</p>
                    <p className={classes['name']}>Theresa Bright - Lead Brand illustrator</p>
                </div>

            </div>
        </div>
    );
};

export default StaffReviewBannerSection;