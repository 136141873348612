import React, { useRef, useState } from "react";
import Accordion from "../UI/Accordion/Accordion";
import classes from "./LoanWhoCanApplySection.module.css";
import Button from "../UI/Button";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const LoanWhoCanApplySection = () => {

    const titleStyle = {
        color: '#FFF',
        fontFamily: "'Lato', sans-serif",
        fontSize: window.innerWidth <= 1024 ? '15px' : '20px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '119%', /* 30.94px */
        textTransform: 'capitalize',
    
    };
    const bodyStyle = {
        color: '#FFF',
        fontFamily: "'Lato', sans-serif",
        fontSize: window.innerWidth <= 1024 ? '12px' : '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '119%', /* 30.94px */
        textTransform: 'capitalize',
        padding : "1em 0",
    };
    const btnStyles = {
        backgroundColor: "var(--Primary, #EB2590)",
        color: "#FFF",
        width: "8.813em",
        height: "2.538em",
        display: 'block', 
        margin: 'auto',
        marginTop: '3em',
        fontSize: window.innerWidth <= 1024 && "15px",
    };

    const accordionData = [
        {
            'key': 1, 
            'title' : 'Business expansion', 
            'body' : 'we specialize in providing tailored loans to asset-based businesses across various dynamic industries. Our flexible financing solutions empower companies in sectors such as technology, healthcare, retail, manufacturing, education, hospitality, agriculture, and many more.',
            'img' :  "loan_1.jpg",
        },
        {
            'key': 2, 
            'title' : 'Business projects', 
            'body' : 'Our loans fuel innovative business projects, supporting ventures in technology, infrastructure, and sustainable initiatives, empowering entrepreneurs to realize their vision and drive impactful change in various industries.',
            'img' :  "loan_2.jpg",
        },
        {
            'key': 3, 
            'title' : 'Working Capital', 
            'body' : 'Secure the working capital your business needs to thrive. Our loans provide the financial backbone, ensuring smooth operations, covering expenses, and seizing growth opportunities in dynamic markets.',
            'img' :  "loan_3.jpg",
        },
        {
            'key': 4, 
            'title' : 'Business Equipments', 
            'body' : 'Equip your business for success with our tailored loans for essential equipment. From machinery to tech tools, our financing supports acquiring, upgrading, and maintaining equipment for enhanced productivity.',
            'img' :  "loan_4.jpg",
        },
        {
            'key': 5, 
            'title' : 'New Business Site', 
            'body' : 'Expanding to new business sites? Shecluded loans facilitate establishment, renovation, or acquisition of premises, enabling businesses to enter new markets or bolster their local presence with confidence and ease.',
            'img' :  "loan_5.jpg",
        },

    ];

    const [pos, setPos] = useState(1);
 
    
    const sliderRef = useRef();
    const accOpenHandler = (pos) => {
        setPos(pos);
        sliderRef.current.slickGoTo(pos - 1);
    };

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        initialSlide: 0,
        adaptiveHeight: false,
        // autoplaySpeed: 100,
      };



    return (
        <div className={classes['container']}>
            <p className={classes['title']}>
            
                <span className={classes['next_line']}>businesses we Loan to</span>
            </p>

            <div className={classes['sub_container']}>
                <div className={classes['text_container']}>
                    <Accordion titleStyle = {titleStyle} ulStyle = {{borderBottom: "solid 1px #F598D8"}} bodyStyle = {bodyStyle} accordionData = {accordionData} onClick = {accOpenHandler} img />
                    
                </div>
                
                <div className={classes['img_container']}>
                    <Slider ref={sliderRef} {...settings} >
                        {accordionData.map((data) =><img src={"/images/" + data.img} key={data.key} alt="" 
                        className={classes['img']} />)}
                    </Slider>
                </div>
            </div>
            {/* <p className={classes['text_loan']}>Loan Applications are now Open</p> */}
            <Button text = "Apply" to = "/loanapplication" styles = {btnStyles} />
        </div>
    );
};

export default LoanWhoCanApplySection;