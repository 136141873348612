import { FaAngleRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import React from 'react';

const SectionThree = () => {
  return (
    <div className="w-auto">
      <div className="overflow-x-auto lg:overflow-x-hidden w-[94vw] lg:w-auto flex max-w-[75em] mx-auto pt-20 custom-scrollbar gap-5 lg:gap-0 ">
        <div className="flex-1 min-w-full lg:min-w-0">
          <img
            src="/images/sectionthree_1.png"
            alt="sectionthree_1"
            className="w-full min-h-[404.95px] lg:h-auto"
          />
          <div className="flex flex-col p-4">
            <p className="font-[700] text-[24px] leading-[28.8px] font-lato ">
              Doyin, <span className="italic font-[500]">Rewanye</span>
            </p>
            <p className="font-[400] text-[14px] leading-[16.8px] font-lato ">
              “Our first bale was financed by shecluded and we were able to
              hire more staff . Taking a loan from Shecluded has grown the
              business and given us a soft landing during the rocky periods.
              Thank you Shecluded.”
            </p>
          </div>
        </div>
        <div className="flex-1 relative min-w-full lg:min-w-0">
          <img
            src="/images/sectionthree_2.png"
            alt="sectionthree_2"
            className="w-full"
          />
          <div className="absolute flex w-full h-[112.63px] z-[1.9999999999] mt-[-112.63px]">
            <div className="flex-1 min-h-[112.63px]"></div>
            <div className="bg-custom-primary flex-1"></div>
          </div>
          <div className="flex flex-col bg-custom-primary p-4">
            <p className="font-[700] text-[24px] leading-[28.8px] font-lato text-white">
              Ameenah,{" "}
              <span className="italic font-[500] text-custom-sect2">
                OfadaHub
              </span>
            </p>
            <p className="font-[400] text-[14px] leading-[16.8px] font-lato text-white">
              “Acquiring a loan from Shecluded enabled us to procure and stock
              Raw materials in larger quantities ahead of the price hike. Thanks
              to Shecluded, we commenced processing!"
            </p>
          </div>
        </div>
        <div className={`flex-1 min-w-full  lg:min-w-0 flex ${window.innerWidth <= 1024 && "pb-3"}`} >
            <div className="bg-custom-sect3 flex flex-col justify-end min-w-full">
                <img
                    src="/images/sectionthree_3.png"
                    alt="sectionthree_3"
                    className=" rounded-t-[240px] w-[319.51px] lg:w-[369.51px] mx-auto h-[444.95px] lg:h-auto "
                />

                <div className="flex-[.67] flex flex-col p-4 ">
                    <p className="flex-1 font-[700] text-[22px] leading-[28.8px] font-lato ">
                    Uchenna,{" "}
                    <span className="italic text-[22px] font-[500] text-white">
                        Ivyleague Collections
                    </span>
                    </p>
                    <p className="flex-1 font-[400] text-[14px] leading-[16.8px] font-lato "></p>
                </div>
            </div>
        </div>
      </div>
      <div className=" max-w-[90vw] lg:max-w-[75em] flex justify-start lg:justify-end lg:mt-3 mx-auto">
        <NavLink
            to="/testimonials"
            className="flex lg:w-[90px] h-[39px]  justify-between !no-underline
            items-center text-[18px] lg:text-[15px] font-[500] lg:font-[800] leading-[20.4px] text-custom-primary "
          >
                See More <FaAngleRight  />
          </NavLink>
      </div>
    </div>
  );
};
export default SectionThree;
