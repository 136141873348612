import React from "react";
import classes from "./AboutHubSection.module.css";


const AboutHubSection = () => {
    return (
        <div className={classes['container']}>
            <div className={classes['heading']}>
                <p className={classes['title']}>Briefly About the Hub</p>
            </div>
            <p className={classes['text_body']}>Welcome to the Shecluded Hub, the ultimate sanctuary for female entrepreneurs seeking to flourish in the world of business. Designed exclusively for women, this dynamic hub is a haven where dreams become reality.At Shecluded, we offer an inclusive and supportive ecosystem that fosters growth and innovation. Whether you're launching a startup or scaling your existing venture, our community of like-minded women provides the inspiration, mentorship, and resources you need to succeed.From collaborative co-working spaces to expert-led workshops and networking events, Shecluded Hub is your all-inclusive destination for empowerment. Discover the strength in sisterhood and propel your business aspirations to new heights at Shecluded Hub. Join us and thrive in a world where your success knows no bounds.</p>
        </div>
    );
};

export default AboutHubSection;