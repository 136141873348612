import React, { Component } from 'react';
import LanguageSelector from "../../UI/LanguageSelector";
import classes from "./MiddlePane.module.css";
import Socials from "./Socials";



const MiddlePane = () => {
    const switchLang = {
        textAlign: 'left',
        cursor: 'pointer',
        zIndex: '50',
        top: '20px',
        right: '20px',
    }
    return(
        <div className={classes.container}>
            <div className={classes['lang-selector']}><LanguageSelector switchLang = {switchLang} fullName showFlag /></div>
            <div className={classes['socials']}><Socials /></div>
        </div>
    );
};


export default MiddlePane;