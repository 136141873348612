import React from "react";
import Button from "../UI/Button";
import classes from "./NewsItemComponent.module.css";
// window.innerWidth <= 1024 ?
const NewsItemComponent = (props) => {
    const btnStyles = {
        float: "left", 
        width: "7.625em", 
        overflow: "auto", 
        marginTop: window.innerWidth > 1024 && "1.2em", 
        padding:"0", 
        height: "2.375em", 
        borderRadius: "8px", 
        background: "#000", 
        color: "#FFF", 
        fontSize: "15px", 
        fontWeight: "500",
        marginLeft:  window.innerWidth <= 1024 ? ".5em" : "1em",
    };
    return (
        <div className={classes["container"]}>
            <img src={"/images/" + props.details.img_src}  alt="" className={classes["img"]} />
            <div className={classes["text_container"]}>
                <div className={classes["heading"]}>
                    <span className={classes["author"]}>{props.details.author}</span>
                    {props.details.status !== "" && <span className={classes["status"]}>{props.details.status}</span>}
                </div>
                 <p className={classes["text_body"]} style={props.hasButton && {float: "left", width: window.innerWidth <= 1024 ? "10em" : "10em"}}>{props.details.text_body}</p>
                 {props.hasButton && <Button text="Register" to = "/" styles = {btnStyles} />}
                <p className={classes["news_date"]}>{props.details.news_date}</p>
                
            </div>
        </div>
    );
};

export default NewsItemComponent;