import React from "react";
import classes from "./PrivacyAndSecuritySection.module.css";
import TitleComponent from "./TitleComponent";

const PrivacyAndSecuritySection = () => {
    return (
        <>
            <TitleComponent num = {6} text={" Privacy and Security"} />
            <ul className={classes['ul']}>
                <li className={classes['li']}>Privacy. Information about how Shecluded works to protect your privacy can be found in our privacy policy</li>
                <li className={classes['li']}>Electronic Communications.All e-mail sent to and from Shecluded will be received or otherwise recorded by the Shecluded e-mail system and is subject to archival, monitoring or review by and/or disclosure to, someone other than the recipient. Communications through the website may involve the electronic transmission to any e-mail address you provided to us, of information that you may consider to be personal financial information, and you agree and consent to such transmission of such information.</li>
                <li className={classes['li']}>Confidentiality of Information.Shecluded has taken reasonable steps to ensure the confidentiality of information taken through the Website and transmitted via the Internet. However, unexpected changes in technology may be used by unauthorised third parties to intercept confidential information, and we cannot be responsible should confidential information be intercepted and subsequently used by an unintended recipient.</li>
                <li className={classes['li']}>Data Collection.When you visit the Shecluded website, we collect certain anonymous information about you. We also collect information that you input into our Website. We use this information to understand our visitors and business better, provide the services you request, and enhance the Shecluded service</li>
            </ul>
        </>
    );
};

export default PrivacyAndSecuritySection;