import React from "react";
import FormSection from "../components/new components/RolesComponents/FormSection";
import RoleDescriptionSection from "../components/new components/RolesComponents/RoleDescriptionSection";
import RolePositionsSection from "../components/new components/RolesComponents/RolePositionsSection";
import RoleQualificationsSection from "../components/new components/RolesComponents/RoleQualificationsSection";
import RoleResponsibilitiesSection from "../components/new components/RolesComponents/RoleResponsibilitiesSection";
import RoleTitleSection from "../components/new components/RolesComponents/RoleTitleSection";
import { useParams } from "react-router-dom";
import classes from "../components/new components/RolesComponents/Temp.module.css";


const RolePage = () => {

    const { position } = useParams();
    
    const Jobs = [
        {
            key: 1,
            Title: "Head Of Growth",
            Description: [
                "Shecluded is looking to hire a passionate and results-driven Head of Growth to help us scale new heights in our mission to support more women entrepreneurs across Africa and the world. So far, we have provided more than $1.1 million in growth financing for 400 women-led businesses and supported over 5,000 female entrepreneurs in partnership with key international partners that include GIZ and CFYE.",
                "Are you ready to be a part of something transformative? As a senior, director-level Head of Growth at Shecluded, you will be responsible for driving revenue growth, forging strategic partnerships, and developing new offerings and programs that are changing the game for women in business.",
                "We are looking for a trailblazer with extensive experience in marketing, sales, and strategic business development – someone who has a track record of achieving targets and driving financial success. Beyond that, if you've got a knack for securing grants, funding, partnerships, or sponsorships from a diverse range of sources, that will be a huge advantage.",
                "If you're excited to be part of our journey and make a real impact, we want to hear from you. Apply now via the link below, and let's change the world together! 🌍💼💪",
                "#ShecludedGrowthHead #BuildWomenInBusiness #JoinTheMovement"
            ],
            Link: "https://forms.gle/mZmBfA4xNH2E2P516"

        },

        {
            key: 2,
            Title: "Director Of Programs",
            Description: [
                "Shecluded is searching for a dedicated and visionary Director of Programs to lead our mission to new heights. As a senior leader at Shecluded, you will be responsible for designing, planning, and executing our transformative training, accelerator, and incubation programs - all with a laser focus on delivering maximum impact for women entrepreneurs.",
                "In the last two years, Shecluded has curated several signature capacity development programs that have benefitted over 5,000 female entrepreneurs in partnership with key international partners that include GIZ and CFYE.",
                "Do you have what it takes to drive stakeholder engagement with our partners and trainees, ensure top-notch program quality, and showcase our program outcomes through effective reporting?",
                "If you're experienced in program development and delivery, curriculum design, and stakeholder engagement, we want to hear from you. An understanding of impact measurement and a passion for women entrepreneurship will set you up for success.",
                "Join us in transforming lives and creating more opportunities for women in business",
                "Apply now and be a part of the Shecluded story! 🌍📚💪 #ShecludedDirectorOfPrograms #EmpowerWomenInBusiness #JoinTheMovement"
            ],
            Link: "https://forms.gle/KXAojgfuYVasDBb96"

        }
    ];

    const Job = Jobs[(position === "headofgrowth" ? 0 : position === "directorofprograms" && 1)];

    const Desc = Job.Description;


    return (
        <>
            <RoleTitleSection title = {Job.Title} />
            {/* <RolePositionsSection /> */}
            <RoleDescriptionSection deadline = "OPEN TILL JANUARY 1ST" description = {[Desc]} />
            {/* <RoleQualificationsSection /> */}
            {/* <RoleResponsibilitiesSection /> */}
            {/* <FormSection /> */}
            <div className={classes['btn']}><a href={Job.Link}>Apply</a></div>
        </>
    );
};

export default RolePage;