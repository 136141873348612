import React from "react";
import classes from "./GetStartedSliderComponent.module.css";

const GetStartedSliderComponent = () => {
    return (
        <div className={classes['container']}>
            <div className={classes['sub_container']}>
                <div className={classes['text_container']}>
                    <p className={classes['step_1']}>Step 1</p>
                    <p className={classes['title']}>Sign Up</p>
                    <p className={classes['text_body']}>To sign up for loans with Shecluded, we've streamlined the process to make it as accessible and straightforward as possible. Here's what you'll need to get started on your journey to securing financial support for your business After registering, take a moment to complete your profile. This step is essential as it helps us understand your unique business needs better. Providing accurate information about your business and financial situation allows us to tailor loan options to your specific requirements.</p>
                </div>
                <div className={classes['img_container']}>
                    <img src="/images/sign_up.png" alt="" className={classes['img']} />
                </div>
                <div className={classes['navigators']}>
                    <img src="/images/Arrow - Left Circle.png" alt="" className={classes['nav']}  />
                    <img src="/images/Arrow - Right Circle.png" alt="" className={classes['nav']} />
                </div>
            </div>
            <p className={classes['last']}>Get Started</p>
        </div>
    );
};

export default GetStartedSliderComponent;