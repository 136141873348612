import React from "react";
import classes from "./whatwedo.module.css";

const WhatWeDO = () => {
    return (
        <div className={classes['banner__container']}>
            <p className={classes['tag']}>What We Do</p>
            <h1 className={classes['word_two']}>Holistic Business Support <br></br>for
            <span> Women Entrepreneurs</span></h1>

            <div className={classes['vision-list']}>
                <div className={classes['vision-tabs']}>
                    <div className={classes['vision-tab']}>
                        <div className={classes['dot']}>
                            <img src="/images/square-icon-white.png" className={classes['dot-icon']}></img>
                        </div>
                        <div className={classes['vision-story']}>
                            <h1 className={classes['story-title']}>Strategic Business Coaching</h1>
                            <p className={classes['word_three']}>Our business coaching services help women navigate complex markets by providing tailored guidance on strategy, investment pitches, and expansion. We empower entrepreneurs to refine their business models and make informed decisions 
                            </p>
                        </div>
                    </div>
                    <div className={classes['vision-tab']}>
                        <div className={classes['dot']}>
                            <img src="/images/square-icon-white.png" className={classes['dot-icon']}></img>
                        </div>
                        <div className={classes['vision-story']}>
                            <h1 className={classes['story-title']}>Access to Capital</h1>
                            <p className={classes['word_three']}>Financial inclusion is  at the core of our mission, Shecluded provide women with access to grants, loans, and investment opportunities. We’ve helped secure over 65 successful grants, empowering women to access the capital they need for growth
                            </p>
                        </div>
                    </div>
                    <div className={classes['vision-tab']}>
                        <div className={classes['dot']}>
                            <img src="/images/square-icon-white.png" className={classes['dot-icon']}></img>
                        </div>
                        <div className={classes['vision-story']}>
                            <h1 className={classes['story-title']}>Financial Literacy & Business Education</h1>
                            <p className={classes['word_three']}>Our business training programs equip women with the financial literacy to manage cash flow, set competitive prices, and ensure long-term sustainability. We empower women to build not just businesses, but profitable, enduring enterprises
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhatWeDO;