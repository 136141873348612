import React from "react";
import Button from "../UI/Button";
import InPageLink from "../UI/InPageLink";
import classes from "./HubBannerSection.module.css";

const HubBannerSection = () => {
    const btnStyles = {
        background: "#EB2590",
        color: "#fff",
        display: window.innerWidth <= 1024 ? "block" : "block",
        float: window.innerWidth <= 1024 ? "none" : "left",
        overflow: "auto",
        margin: window.innerWidth <= 1024 && "auto",
        marginTop: window.innerWidth <= 1024 && ".9em",
        width: window.innerWidth <= 1024 && "9em",
        borderRadius: window.innerWidth <= 1024 && "9px",
        height: window.innerWidth <= 1024 && "2.5em",
        marginRight: window.innerWidth <= 1024 && "0",
        fontSize: "14px",
    };
    
    const linkStyle = {
        color: '#FFF',
        textAlign: 'left',
        fontFamily: "'Lato', sans-serif",
        fontSize: window.innerWidth <= 1024 ? "15px" : '18px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        
    };
    return (
        <div className={classes['container']}>
            <p className={classes['tag']}>SHECLUDED HUB</p>
            <p className={classes['title']}>
                <span className={classes['next_line']}>An <span className={classes['ecosystem']}>Ecosystem</span> for </span> 
                <span className={classes['next_line']}>Women Entrepreneurs</span> 
                <span className={classes['next_line']}>to grow</span>
            </p>
            <div style={{display: "flex", margin:  window.innerWidth > 1024 ? "0" : "auto", 
                width:  window.innerWidth <= 1024 ? "auto" : "28em", gap: "1em"}}>
                <div style={{flex:1,}}><Button to = "https://hub.shecluded.com" text = "Host an Event" styles = {btnStyles} out /></div>
                <div style={{flex:1,}}><p className={classes['link']}><a href ="https://hub.shecluded.com" style = {linkStyle} >Book a Space</a></p></div>
            </div>
        </div>
    );

};

export default HubBannerSection;