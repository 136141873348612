import React from "react";
import classes from "./LoansTestimonialSection.module.css";
import { NavLink } from "react-router-dom";

const LoansTestimonialSection = () => {
    return (
        <div className={classes['container']}>
            <div className={classes['sub_container']}>
                <div className={classes['text_container']}>
                    <p className={classes['title']}><span className={classes['her']}>Her </span>Thoughts on 
                                            <span className={classes['next_line']}>Shecluded</span> 
                                            <span className={classes['next_line']}>Growth Loans</span></p>
                    <div className={classes['testimony__container']}>
                        <p className={classes['testifier']}>Ibukun</p>
                        <p className={classes['testifier__position']}>FOUNDER CLOTHSHE</p>
                        <p className={classes['testimony']}>"Shecluded Hub's loan transformed my financial journey completely. Their flexible terms, low rates, and supportive team made reaching my goals a reality. Grateful beyond words."</p>
                    </div>
                </div>
                <div className={classes['img_container']}>
                    <img src="/images/loan_testimony.png" alt="" className={classes['img']} />
                </div>
                <p className={classes['link_p']}><NavLink className={classes['link_a']} to="/testimonials" >See more</NavLink></p>
            </div>
        </div>
    );
};

export default LoansTestimonialSection;