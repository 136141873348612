import React from "react";
import { useState } from "react";
import classes from "./Accordion.module.css";

const AccordionContainerFAQ = (props) => {


    const noBullet = {
        listStyleType: "none"
    }

    const accType = (props.plusMinus) ? ((props.openAccordion === props.position) ? (props.black) ? classes['black_minus'] : classes['minus'] : 
                                                            (props.black) ? classes['black_plus'] : classes['plus']) : 
                                        ((props.openAccordion === props.position) ? classes['down'] : "");

    const pClass = (props.openAccordion === props.position) ? classes['accordion__title'] + " " + accType : classes['accordion__title'] + " " + accType;

    const ulClass = (props.openAccordion === props.position) ? classes['accordion__ul'] + " " + classes['display-block'] : classes['accordion__ul'];

    return (
        <div className={classes['accordion__container']}>
            <p className={pClass} onClick={() => {props.clickHandler(props.position)}} style={props.titleStyle}>{props.title}</p> 
            {typeof props.body === "object" ? <ul className={ulClass} style={props.ulStyle}>
                {props.body.map((item, idx) => (<li className={classes['accordion__li']} style={props.bodyStyle} key={idx}>
                    {(item.title !== "") && <p>{item.title}</p>}
                    {(item.texts.length === 1) && <p>{item.texts[0]}</p>}
                    {(item.texts.length > 1) && <ul> {item.texts.map((text, idx) => <li key={idx}>{text}</li>)}</ul>}
                
                </li>))}
            </ul> : <p className={classes['accordion__li']} style={props.bodyStyle}>{props.body}</p>}
            
        </div>
    );
};

export default AccordionContainerFAQ;