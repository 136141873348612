import React from "react";
import SupportFormSection from "../components/new components/SupportComponents/SupportFormSection";

const SupportPage = () => {
    return (
        <>
            <SupportFormSection />
        </>
    );
};

export default SupportPage;