import React, { Component } from 'react';
import Button from "../UI/Button";
import classes from "./NotFoundSection.module.css";


const NotFoundSection = () => {

    const btnStyles = {
        borderRadius: '12px',
        background: '#000',
        display: "block",
        margin: "auto",
        marginTop: "2em",
        color: "#fff",
    };

    return (
        <div className={classes['container']}>
            <img src="/images/not_found.png" alt="" className={classes['img']} />
            <p className={classes['title']}>Hi ,Something is Wrong</p>
            <p className={classes['note']}>The page you are looking for has either been moved, renamed or does not exist !</p>
            <Button text="Back Home" styles = {btnStyles} to= "/" />
        </div>
    );
};

export default NotFoundSection;