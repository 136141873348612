import React from 'react';
import { useSpring, animated } from 'react-spring';

const testimonials = [
  {
    name: 'Ifeoma',
    quote: '"No stress, no need for collateral, and the interest rate is fantastic"',
    details: '"Shecluded has been a big help to my business. They\'ve given me loans many times, and it\'s easy - . No bank or financial institution can match that. My business not only survived all seasons but is still growing, thanks to Shecluded."',
    image: '/images/testi_1.png', 
  },
  {
    name: 'Damilola',
    quote: '"No stress, no need for collateral, and the interest rate is fantastic"',
    details: '"Shecluded has been a big help to my business. They\'ve given me loans many times, and it\'s easy - . No bank or financial institution can match that. My business not only survived all seasons but is still growing, thanks to Shecluded."',
    image: '/images/testi_2.png',
  },
  {
    name: 'Deborah',
    quote: '"No stress, no need for collateral, and the interest rate is fantastic"',
    details: '"Shecluded has been a big help to my business. They\'ve given me loans many times, and it\'s easy - . No bank or financial institution can match that. My business not only survived all seasons but is still growing, thanks to Shecluded."',
    image: '/images/testi_3.png',
  },
  {
    name: 'Jemima',
    quote: '"No stress, no need for collateral, and the interest rate is fantastic"',
    details: '"Shecluded has been a big help to my business. They\'ve given me loans many times, and it\'s easy - . No bank or financial institution can match that. My business not only survived all seasons but is still growing, thanks to Shecluded."',
    image: '/images/testi_4.png',
  },

];

const TestimonialCard = ({ name, quote, details, image }) => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-md bg-white">
      <div className="px-6 py-4">
        <p className="text-gray-700 text-base">{quote}</p>
        <p className="text-gray-500 text-xs">{details}</p>
        <div className='flex justify-between items-end'>
            <div className="font-bold text-xl mb-2">{name}</div>
            <div className="w-[80px] h-[80px] rounded-full"><img className="object-cover w-full h-full rounded-full" src={image} alt={name} /></div>
        </div>
      </div>
    </div>
  );
};

const TestimonialComponent = () => {
  const [index, setIndex] = React.useState(0);
 

  return (
    <div className="hidden lg:flex items-center gap-3 justify-between py-5">
      {testimonials.map((testimonial, i) => (
        <animated.div
          key={i}
        >
          <TestimonialCard {...testimonial} />
        </animated.div>
      ))}
    </div>
  );
};

export default TestimonialComponent;
