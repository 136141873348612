import React, { useEffect } from "react";
import CollaborationSection from "../components/new components/StoryLeadershipComponents/CollaborationSection";
import JoinShecludedSection from "../components/new components/StoryLeadershipComponents/JoinShecludedSection";
import MeetFounder from "../components/new components/StoryLeadershipComponents/MeetFounder";
import StoryLeadershipBanner from "../components/new components/StoryLeadershipComponents/StoryLeadershipBanner";
import StoryLeadershipMissionSection from "../components/new components/StoryLeadershipComponents/StoryLeadershipMissionSection";
// import StoryLeadershipStorySection from "../components/new components/StoryLeadershipComponents/StoryLeadershipStorySection";
import StoryLeadershipVisionSection from "../components/new components/StoryLeadershipComponents/StoryLeadershipVisionSection";
import ValuesSection from "../components/new components/StoryLeadershipComponents/ValuesSection";
import MilestonesSection from "../components/new components/StoryLeadershipComponents/MilestonesSection";
import { useLocation } from "react-router-dom";


const StoryLeadershipPage = () => {

    const {pathname, hash, key} = useLocation();


    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                element.scrollIntoView();
                }
            }, 0);
        }
    }, [hash, pathname, key]);

    return (
        <>
            <StoryLeadershipBanner />
            <MilestonesSection />
            {/* <StoryLeadershipStorySection /> */}
            <StoryLeadershipVisionSection />
            <StoryLeadershipMissionSection />
            <CollaborationSection />
            <ValuesSection />
            {/* <MeetFounder /> */}
            <JoinShecludedSection />
        </>
    );
};

export default StoryLeadershipPage;