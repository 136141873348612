import React, { Component } from 'react';
import NotFoundSection from "../components/new components/NotFoundComponents/NotFoundSection";
import Header from '../components/new components/Header/Header';
import Footer from '../components/new components/Footer/Footer';


const NotFoundPage = () => {
    return (
        <>
            {/* <Header /> */}
            <NotFoundSection />
            {/* <Footer /> */}
        </>
    );
};

export default NotFoundPage;