import React from "react";
import classes from "./AboutShecludedSection.module.css";


const AboutShecludedSection = () => {
    return (
        <div className={classes['container']}>
            <div className={classes['sub_container']}>
                <div className={classes['text_container']}>
                    <p className={classes['text_body']}>
                            Shecluded Hub is about 
                        <span className={classes['next_line']}> Collaboration for Growth.</span>
                        <span className={classes['next_line']}><span className={classes['so_far']}> So far</span>, 
                        <span className={classes['stat']}> 300+ women</span> have </span> 
                        <span className={classes['next_line']}>come together to be a part</span>
                        <span className={classes['next_line']}>of it all.</span>
                    </p>
                </div>
                <div className={classes['img_container']}></div>
            </div>
        </div>
    );
};

export default AboutShecludedSection;