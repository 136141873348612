import React from "react";
import classes from "./QuoteSection.module.css";
import Polygon from "../UI/Polygon";


const QuoteSection = () => {

    const polygonStyles = {
        zIndex: "-1",
        left: window.innerWidth <= 1024 ? "0" : "0",
        marginTop: window.innerWidth <= 1024 ? "-5em" : "-8em",
        width: window.innerWidth <= 1024 ? "1.69em" : "5.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "7.165em",
        position: "absolute",
        backgroundColor: "rgba(255, 201, 92, .2)",
        // zIndex: window.innerWidth <= 1024 && "1",
        opacity: "0.6",
        transform: "rotate(34.914deg)",
    };

    
    const polygonStyles1 = {
        zIndex: "-1",
        left: window.innerWidth <= 1024 ? "1em" : "12em",
        marginTop: window.innerWidth <= 1024 ? "5em" : "15em",
        width: window.innerWidth <= 1024 ? "1.69em" : "5.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "7.165em",
        position: "absolute",
        backgroundColor: "rgba(255, 201, 92, 0.20)",
        // zIndex: window.innerWidth <= 1024 && "1",
        opacity: "0.6",
        transform: "rotate(34.914deg)",
    };

    const polygonStyles2 = {
        zIndex: "-1",
        left: window.innerWidth <= 1024 ? "5em" : "30em",
        marginTop: window.innerWidth <= 1024 ? "-2em" : "-5em",
        width: window.innerWidth <= 1024 ? "1.69em" : "3.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "5.165em",
        position: "absolute",
        backgroundColor: "rgba(255, 201, 92, 0.20)",
        // zIndex: window.innerWidth <= 1024 && "1",
        opacity: "0.6",
        transform: "rotate(34.914deg)",
    };

    const polygonStyles3 = {
        zIndex: "-1",
        left: window.innerWidth <= 1024 ? "10em" : "38em",
        marginTop: window.innerWidth <= 1024 ? "5em" : "10em",
        width: window.innerWidth <= 1024 ? "1.69em" : "3.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "5.165em",
        position: "absolute",
        backgroundColor: "rgba(255, 201, 92, 0.20)",
        // zIndex: window.innerWidth <= 1024 && "1",
        opacity: "0.6",
        transform: "rotate(34.914deg)",
    };

    const polygonStyles4 = {
        zIndex: "-1",
        right: window.innerWidth <= 1024 ? "1em" : "1em",
        marginTop: window.innerWidth <= 1024 ? "5em" : "15em",
        width: window.innerWidth <= 1024 ? "1.69em" : "5.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "7.165em",
        position: "absolute",
        backgroundColor: "rgba(255, 201, 92, 0.20)",
        // zIndex: window.innerWidth <= 1024 && "1",
        opacity: "0.6",
        transform: "rotate(34.914deg)",
    };

    const polygonStyles5 = {
        zIndex: "-1",
        right: window.innerWidth <= 1024 ? "7em" : "20em",
        marginTop: window.innerWidth <= 1024 ? "3em" : "12em",
        width: window.innerWidth <= 1024 ? "1.69em" : "3.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "5.165em",
        position: "absolute",
        backgroundColor: "rgba(255, 201, 92, 0.20)",
        // zIndex: window.innerWidth <= 1024 && "1",
        opacity: "0.6",
        transform: "rotate(34.914deg)",
    };

    const polygonStyles6 = {
        zIndex: "-1",
        right: window.innerWidth <= 1024 ? "4em" : "12em",
        marginTop: window.innerWidth <= 1024 ? "6em" : "22em",
        width: window.innerWidth <= 1024 ? "1.69em" : "3.68em",
        height: window.innerWidth <= 1024 ? "1.897em" : "5.165em",
        position: "absolute",
        backgroundColor: "rgba(255, 201, 92, .2)",
        // zIndex: window.innerWidth <= 1024 && "1",
        opacity: "0.6",
        transform: "rotate(34.914deg)",
    };

    return (
        <div className={classes['container']}>
            <Polygon style = {polygonStyles} />
            <Polygon style = {polygonStyles1} />
            <Polygon style = {polygonStyles2} />
            <Polygon style = {polygonStyles3} />
            <Polygon style = {polygonStyles4} />
            <Polygon style = {polygonStyles5} />
            <Polygon style = {polygonStyles6} />
            <p className={classes['quote']}>
                "What struck me most about the Shecluded Hub is the remarkable access to resources. Women from all walks of life come together, and the wealth of resources available here is simply <span className={classes['highlight']}>awe-inspiring.</span>. It's an exciting place to be!"
            </p>
            <p className={classes['quoter']}>- Flavor Blessing</p>
        </div>
    );
};
export default QuoteSection;