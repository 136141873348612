import React from "react";
import classes from "./FailureToComplySection.module.css";
import TitleComponent from "./TitleComponent";

const FailureToComplySection = () => {
    return (
        <>
            <TitleComponent num = {9} text = {" Failure to comply with Terms of Service"} />
            <ol className={classes['ol']}>
                <li className={classes['li']}>Shecluded may suspend or terminate your account and/or deny you access to use the Website, without prior notice, if you engage in any conduct that Shecluded believes, in its sole discretion: (a) violates any term or provision of the Terms and Conditions, (b) violates the rights of Shecluded or third parties, or (c) is otherwise inappropriate for continued access and use of the service. In the event of any suspension or termination, your right to access the Website and your Shecluded account will be terminated</li>
            </ol>
        </>
    );
};

export default FailureToComplySection;