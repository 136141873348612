import React from "react";
import classes from "./LanguageSelector.module.css";

const LanguageSelector = (props) => {
    
    return (
        <div className={classes['switch-lang']} style={props.switchLang}> 
            <div className={classes["current-lang"]}>
                {props.showFlag && <img className={classes["lang-flag"]} alt="" src="https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_United_Kingdom.png"/>}
                <p className={classes["lang-text"]}>{props.fullName ? 'English' : 'ENG'}</p>
            </div>
            {/* <div className={classes["lang-dropdown"]}>
                <div className={classes["selecting-lang"]}>
                {props.showFlag && <img className={classes["lang-flag"]} alt="" src="https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_Spain.png"/>}
                <p className={classes["lang-text"]}>{props.fullName ? 'Español' : 'ESP'}</p>
                </div>
                <div className={classes["selecting-lang"]}>
                {props.showFlag &&<img className={classes["lang-flag"]} alt="" src="https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_France.png"/>}
                <p className={classes["lang-text"]}>{props.fullName ? 'Français' : 'FRN'}</p>
                </div>
                <div className={classes["selecting-lang"]}>
                {props.showFlag &&<img className={classes["lang-flag"]} alt="" src="https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_Italy.png"/>}
                <p className={classes["lang-text"]}>{props.fullName ? 'Italiano' : 'ITL'}</p>
                </div>
                <div className={classes["selecting-lang"]}>
                {props.showFlag &&<img className={classes["lang-flag"]} alt="" src="https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_Germany.png"/>}
                <p className={classes["lang-text"]}>{props.fullName ? 'Deutsch' : 'DEU'}</p>
                </div>
            </div> */}
        </div>
    );
};

export default LanguageSelector;