import React from "react";
import classes from "./banner.module.css";

const ImageBanner = () => {
    return (
        <div className={classes['banner__container']}>

            <img src="/images/banner.png" className={classes['banner']}></img>

             
        </div>
    );
};

export default ImageBanner;