import React from "react";
import classes from "./EventOfDefaultSection.module.css";
import TitleComponent from "./TitleComponent";


const EventOfDefaultSection = () => {
   return (
    <>
        <TitleComponent num = {5} text = {" Event of Default"} />
        <p className={classes['body']}>Default in terms of this Agreement will occur if:</p>
        <ol className={classes['ol']}>
            <li className={classes['li']}>The Borrower fails to make any scheduled repayment in full on or before the payment due date in accordance with the repayment plan given to the Borrower by the Lender;</li>
            <li className={classes['li']}>Any representation/information, warranty or assurance made or given by the Borrower in connection with the application for this loan or any information or documentation supplied by the Borrower is later discovered to be materially incorrect; or</li>
            <li className={classes['li']}>The Borrower does anything which may prejudice the Lender's rights in terms of this Agreement or causes the Lender to suffer any loss or damage.</li>
        </ol>
        <p className={classes['body']}>In the event of default;</p>
        <ol className={classes['ol']}>
            <li className={classes['li']}>The Lender reserves the right to assign its right, title and interest under the Agreement to an external Collections Agency who will take all reasonable steps to collect the outstanding loan amount.</li>
            <li className={classes['li']}>The Lender also reserves the right to institute legal proceedings against the defaulting Borrower and is under no obligation to inform the Borrower before such proceedings commence.</li>
            <li className={classes['li']}>The Lender shall distribute and use the Borrower’s personal information on social media or on other platforms as it deems fit.</li>
        </ol>
    </>
   );
};

export default EventOfDefaultSection;