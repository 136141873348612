import React from 'react';
import Ellipse from "../UI/Ellipse";
import classes from "./HomeBanner.module.css";
import ImageDoorShape from "../UI/ImageDoorShape";
import Button from "../UI/Button";
import { NavLink } from 'react-router-dom';
import InPageLink from '../UI/InPageLink';


const HomeBanner = () => {
    const imgStyles = {
        width:  window.innerWidth <= 1024 ? "17em" : "",
        height: window.innerWidth <= 1024 ? "" : "30em",
        borderRadius: "200px 200px 0px 0px",
        display: "block",
        // "boxShadow": "0px 4px 10px 0px rgba(172, 172, 172, 0.25)",
        margin: "auto",
        marginTop: window.innerWidth <= 1024  && "3em",
    };
    const btnStyles = {
        display: window.innerWidth <= 1024 ? 'inline-block' : 'inline-block', 
        margin: window.innerWidth <= 1024 ? '0' : '0',
        marginBottom: window.innerWidth <= 1024 ? '0' : '0',
        marginTop: window.innerWidth <= 1024 ? '0' : '0',
        marginRight: window.innerWidth <= 1024 ? '2em' : '0',
        border:  window.innerWidth <= 1024 && 'solid 2px #EB2590',
        backgroundColor: 'var(--Primary, #EB2590)',
        color: '#fff',
        width: "8.375em",
        height: "2.7em",
        borderRadius: window.innerWidth <= 1024 && "9px",
        fontSize: window.innerWidth <= 1024  && "16px",
    };

    const ellipseStyle = {
        width: window.innerWidth <= 1024 ? "18.25em"  : "30em",
        height: window.innerWidth <= 1024 ? "18.25em"  : "30em",
        marginTop: window.innerWidth <= 1024 ? "-5em"  : "-1em",
        marginLeft: window.innerWidth <= 1024 && "-11em",
        borderRadius: window.innerWidth <= 1024 && "18.25em",
        border: window.innerWidth <= 1024 && "25px solid rgba(235, 37, 144, 0.05)",
    };

    return (
        <div className={classes['banner']}>
            <Ellipse style = {ellipseStyle} />
            <p className={classes['banner__tagline']}>
                Financing Women Owned Businesses Across Africa.
                <span className={classes['banner__small']}>
                         Shecluded is a funding company that provides debt and equity capital to women in business. Through its tailored financial products, training, incubation, and accelerator programs, advocacy efforts, capacity development initiatives, and exclusive focus on gender lens investing. 
                </span>
                <span className={classes['banner__actions']}>
                    <Button text={"Sign Up"} to = {"/register"} styles = {btnStyles} />
                    <NavLink to="/our_story" style={{color: "#000", fontSize: "3px", fontStyle: "bold"}} ><span>Learn More</span></NavLink>
                </span>
            </p>
            <div className={classes['img__container']}>
                <ImageDoorShape src = "/images/banner_img.png" alt = "banner image" style = {imgStyles}/>
            </div>
        </div>
    );
};

export default HomeBanner;