import React from "react";
import CareerValueSection from "../components/new components/JobsComponents/CareerValueSection";
import EmployeeStoriesSection from "../components/new components/JobsComponents/EmployeeStoriesSection";
import JobBannerSection from "../components/new components/JobsComponents/JobBannerSection";
import OpenRolesSection from "../components/new components/JobsComponents/OpenRolesSection";
import QuoteBannerSection from "../components/new components/JobsComponents/QuoteBannerSection";
import StaffReviewBannerSection from "../components/new components/JobsComponents/StaffReviewBannerSection";


const JobsPage = () => {
    return (
        <>
            <JobBannerSection />
            <QuoteBannerSection />
            <StaffReviewBannerSection />
            <EmployeeStoriesSection />
            <CareerValueSection />
            <OpenRolesSection />
        </>
    );
};

export default JobsPage;