import { FaAngleRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import React from 'react';

const SectionTwo = () => {
  return (
    <div className="flex flex-col lg:flex-row  max-w-[89vw] lg:max-w-[75em] mx-auto justify-between gap-5 items-center">
      <div className="flex-[1]">
        <p className="font-playfair font-[500] text-[38px] lg:text-[48px] leading-[50.65px] lg:leading-[63.98px]">
          We{" "}
          <span className="italic font-[700]  text-custom-primary">
            Offer Loans
          </span>{" "}
          that power startups & growth
        </p>
        <p className="font-lato font-[300] text-[18px] lg:text-[22px] leading-[21.6px] lg:leading-[26.4px]">
          Shecluded offers growth loans to help women owned businesses take
          their businesses to the next level. These loans are available to
          businesses that have been in operation for at least 12 months and have
          a stable financial
        </p>
        <div className="flex gap-5">
          <NavLink
            to="/our_story"
            className="hidden lg:flex w-[113px] h-[39px] border-2 rounded-full justify-center !no-underline 
            items-center border-custom-primary text-[12px] font-[500] leadind-[14.4px] text-custom-primary "
          >
                Get a Loan
          </NavLink>

          <NavLink
            to="/our_story"
            className="flex w-[166px] h-[39px] lg:border-2 rounded-full justify-between lg:justify-center !no-underline
            items-center lg:border-custom-primary text-[17px] lg:text-[12px] font-[500] leading-[20.4px] lg:leading-[14.4px] text-custom-primary lg:text-white lg:bg-custom-primary "
          >
                View Requirements <FaAngleRight className="block lg:hidden" />
          </NavLink>
      
        </div>
      </div>
      <div className="flex-[.9]">
        <img
          src="/images/sectiontwo.png"
          alt="sectiontwo"
          className="mx-auto"
        />
      </div>
    </div>
  );
};
export default SectionTwo;
