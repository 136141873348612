import React from "react";
import classes from "./TitleBannerSection.module.css";


const TitleBannerSection = () => {
    return(
        <div className={classes['container']}>
            <p className={classes['title']}>Terms of Use</p>
            <p className={classes['date_updated']}>Updated 24th September 2023</p>
        </div>
    );
};

export default TitleBannerSection;