import React from "react";
import classes from "./FAQSearchSection.module.css";

const FAQSearchSection = () => {
    return (
        <div className={classes['container']}>
            <p className={classes['title']}>Frequently Asked Questions</p>
            <input type="text" placeholder="Search for Questions and Articles here... " className={classes['search']} />
        </div>
    );
};

export default FAQSearchSection;