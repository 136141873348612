import React from "react";
import classes from "./QuoteBannerSection.module.css";

const QuoteBannerSection = () => {
    return (
        <div className={classes['container']}>
            <p className={classes['tagline']}>Work At Shecluded </p>
            <p className={classes['title']}>Join a team, change the <span className={classes['next_line']}>status Quo.</span></p>
            <p className={classes['down_note']}>
                <span className={classes['next_line']}>Learn how you can create  meaningful </span>
                <span className={classes['next_line']}>influence: Explore our focus areas,</span>
                <span className={classes['next_line']}>global presence, and prospects for </span>
                <span className={classes['next_line']}>businesses.</span>
            </p>
        </div>
    );
};

export default QuoteBannerSection;