import React from "react";
import { useState } from "react";
import InPageLink from "../UI/InPageLink";
import classes from "./OpenRolesSection.module.css";

const OpenRolesSection = () => {

    const [counter, setCounter] = useState(0);
    const max = Math.floor(window.innerWidth / 487.152);
    const jobArrLength = window.innerWidth > 1024 && 4;
    

    const leftClickHandler = () => {
        if(counter > 0){
            setCounter(counter - 1);
        }
    };

    const rightClickHandler = () => {
        if(counter < (jobArrLength - max)){
            setCounter(counter + 1);
        }
    };


    const left = window.innerWidth > 1024 && - 487.152 * counter + "px";

    const catStyle = {
        marginLeft: left,
        transition: "margin-left 1s",
    };

    const linkStyle = {
        color: "#EB2590",
    };


    return (
        <div className={classes['container']}>
            <p className={classes['tagline']}>
                OPEN ROLES
            </p>
            <p className={classes['title']}>
                A few ways you can make <span className={classes['next_line']}>impact at Shecluded.</span> 
            </p>

            <div className={classes['job_container']}>
                <div className={classes['job_categories']} style={catStyle}>
                    <div className={classes['category']}>
                        <img src="/images/operations.png" alt="" className={classes['img']} />
                        <p className={classes['link']}><InPageLink styles = {linkStyle} text = {'Operations'} to = {'/hiring/directorofprograms'} /></p>
                    </div>
                    <div className={classes['category']}>
                        <img src="/images/sales.png" alt="" className={classes['img']} />
                        <p className={classes['link']}><InPageLink styles = {linkStyle} text = {'Sales'} to = {'/hiring/headofgrowth'} /></p>
                    </div>
                    <div className={classes['category']}>
                        <img src="/images/engineering.png" alt="" className={classes['img']} />
                        <p className={classes['link']}><InPageLink styles = {linkStyle} text = {'Engineering'} to = {'/'} /></p>
                    </div>
                    {/* <div className={classes['category']}>
                        <img src="/images/engineering.png" alt="" className={classes['img']} />
                        <p className={classes['link']}><InPageLink styles = {linkStyle} text = {'Four'} to = {'/'} /></p>
                    </div>
                    <div className={classes['category']}>
                        <img src="/images/engineering.png" alt="" className={classes['img']} />
                        <p className={classes['link']}><InPageLink styles = {linkStyle} text = {'Five'} to = {'/'} /></p>
                    </div>
                    <div className={classes['category']}>
                        <img src="/images/engineering.png" alt="" className={classes['img']}  />
                        <p className={classes['link']}><InPageLink styles = {linkStyle} text = {'Six'} to = {'/'} /></p>
                    </div> */}
                </div>
            </div>

            <div className={classes['navigators']}>
                <img src="/images/Arrow - Left Circle.png" alt="" className={classes['nav']} onClick={leftClickHandler} />
                <img src="/images/Arrow - Right Circle.png" alt="" className={classes['nav']} onClick={rightClickHandler} />
            </div>
        </div>
    );
};

export default OpenRolesSection;

