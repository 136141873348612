import React, { Component } from 'react';
import classes from "./CookieInfo.module.css";

const CookieInfo = () => {
    return (
        <div className={classes['container']}>
            <p className={classes['p']}>The information presented on this website does not provide a comprehensive overview of the full range of services and offerings provided by Shecluded.</p>
            <p className={classes['p']}>This website utilizes cookies to enhance user experience and adheres to policies regarding cookies and privacy. By using this website, I acknowledge my acceptance 
                of the terms of use as well as the Disclaimer Policy. It's important to note that the information collected will not be utilized for marketing purposes. 
                Trademarks such as Google Play and its logo, along with Apple and iPhone, are the property of their respective owners, Google LLC and Apple Inc., registered in 
                the U.S.</p>
            <p className={classes['copyright']}> &copy; {new Date().getFullYear()} Shecluded Financial Technology. All rights reserved.</p>
        </div>
    );
};


export default CookieInfo;