import React from "react";
import classes from "./DefinitionsSection.module.css";
import TitleComponent from "./TitleComponent";


const DefinitionsSection = () => {
    return (
        <>
            <TitleComponent num = {2} text = {" Definitions "} />
            <div style={{paddingBottom: "3em"}}>
                <p className={classes['body']}>In this agreement, the terms: “You”, “your”, “Customer”, “Borrower”, and “Client” shall mean the 
                person who applied for this Account and agrees to this Agreement, while “We”, “us”, and “our” and 
                “Lender” shall mean Shecluded.</p>
                <ul className={classes['ul']}>
                    <li className={classes['li']}>"Account" means the Customer's account with Shecluded</li>
                    <li className={classes['li']}>"Disbursement Date" means the date Shecluded advanced the loan to the Customer's account</li>
                    <li className={classes['li']}>"Due Payment Date" means the date on which the customer is to pay back</li>
                    <li className={classes['li']}>"Credit Limit" means the maximum credit available to the Borrower on opening an account with Shecluded</li>
                    <li className={classes['li']}>
                        "Loan" means the amount advanced to the Borrower by Shecluded.
                        <ol type="a" className={classes['ol']}>
                            <li className={classes['li']}>
                                You must: 
                                (a) accept and agree to these Terms and Conditions; 
                                (b) register with us on the Shecluded website 
                                (c) be a Nigerian citizen (or a Nigerian legal resident) of at least 18 years of age; 
                                (d) have a bank account with a Nigerian financial institution; and 
                                (e) provide all information as may be requested by us, such as your name, email address, 
                                    mobile device number, online credentials for your bank account, 
                                    and such other information as we may request from time to time 
                                    (collectively, “Customer Information”).
                            </li>
                            <li className={classes['li']}>
                                You represent and warrant that all Customer Information you provide us 
                                from time to time is truthful, accurate, current, and complete. 
                                You agree not to misrepresent your identity or your Customer Information.
                            </li>
                            <li className={classes['li']}>
                                You agree to promptly notify us of changes to your Customer Information by 
                                updating your profile on the Shecluded website and to notify us at least five 
                                business days before any changes to your bank account information, including, 
                                but not limited to, the closure of your bank account for any reason.
                            </li>
                            <li className={classes['li']}>
                                If we approve your registration, you will be authorised to use the Shecluded account, 
                                subject to these terms -
                                <ul className={classes['ul_2']}>
                                    <li>
                                        A copy of your government-issued photo ID, such as a national ID, 
                                        international passport, permanent voter's card or driver's license;
                                    </li>
                                    <li>
                                        A copy of a utility bill, bank statement, affidavit, or another bill, 
                                        dated within three months of our request, with your name and Nigerian street address on it; and
                                    </li>
                                    <li>
                                        Such other information and documentation that we may require from time to time.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                </ul>
                <p className={classes['body']}>
                    We respect and are committed to protecting your privacy. We do not sell or otherwise provide personal 
                    information to other companies for the marketing of their own products or services. We will retain your 
                    personal information for as long as your account is active or as needed to provide you services or as 
                    necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                </p>
                <p className={classes['body']}>
                    We reserve the right to disclose your personal information as required by law 
                    (e.g., to comply with a subpoena, warrant, or court order) and when we believe that 
                    disclosure is necessary to protect our rights, avoid litigation, protect your safety or the safety of others, 
                    investigate fraud, and/or respond to a government request. We may also disclose information about you if we 
                    determine that such disclosure should be made for reasons of national security, law enforcement, or other issues 
                    of public importance.
                </p>
            </div>
        </>
    );
};

export default DefinitionsSection;