import React from "react";
import Button from "../UI/Button";
import classes from "./InsideShecludedSection.module.css";


const InsideShecludedSection = () => {

    const btnStyles = {
        background: "#EB2590",
        color: "#fff",
        display: "block",
        float: window.innerWidth <= 1024 ? "none" : "left",
        overflow: "auto",
        margin: window.innerWidth <= 1024 && "auto",
        marginTop: "2em",
        width: window.innerWidth <= 1024 ? "10em" : "16.011em",
        height: window.innerWidth <= 1024 && "2.5em",
        fontSize: window.innerWidth <= 1024 && "14px",
        borderRadius: window.innerWidth <= 1024 && "9px",
    };


    return (
        <div className={classes['container']}>
            <div className={classes['top']}>
                <p className={classes['title']}>
                    <span className={classes['pink']}>See Inside </span> Shecluded Hub
                </p>
                <p className={classes['note']}>Sneak Peak at Shecluded Workspaces </p>
                <Button text = "Rent a Space" to="https://hub.shecluded.com" out styles = {btnStyles} />
            </div>
        
            <div className={classes['bottom']}>
                <div className={classes['partition']}>
                    <img src="/images/partition_1_0.png" alt="" /> 
                    <img src="/images/partition_1_1.png" alt="" /> 
                </div>

                <div className={classes['partition2']}>
                    <img src="/images/partition_2_0.png" alt="" /> 
                    <img src="/images/partition_2_1.png" alt="" /> 
                </div>

                <div className={classes['partition3']}>
                    <img src="/images/partition_3_0.png" alt="" /> 
                    <img src="/images/partition_3_1.png" alt="" /> 
                </div>

            </div>
        </div>
    );
};

export default InsideShecludedSection;