import React from "react";
import classes from "./programs.module.css";
import { background } from "@chakra-ui/react";

const BussSuppProgram = () => {
    return (
        <div className={classes['banner__container']}>
            <p className={classes['tag']}>Our Programs</p>
            <h1 className={classes['word_two']}>Proven Success
                in <br></br><span>Empowering Women</span></h1>

            <div className={classes['program_row']}>

                <div className={classes['program_col']} style={{ backgroundColor: "#F5F5F5" }} >
                    <h1 className={classes['story-title']}>Incubator</h1>
                    <p className={classes['word_three']}>We incubate operating businesses with a revenue records beyond 7 months . We do our best to enlist each eligible applicant after their applications are sent in. </p>
                    <img src="/images/program_shape_1.png" className={classes['shape']} style={{ paddingTop: "50px" }}></img>
                </div>

                <div className={classes['program_col']} style={{ backgroundColor: "#FDF1FD", marginLeft: "30px", marginRight: "30px" }} >
                    <h1 className={classes['story-title']}>Accelerator</h1>
                    <p className={classes['word_three']}> For Business starters and young operating businesses, we offer accelerator programs annually at Shecluded. </p>
                    <img src="/images/program_shape_2.png" className={classes['shape']} style={{ paddingTop: "75px" }}></img>
                </div>

                <div className={classes['program_col']} style={{ backgroundColor: "#EFF4FB" }} >
                    <h1 className={classes['story-title']}>COVID-19 Impact Relief<br></br> Digital Skills Training</h1>
                    <p className={classes['word_three']}> In 2020, Shecluded held virtual training to equip 1,300 women with digital skills during the pandemic. The program focused on transitioning women into the digital economy. </p>
                    <img src="/images/program_shape_3.png" className={classes['shape']} style={{ paddingTop: "28px" }}></img>
                </div>
            </div>


            <div className={classes['program_row']}>
            <div className={classes['program_col']} style={{ backgroundColor: "#F5F5F5" }} >
                    <h1 className={classes['story-title']}>GirlCode Bootcamp with <br></br>Microsoft ADC (2022)</h1>
                    <p className={classes['word_three']}> We partnered with Microsoft ADC to host the GirlCode Bootcamp, enhancing young women developers' tech skills and earning potential.Over 30 participants joined panel sessions and took part in hands-on workshops. </p>
                    <img src="/images/program_shape_3.png" className={classes['shape']} style={{ paddingTop: "28px" }}></img>
                </div>
            
                
                
                <div className={classes['program_col']} style={{ backgroundColor: "#FDF1FD" , marginLeft: "30px", marginRight: "30px" }} >
                    <h1 className={classes['story-title']}>INSPIRE, CREATE, <br></br> START, SCALE (ICSS)</h1>
                    <p className={classes['word_three']}>A business incubation programme aimed to accelerate the startup, growth, and success of the supported enterprises through an array of business support resources and services. </p>
                    <img src="/images/program_shape_1.png" className={classes['shape']} style={{ paddingTop: "44px" }}></img>
                </div>

                <div className={classes['program_col']} style={{ backgroundColor: "#EFF4FB" }} >
                    <h1 className={classes['story-title']}>TOFA (Traders of Africa)</h1>
                    <p className={classes['word_three']}> Shecluded partnered with TOFA to promote inclusion for women in Africa's Agro-Trading sector. Around 20 women received hands-on training from TOFA Academy,  covering product sourcing, quality checks, payment methods, and delivery processes. </p>
                    <img src="/images/program_shape_2.png" className={classes['shape']} style={{ paddingTop: "24px" }}></img>
                </div>
            
            </div>



        </div>
    );
};

export default BussSuppProgram;