import React from 'react';
import Button from "../UI/Button";
import CardFrame from "../UI/CardFrame";
import classes from "./HomeWhatsNewSection.module.css";

const HomeWhatsNewSection = () => {
    const btnStyles = {
        display: window.innerWidth <= 1024 ? 'block' : 'block', 
        margin: window.innerWidth <= 1024 ? 'auto' : 'auto',
        marginBottom: window.innerWidth <= 1024 ? '0' : '0',
        marginTop: window.innerWidth <= 1024 ? '2.875em' : '2.875em',
        borderRadius: window.innerWidth <= 1024 ? "9px" :'16px',
        border: '2px solid var(--Primary, #EB2590)',
        background: 'var(--Primary, #EB2590)',
        color: '#fff',
        padding: window.innerWidth <= 1024 ? ".6em" : ".9em 1.746em",
        fontSize: window.innerWidth <= 1024 ? "14px" : '21px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        width: window.innerWidth <= 1024 ? '10.25em' : "14.063em",
        height: window.innerWidth <= 1024 ? "2.8em" :"3.375em",
        textAlign: "center",
    };

    const cardFrameStyles = {
        flex: window.innerWidth <= 1024 ? "" : "1",
    };
    return (
        <div className={classes['container']}>
            <p className={classes['title']}>What's New</p>
            <p className={classes['short_note']}>Get to see the latest news directly from shecluded. Visit the blog to see more stories.</p>
            <a href = {"https://blog.shecluded.com/"} style = {btnStyles}>See Full Articles</a>
            <div className={classes['articles_container']}>
                <CardFrame styles={cardFrameStyles}>
                    <div className={classes['top']}>
                        <p className={classes['card_title']}>SHECLUDED</p>
                        <p className={classes['date']}></p>
                        <p className={classes['article_link']} >
                            <a href="https://blog.shecluded.com/shecluded-celebrates-graduation-of-incubator-participants/">Shecluded celebrates graduation of Incubator participants</a>
                        </p>
                    </div>
                    <div className={classes['bottom']}>
                        <img src= "/images/blog_1.jpg" alt="" />
                    </div>
                </CardFrame>
                <CardFrame styles={cardFrameStyles}>
                    <div className={classes['top']}>
                        <p className={classes['card_title']}>SHECLUDED</p>
                        <p className={classes['date']}></p>
                        <p className={classes['article_link']} >
                            <a href="https://blog.shecluded.com/how-love-accelerated-the-growth-of-my-business/">How Love Accelerated The Growth Of My Business</a>
                        </p>
                    </div>
                    <div className={classes['bottom']}>
                        <img src= "/images/blog_2.png" alt="" />
                    </div>
                </CardFrame>
                <CardFrame styles={cardFrameStyles}>
                    <div className={classes['top']}>
                        <p className={classes['card_title']}>SHECLUDED</p>
                        <p className={classes['date']}></p>
                        <p className={classes['article_link']} >
                            <a href="https://blog.shecluded.com/how-to-start-affiliate-marketing-affiliate-marketing-for-beginners/">How To Start Affiliate Marketing (Affiliate Marketing For Beginners)</a>
                        </p>
                    </div>
                    <div className={classes['bottom']}>
                        <img src= "/images/blog_3.jpg" alt="" />
                    </div>
                </CardFrame>
            </div>
        </div>
    );
};

export default HomeWhatsNewSection;