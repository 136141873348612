import React from "react";
import BlogServicesUpdatesSection from "../components/new components/EventsComponents/BlogServicesUpdatesSection";
import CallToActionSection from "../components/new components/EventsComponents/CallToActionSection";
import NewsSection from "../components/new components/EventsComponents/NewsSection";
import UpcomingEventsSection from "../components/new components/EventsComponents/UpcomingEventsSection";

const EventsPage = () => {
    return (
        <>
            <NewsSection />
            <BlogServicesUpdatesSection />
            <UpcomingEventsSection />
            <CallToActionSection />
        </>
    );
};

export default EventsPage;