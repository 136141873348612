
import React from 'react';
import { FaAngleRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import TestimonialComponent from "./TestimonialComponent";
import TestimonialComponentMobile from "./TestimonialComponentMobile";


const SectionNine = () => {

    return (
        <div className="w-full bg-[#FFF3FB] h-[1024.96px] flex justify-center items-center">
            <div className="flex flex-col max-w-[90vw] lg:max-w-[75em] ">
                <p className="font-playfair font-[400] text-[36px] lg:text-[50px] leading-[47.99px] lg:leading-[66.65px] lg:text-center">The Shecluded <span className="font-[700] italic text-[#EB2590]">Community</span></p>
                <p className="font-lato font-[500] text-[17px] lg:text-[31px] leading-[20.4px] lg:leading-[37.2px] lg:text-center ">A platform for women to interact, find their next customers or business partner, gain access to opportunities and scale faster</p>
                <NavLink
                    to="/our_story"
                    className="lg:mx-auto flex w-[143px] lg:w-[166px] h-[37px] lg:h-[39px] lg:border-2 rounded-[5px] lg:rounded-full gap-3 lg:gap-0 justify-center !no-underline lg:mt-20
                                items-center lg:border-custom-primary px-[30px] py-[7px] lg:px-0 lg:py-0 text-[12px] lg:text-[14.4px] font-[500] leading-[20.4px] lg:leading-[14.4px] text-custom-primary text-white bg-custom-primary "
                    >
                    Click to Join
                </NavLink>

                <div className="relative lg:static">
                    <TestimonialComponent />
                    <TestimonialComponentMobile />
                </div>
            </div>
        </div>
    );

};
export default SectionNine;