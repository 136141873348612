import React from 'react';


const SectionFour = () => {
    return (
        <div className="w-auto bg-[#F6F6F6] pb-[100px]">
            <div className="overflow-x-auto lg:overflow-x-hidden overflow-y-hidden  w-[94vw] lg:w-auto flex justify-between max-w-[75em] mx-auto px-2 lg:px-10 pt-20 custom-scrollbar gap-5 lg:gap-0 ">
                <div className="w-[228.4px] lg:w-[250.1px] h-[543.1px] lg:h-[594.71px] bg-custom-primary flex flex-col relative">
                    <div className="flex flex-col p-4 flex-[1] justify-start  items-center pt-5">
                        <p className="text-[55px] text-white font-[800] font-lato leading-[66px]">130+</p>
                        <p className="text-[24px] text-white font-[500] font-lato leading-[28.8px] text-center">Women Empowered</p>
                    </div>
                    <div className="flex-[.5] ">
                        <img src="/images/sectionfour_1.png" alt="" className="absolute min-w-[245.76px] lg:min-w-[367.67px] mt-[-55.5px] ml-[-35px] lg:mt-[-148px] lg:ml-[-55px] z-[1.999999999]" />
                    </div>
                </div>
                
                <div className="w-[228.4px] lg:w-[250.1px] h-[543.1px] lg:h-[594.71px] bg-custom-secondary1 flex flex-col relative">
                    <div className="flex flex-col p-4 flex-[1] justify-start  items-center pt-5">
                        <p className="text-[55px] text-white font-[800] font-lato leading-[66px]">10K</p>
                        <p className="text-[24px] text-white font-[500] font-lato leading-[28.8px] text-center">Businesses supported since 2019</p>
                    </div>
                    <div className="flex-[.5] ">
                        <img src="/images/sectionfour_2.png" alt="" className="absolute min-w-[215.76px] lg:min-w-[335.76px] mt-[-39px] ml-[-31px] lg:mt-[-120px] lg:ml-[-41px] z-[1.999999999]" />
                    </div>
                </div>
                
                <div className="w-[228.4px] lg:w-[250.1px] h-[543.1px] lg:h-[594.71px] bg-custom-secondary2 flex flex-col relative">
                    <div className="flex flex-col p-4 flex-[1] justify-start  items-center pt-5">
                        <p className="text-[55px] text-white font-[800] font-lato leading-[66px]">60%</p>
                        <p className="text-[24px] text-white font-[500] font-lato leading-[28.8px] text-center">Increase in revenue after Shecluded Incubation</p>
                    </div>
                    <div className="flex-[.5] ">
                        <img src="/images/sectionfour_3.png" alt="" className="absolute min-w-[245.76px] lg:min-w-[404.87px] mt-[-69.5px] ml-[-49px] lg:mt-[-200px] lg:ml-[-79px] z-[1.999999999]" />
                    </div>
                </div>
                
                <div className="w-[228.4px] lg:w-[250.1px] h-[543.1px] lg:h-[594.71px] bg-custom-secondary3 flex flex-col relative px-3 ">
                    <div className="flex flex-col p-4 flex-[1] justify-start  items-center pt-5">
                        <p className="text-[55px] text-white font-[800] font-lato leading-[66px]">5</p>
                        <p className="text-[24px] text-white font-[500] font-lato leading-[28.8px] text-center">SDGs tackled so far</p>
                    </div>
                    <div className="flex-[.5] ">
                        <img src="/images/sectionfour_4.png" alt="" className="absolute min-w-[260.76px] lg:min-w-[386.58px]  mt-[-90px] ml-[-72px] lg:mt-[-180px] lg:ml-[-92px] z-[1.999999999]" />
                    </div>
                </div>
                
            </div>
        </div>
    );
};
export default SectionFour;