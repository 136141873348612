import React from "react";
import Button from "../UI/Button";
import classes from "./LoanApplicationBannerSection.module.css";


const LoanApplicationBannerSection = () => {

    const btnStyles = {
        background: "#000",
        color: "#fff",
        marginTop: "2.151em",
        border: "solid 1px #000"
    };

    return (
        <div className={classes['container']}>
            <div className={classes['text_container']}>
                <p className={classes['tagline']}>GROWTH LOANS</p>
                <p className={classes['title']}>
                    <span className={classes['pink']}>welcome </span> to Growth 
                    <span className={classes['next_line']}>Loan Applications</span>
                </p>
                <p className={classes['text_body']}>Shecluded Growth Loan applications are really easy. Simly create an Account/Sign Up, Apply for your preferred amount and wait for  your loan.</p>
                <Button text = "Get Started" to = "/" styles = {btnStyles} />
            </div>
            <div className={classes['img_container']}>
                <img src="/images/loanapp_banner.png" alt="" className={classes['img']} />
            </div>
        </div>
    );
};

export default LoanApplicationBannerSection;

