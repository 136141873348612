import React, {  useState } from "react";
import classes from "./EchoesSection.module.css";
import { background } from "@chakra-ui/react";

const EchoesSection = () => {



 
    const [counter, setCounter] = useState(0);

   


    const testimonialLeft =  window.innerWidth <= 1024 ? (-22 * counter) + "em" : (-35.063 * counter) + "em";
    





    return (
        <div className={classes['container']}>
            <div className={classes['testimonial__container']}>
                <div className={classes['left_pane']}>
                    <p className= {classes['tagline']}>SHECLUDED HUB BUSINESSES </p>
                    <p className= {classes['title']}>
                        <span className= {classes['pink']}>Echoes</span> from a
                        <span className= {classes['next_line']}> mile away</span>
                    </p>
                    <div style={{display: "flex", maxWidth: window.innerWidth <= 1024 ? "22em" : "35.063em",
            
                     marginLeft: testimonialLeft, scrollBehavior:"smooth", transition: "margin-left .5s ease-in"}}>
                        <div className={classes['left__pic']}> 
                            <div className={classes['testimony__container']}>
                                <div className={classes['heading']}>   
                                    <p className={classes['testifier']}>Doris Omoyeni</p>
                                    <p className={classes['testifier__position']}>Diamond Plug phones & gadgets</p>
                                </div>
                                <p className={classes['testimony']}>I love Shecluded; they're one of the best for loans. God bless Shecluded, may they continue to grow and achieve even greater success.I love Shecluded; they're one of the best for loans. God bless Shecluded, may they continue to grow and achieve even greater success.</p>
                            </div>
                        </div>
                        <div className={classes['left__pic']} style={{background: "url('/images/Onome Aluko.jpg')", paddingTop: "20em", backgroundSize: window.innerWidth <= 1024 && "cover"}}> 
                            <div className={classes['testimony__container']}>
                                <div className={classes['heading']}>   
                                    <p className={classes['testifier']}>Onome Aluko</p>
                                    <p className={classes['testifier__position']}>PYOOR</p>
                                </div>
                                <p className={classes['testimony']}>Shecluded has been a big help to my business. They've given me loans many times, and it's easy - no stress, no need for collateral, and the interest rate is fantastic. No bank or financial institution can match that. My business not only survived all seasons but is still growing, thanks to Shecluded. Also, with the free business growth tips from Pyoor, my business is doing great</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes['right_pane']}> 
                    <div style={{display: "flex", flexDirection:"row-reverse", marginLeft: "auto", maxWidth: "35.063em", 
                    
                    marginRight: testimonialLeft, scrollBehavior:"smooth", transition: "margin-right .5s ease-in"}}>
                        <div className={classes['right__pic']}> 
                            <div className={classes['testimony__container']}>
                                <div className={classes['heading']}> 
                                    <p className={classes['testifier__position']}>Baker's Treats</p>
                                    <p className={classes['testifier']}>Pelumi Ola</p>
                                </div>
                                <p className={classes['testimony']}>Shecluded gave me a loan to grow my business. Now I can take on more without worry. I can access bigger and better opportunities because Shecluded is always there for me.</p>
                            </div>
                        </div>
                        <div className={classes['right__pic']} style={{background: "url('/images/Pelumi Ola.jpg')", backgroundSize: "cover"}}> 
                            <div className={classes['testimony__container']}>
                                <div className={classes['heading']}> 
                                    <p className={classes['testifier__position']}>Baker's Treats</p>
                                    <p className={classes['testifier']}>Pelumi Ola</p>
                                </div>
                                <p className={classes['testimony']}>Shecluded gave me a loan to grow my business. Now I can take on more without worry. I can access bigger and better opportunities because Shecluded is always there for me.</p>
                            </div>
                        </div>
                    </div>
                    <p>These stories are right from their hearts. Apply to be part of it.</p>
                    <p className={classes['link']}><a href="/" >Apply </a></p>
                </div>
            </div>
            <div className={classes['navigators']}>
                <img onClick={() => {(counter > 0) && setCounter(counter - 1)}} src="/images/Arrow - Left Circle.png" alt="" className={classes['nav']}  />
                <span className={classes['more']}>MORE</span> 
                <img onClick={() => {(counter < 1) && setCounter(counter + 1)}} src="/images/Arrow - Right Circle.png" alt="" className={classes['nav']} />
            </div>
        </div>
    );
}

export default EchoesSection;