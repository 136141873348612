import React from 'react';
import classes from "./HomePartnersIcons.module.css";

const HomePartnersIcons = () => {
    return (
        <div id= "partners" className={classes['partners__container']} >
            <p>Notable Partners</p>
            <div className={classes['img__container']}>
                <div className={classes['img']}><a href='https://www.halcyonhouse.org/halcyon-incubator/fellows/ifeoma-uddoh/'><img src="/images/halcyon_2018.png" alt="icon" /></a></div>
                <div className={classes['img']}><a href='https://ng.usembassy.gov/fintech-start-up-emerges-winner-of-selectusa-tech-pitch-competition/'><img src="/images/selectusa_stacked_full-color_med-790x359.png" alt="icon"  /></a></div>
                <div className={classes['img']}><a href='https://thecenter.nasdaq.org/milestone-makers-spring-2021/'><img src="/images/Nasdaq-Logo.png" alt="icon" /></a></div>
                <div className={classes['img']}><a href=''><img src="/images/InclusiveFintech50_stacked-logo.png" alt="icon" /></a></div>
                <div className={classes['img']}><a href='https://www.campus.co/africa/black-founders-fund/'><img src="/images/logo-googleforstartups.png" alt="icon" /></a></div>
            </div>
        </div>
    );
}

export default HomePartnersIcons;