import React from "react";
import classes from "./../Header/NavBar.module.css";
import { NavLink } from "react-router-dom";

const HeaderLink = (props) => {

    return (
        <ul className={classes['header-nav__ul']}>
            {props.navLinks.map((navLink) => <li className={navLink.liClass} key={navLink.key} >
                {navLink.subMenu.length === 0 && navLink.inpage ? <NavLink to={navLink.to} onClick={ props.onClick }>{navLink.menuText}</NavLink> : navLink.subMenu.length === 0 && <a href="https://blog.shecluded.com" target="_blank">{navLink.menuText}</a>}
                {navLink.subMenu.length > 0 && <> <a href="/" onClick={(event) => {event.preventDefault(); props.setOpenNav((prevProp) => prevProp === navLink.key ? 0 : navLink.key);}}>{navLink.menuText}</a>
                    <ul className={navLink.subMenuClass}>
                        {navLink.subMenu.map ((subNav) => <li key={subNav.key}>
                           {subNav.subMenuTo ? <NavLink to={subNav.subMenuTo} onClick={ props.onClick }>
                                {subNav.subMenuTitle}
                                <p>{subNav.subMenuDetails}</p>
                            </NavLink> :  <a href={subNav.to}>
                            {subNav.subMenuTitle}
                            <p>{subNav.subMenuDetails}</p>
                        </a>}
                        </li>)}
                    </ul>
                </>}
            </li>)}
        </ul>
    );
};

export default HeaderLink;