import React, { Component } from 'react';

import FooterLink from "../UI/FooterLink";
import classes from "./FooterNav.module.css";


const CareersNav = (props) => {

    const links = [
        {
            key: 1,
            to: '/jobs',
            text: 'Jobs'
        },
        {
            key: 2,
            to: '/#partners',
            text: 'Partners'
        },
        {
            key: 3,
            to: '/about',
            text: 'Our Story'
        },
        // {
        //     key: 4,
        //     to: '/our_story#founder',
        //     text: 'Leadership'
        // },
        // {
        //     key: 5,
        //     to: '/',
        //     text: 'Directory'
        // },
        // {
        //     key: 6,
        //     to: '/events',
        //     text: 'Events'
        // },
    ];
    



    const careerUl = (props.navAccordionState) ? classes['footer-nav__ul'] + " " + classes['display-block'] : classes['footer-nav__ul'];
    const careerTitle = classes['footer-nav__title'];
    return (
        <div className={classes['footer-nav__container']}>
            <p className={careerTitle} onClick={props.onClick}>Careers</p>        
            <FooterLink links = {links} categoryUl = {careerUl} />
        </div>
    );

}


export default CareersNav;