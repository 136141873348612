import React from "react";
import ValuesSection from "../StoryLeadershipComponents/ValuesSection";


const CareerValueSection = () => {
    return (
        <ValuesSection container_style = {{marginTop: "-10em"}} title_style = {{textAlign: "center"}} side_note_style = {{display: "none"}} />
    );
};

export default CareerValueSection;