import React, { useState } from "react";
import classes from "./AcceleratorIncubatorSection.module.css";
import { NavLink } from "react-router-dom";



const AcceleratorIncubatorSection = () => {

    const [activeTab, setActiveTab] = useState(0);


    return (
        <>
            <div className={classes['container']}>
                <div className={classes['text_container']}>
                    <p className={classes['title']}>
                        <span className={classes['next_line']}>Women <span className={classes['partner']}>partner</span> on </span> 
                        <span className={classes['next_line']}>audacious Ideas whilst </span> 
                        <span className={classes['next_line']}> spending time building</span>
                        <span className={classes['next_line']}> their own products.</span>
                    </p>
                    <p className={classes['note']}>The hub also offers options for hosting <span className={classes['next_line']}>events and renting workspaces.</span> </p>
                </div>
                <div className={classes['img_container']}>
                        <img src= "/images/incubator.png" alt="" className={classes['img']} />
                </div>
            </div>
            <div className={classes['tab']}>
                <div onClick={() => { setActiveTab(0) }} className={ activeTab === 0 ? classes['tab1'] + " " + classes['active']: classes['tab1']}>
                    Accelerator
                </div>
                <div onClick={() => { setActiveTab(1) }} className={ activeTab === 1 ? classes['tab2'] +  " " + classes['active']: classes['tab2']}>
                    Incubator
                </div>
            </div>
            <div className={classes['signup_container']} style={{marginLeft: activeTab === 0 ? (window.innerWidth <= 1024) && "1em" : (window.innerWidth <= 1024) && "-100vw" }}>
                <div className={classes['accelerator']} >
                    <p className={classes['signup_logo']}></p>
                    <p className={classes['signup_title']}>Accelerator</p>
                    <p className={classes['text_body']}>
                        Amongst other services, Shecluded Hub hosts  accelerator programs for experienced and young upcoming female entrepreneurs. We strive to guide visionary women entrepreneurs by seasoned mentorship, leveraging expertise that propel their business ventures to new heights.
                        <span className={classes['link']}><NavLink to="/register">Sign Up for Shecluded Accelerator</NavLink></span>
                    </p>

                </div>
                <div className={classes['incubator']}>
                    <p className={classes['signup_logo']}></p>
                    <p className={classes['signup_title']}>Incubator</p>
                    <p className={classes['text_body']}>
                        Like our accelerator programs, we cushion successful early starters, providing 
                        them a seamless and supportive experience to our clients, from an expansive curriculum, 
                        to program development to graduation and ongoing alumni engagement.
                        <span className={classes['link']}><NavLink to="/register">Sign Up for Shecluded Incubator</NavLink></span>
                    </p>
                </div>

            </div>
        </>
    );
};

export default AcceleratorIncubatorSection;