import React from "react";
import TitleBannerSection from "../components/new components/TermsOfUseComponents/TitleBannerSection";
import EventOfDefaultSection from "../components/new components/TermsOfUseComponents/EventOfDefaultSection";
import IntroductionSection from "../components/new components/TermsOfUseComponents/IntroductionSection";
import DefinitionsSection from "../components/new components/TermsOfUseComponents/DefinitionsSection";
import UserOfShecludedSection from "../components/new components/TermsOfUseComponents/UserOfShecludedSection";
import ClientsConsentSection from "../components/new components/TermsOfUseComponents/ClientsConsentSection";
import PrivacyAndSecuritySection from "../components/new components/TermsOfUseComponents/PrivacyAndSecuritySection";
import GeneralSection from "../components/new components/TermsOfUseComponents/GeneralSection";
import IndemnificationSection from "../components/new components/TermsOfUseComponents/IndemnificationSetion";
import FailureToComplySection from "../components/new components/TermsOfUseComponents/FailureToComplySection";
import ContactUsSection from "../components/new components/TermsOfUseComponents/ContactUsSection";


const TermsOfUsePage = () => {
    return (
        <>
            <TitleBannerSection />
            <IntroductionSection /> 
            <DefinitionsSection />
            <UserOfShecludedSection />
            <ClientsConsentSection />
            <EventOfDefaultSection />
            <PrivacyAndSecuritySection />
            <GeneralSection />
            <IndemnificationSection />
            <FailureToComplySection />
            <ContactUsSection />
        </>
    );
};

export default TermsOfUsePage;