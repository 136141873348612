import React from "react";
import Button from "../UI/Button";
import classes from "./JoinSection.module.css";


const JoinSection = () => {
    return (
        <>
            <p className={classes['title']}>Join the Shecluded <span className={classes['next_line']}>Workspace today</span> </p>
            <Button text= "Register Business"  to = "https://hub.shecluded.com" out styles = {{display:"block", margin:"auto", marginTop: "3em", 
                        color: "#fff", background: "#EB2590", borderRadius: window.innerWidth <= 1024 && "9px",
                        width: window.innerWidth <= 1024 ? "12.599em" : "16.28em", height: window.innerWidth <= 1024 ? "2.559em" :"3.375em"}} />
        </>
    );
};
export default JoinSection;