import React from "react";
import classes from "./RoleTitleSection.module.css";


const RoleTitleSection = (props) => {
    return (
        <div className={classes['container']}>
            <p className={classes['tagline']}>OPEN ROLES</p>
            <p className={classes['title']}>{props.title}</p>
        </div>
    );
};

export default RoleTitleSection;