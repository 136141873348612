import React from "react";
import classes from "./IntroductionSection.module.css";
import TitleComponent from "./TitleComponent";

const IntroductionSection = () => {
    return (
       <>
             <TitleComponent num = {1} text = {" Introduction"} />
             <p className={classes['body']}>
                Shecluded is a credit inclusion company, and by accepting these terms and conditions, 
                you agree that you have read these Terms and Conditions. You authorise Shecluded to review your credit report and 
                use your data as governed by the Laws of the Federal Republic of Nigeria. By accepting these Terms and Conditions, 
                you have agreed to sign up for our Shecluded account, wherein all loan applications will be reviewed and approved. 
                You may also use your Shecluded account to access other services we provide.You will be asked to provide information 
                (such as your date of birth, a means of identification and your Bank Verification Number) when you apply for a loan, 
                as well as your current residential address and any other requirement as may be prescribed by the Central Bank of Nigeria
                from time to time when you open a Shecluded account. This information shall be used for verification purposes.
                To ensure that we can communicate electronically, you must notify us of any change in your email address, 
                mobile device number, or other text message address by updating your profile on the Shecluded website.
              </p>
       </>
    );
};

export default IntroductionSection;