import React from "react";
import { NavLink } from "react-router-dom";


const InPageLink = (props) => {
    const clickHandler = () => {
        window.scrollTo(0, 0);
    };


    return (
        <NavLink to= {props.to} style={props.styles}  onClick={clickHandler}>{props.text}</NavLink>
    );
};

export default InPageLink;