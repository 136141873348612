import React from "react";
import classes from "./LoanApplicationForm.module.css";


const LoanApplicationForm = () => {
    return (
        <div className={classes['container']}>
            <div className={classes['sub_container']}>
                <div className={classes['navigator']}>
                    <div className={classes['step'] + " " + classes['active_step']}> <span className={classes['num']}>1</span> Sign Up </div>
                    <img src="/images/Line 6.png" className={classes['img']} alt="" />
                    <div className={classes['step']}><span className={classes['num']}>2</span> Apply</div>
                    <img src="/images/Line 6.png" alt="" className={classes['img']} />
                    <div className={classes['step']}><span className={classes['num']}>3</span> Receive Loan</div>
                </div>
                <p className={classes['title']}>Create Account</p>
                <p className={classes['note']}>Tell Us a bit about you and your business.</p>
                <form className={classes['form']}>
                    <div className={classes['row']}>
                        <div className={classes['form_group']}>
                            <label className={classes['label']} htmlFor="fullname">Your Full Name</label>
                            <input type="text" name="fullname" id="fullname" autoComplete="name" placeholder="e.g Theresa Sarah " />
                        </div>
                        <div className={classes['form_group']}>
                            <label className={classes['label']} htmlFor="email">Email Address</label>
                            <input type="email" name="email" id="email" autoComplete="email" placeholder="Email" />
                        </div>
                    </div>
                    <div className={classes['row']}>
                        <div className={classes['form_group_single']}>
                                <label className={classes['label']} htmlFor="business_name">Business Name</label>
                                <input type="text" name="business_name" id="business_name" placeholder="e.g Alabi Ventures LTD" autoComplete="business" />
                            
                        </div>
                    </div>
                    <div className={classes['row']}>
                        <div className={classes['form_group_single']}>
                            <label htmlFor="resume" className={classes['label']}>CAC Certificate 
                                <input type="file" id="resume" name="resume" placeholder="Business Certificate  (Perrable Format PDF ,Word doc)" />
                                <div className={classes['file']}>
                                    <p>Business Certificate  (Perrable Format PDF ,Word doc)</p>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={classes['row']}>
                        <div className={classes['form_group']}>
                            <label className={classes['label']} htmlFor="telephone">Telephone</label>
                            <input type="text" name="telephone" id="telephone" placeholder="Telephone" autoComplete="phone" />
                        </div>
                        <div className={classes['form_group']}>
                            <label className={classes['label']} htmlFor="institution">Type of Space</label>
                            <select id="institution" className={classes['select']} name="institution">
                                <option>Covenant University</option>
                            </select>
                        </div>
                    </div>

                    <input type="submit" name="sub" value="NEXT" className={classes['submit']} />
                    <div className={classes['step_nav']}>
                        <p className={classes['step_note']}>You've Completed 1 of 3</p>
                        <div className={classes['indicator_container']}>
                            <div className={classes['indicator'] + " " + classes['active']}></div>
                            <div className={classes['indicator']}></div>
                            <div className={classes['indicator']}></div>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    );
};

export default LoanApplicationForm;