import React, { Component } from 'react';
import CookieInfo from "./CookieInfo";
import classes from "./Footer.module.css";
import FooterNav from "./FooterNav";
import MiddlePane from "./MiddlePane/MiddlePane";
import { useLocation } from 'react-router-dom';


const Footer = () => {

    const location = useLocation();

    return(
        ["/register", "/login"].indexOf(location.pathname) === -1 &&  <>
            <div className={classes.footer}>
                <div className={classes.img_container}>
                    <img src="/images/Shecluded Logo.svg" className={classes.logo} alt="logo" />
                </div>
                <div className={classes.footer_nav}>
                    <FooterNav />
                </div>
            </div>
            <MiddlePane />
            <CookieInfo />
            <a href='https://wa.me/2347048000892' className='shadow-lg z-[200000]' ><img src="/images/whatsappicon.png" alt='' className='z-[200000] fixed bottom-10 right-5 h-[72px] shadow-[rgba(0,0,0, .2)]  '/></a>
        </>
    );
};

export default Footer;