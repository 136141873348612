import React from "react";
import Button from "../UI/Button";
import classes from "./CollaborationSection.module.css";


const CollaborationSection = () => {
    const btnStyles = {
        display: window.innerWidth <= 1024 ? 'block' : 'inline-block', 
        width: window.innerWidth <= 1024 && '9em',
        height: window.innerWidth <= 1024 && '3em',
        marginBottom: window.innerWidth <= 1024 ? '0' : '0',
        marginTop: window.innerWidth <= 1024 ? '0' : '0',
        border:  window.innerWidth <= 1024 && '9px solid var(--Primary, #EB2590)',
        backgroundColor: 'var(--Primary, #EB2590)',
        color: '#fff',
    };
    return (
        <div className={classes['container']}>
            <div className={classes['img-container']}>
                <p className={classes['title']}>
                    We embrace the Challenges that 
                    comes with <span className={classes['learning']}>Learning</span>, Growth & 
                    collaboration.
                </p>
                <Button text={"See Cohort"} to = {"/shecluded_hub#cohort"} styles = {btnStyles} />
                <p className={classes['cohort']}>Cohort Alumni</p>
            </div>
        </div>
    );
};

export default CollaborationSection;