import React from "react";
import classes from "./ClientsConsentSection.module.css";
import TitleComponent from "./TitleComponent";


const ClientsConsentSection = () => {
    return (
        <>
            <TitleComponent num = {4} text = {" Client's Consent"} />
            <p className={classes['body']}>
                By ticking the “I agree to the Terms and Conditions” on the application form, which you hereby adopt as your 
                electronic signature, you consent and agree that:
            </p>
            <ol className={classes['ol']}>
                <li className={classes['li']}>
                    We can electronically provide you with materials and other information about your legal rights and duties.
                </li>
                <li className={classes['li']}>
                    We can check your credit history with the credit bureau/send your information to them as such.
                </li>
                <li className={classes['li']}>
                    You hereby expressly consent and authorise Shecluded to debit your account and authorise us or any of our duly authorised agents 
                    or partner to debit your designated bank account, or any other bank account linked to your Bank Verification Number (BVN), 
                    for repaying the Loan with applicable interest as and when due.
                </li>
                <li className={classes['li']}>
                    We can send you all important communications, billing statements and demand notes and reminders 
                    (collectively called “Disclosures”) to you electronically via our website or to an email address you provide 
                    to us while applying for this loan.
                </li>
                <li className={classes['li']}>
                    We will make the Disclosures available to you from the date it first becomes available or the date we send you the email to alert you that it was available.
                </li>
                <li className={classes['li']}>
                    We will make the Disclosures available to you from the date it first becomes available or the date we send you the email to alert you that it was available.
                </li>
                <li className={classes['li']}>
                    By consenting, you agree that electronic Disclosures have the same meaning and effect as if we provided paper Disclosures to you as we are required to do so. When we send you an email alerting you that the Disclosures are available electronically and make it available online, that shall have the same meaning and effect as if we provided paper Disclosures to you, whether or not you choose to view the Disclosures, unless you had previously withdrawn your consent to receive Disclosures electronically.                
                </li>
                <li className={classes['li']}>
                    You grant us permission to use your picture or information for promotional use (subject to review upon request by You).
                </li>
            </ol>
        </>
    );
};

export default ClientsConsentSection;