import React from "react";
import Accordion from "../UI/Accordion/Accordion";


const LoansFAQ = () => {
    const titleStyle = {
        color: '#000',
        fontFamily: "'Lato', sans-serif",
        fontSize: window.innerWidth <= 1024 ? '13px' : '24px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
    
    }
    const bodyStyle = {
        color: '#000',
        fontFamily: "'Lato', sans-serif",
        fontSize: window.innerWidth <= 1024 ? '12px' : '19px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '33px', /* 30.94px */
    }

    const accordionData = [
        {
            'key': 1, 
            'title' : 'What is Shecluded ?', 
            'body' : 'Launched in 2019, Shecluded is a funding company that provides debt and equity capital to women in business. Through its tailored financial products, training, incubation, and accelerator programs, advocacy efforts, capacity development initiatives, and exclusive focus on gender lens investing, Shecluded is a pioneering organization in Africa’s push to close the financing gap for women, with over $1 million in disbursed loans to more than 3,000 women and 400 women-owned businesses.'
        },
        {
            'key': 2, 
            'title' : 'What types of loans do you offer ?', 
            'body' : 'We offer a variety of loans, including business loans, personal loans, laptop loans etc'
        },
        {
            'key': 3, 
            'title' : 'What are the eligibility criteria for obtaining a loan ?', 
            'body' : 'Eligibility criteria vary depending on the type of loan, but common factors include credit score, income, employment status, and credit history.'
        },
        {
            'key': 4, 
            'title' : 'How do I apply for a loan ?', 
            'body' : 'You can apply for a loan online through our website by filling out a secure application form. You can also visit one of our physical branches for assistance.'
        },
        {
            'key': 5, 
            'title' : 'What documents do I need to provide when applying for a loan ?', 
            'body' : 'The required documents may vary, but typically include proof of identity, proof of income, bank statements, guarantor information etc'
        },

    ];

    const titStyle = {
        color: '#000',
        // textAlign: 'right',
        fontFamily: "'Lato', sans-serif",
        fontSize: '60px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '119%', /* 91.63px */
        textTransform: 'capitalize',
        textAlign:  window.innerWidth <= 1024 && 'center',
    }
    return (

        <div style={{maxWidth: "76em", margin: "8em auto 5em"}}>
            <p style={titStyle}>fAQ</p>
            <Accordion titleStyle = {titleStyle} bodyStyle = {bodyStyle} accordionData = {accordionData} plusMinus />
        </div>
    );
};

export default LoansFAQ;