import React from "react";
import BussSupportHeader from "../components/new components/BussSupportComponents/header";
import BussSupportVision from "../components/new components/BussSupportComponents/vision";
import ImageBanner from "../components/new components/BussSupportComponents/banner";
import WhatWeDO from "../components/new components/BussSupportComponents/what_we_do";
import BussSuppProgram from "../components/new components/BussSupportComponents/programs";
import SuppPageWhatsNewSection from "../components/new components/BussSupportComponents/whatsnew";
import BussSuppTestimonial from "../components/new components/BussSupportComponents/testimonal";
import BussSuppActionBtn from "../components/new components/BussSupportComponents/call_to_action";

const BussinessSupport = () => {
   return(
    <>
      <BussSupportHeader/ >
      <BussSupportVision />
      <ImageBanner />
      <WhatWeDO />
      <BussSuppProgram />
      <SuppPageWhatsNewSection />
      <BussSuppTestimonial />
      <BussSuppActionBtn />
    </>
   )
}

export default BussinessSupport;