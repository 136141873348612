import React from "react";
import Button from "../UI/Button";
import classes from "./LoansBannerSection.module.css";



const LoansBannerSection = () => {

    const btnStyles = {
        borderRadius: '12px',
        border: '1px solid var(--Primary, #EB2590)',
        background: '#EB2590',
        color: '#fff',
        fontFamily: "'Lato', sans-serif",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        padding: window.innerWidth <= 1024 ? ".4em 0 .6em" : "1.063em 2.688em 1.363em",
        height: window.innerWidth <= 1024 && "2.3em",
        width: window.innerWidth <= 1024 && "8.438em",
        display: window.innerWidth <= 1024 && 'block', 
        // margin: window.innerWidth <= 1024 && 'auto',
        marginTop: "2.75em",
        borderRadius: window.innerWidth <= 1024 && "9px",
    };

    return (
        <div className={classes['container']}>
            <div className={classes['sub__container']}>
                <div className={classes['partition_1']}>
                    <p className={classes['tagline']}><span className={classes['dynamic__one']}>Your Business Loans to get you to the </span> next 
                        <span className={classes['dynamic__two']}> Level</span> </p>
                    <p className={classes['small']}>
                        We have disbursed loans to over 3,000 women and 400 women-owned businesses since 2019. 
                    </p>
                    <Button text = {"Apply Now"} to={"/loanapplication"} styles={btnStyles} />
                </div>
                <div className={classes['partition_2']}>
                    <img  className={classes['img']} src="/images/loans_banner.png" alt="" />
                </div>
            </div>
        </div>
    );
};

export default LoansBannerSection;