import React from 'react';
import { useEffect, useState } from "react";
import classes from "./HomeStatisticsSection.module.css";
import { useSwipeable } from 'react-swipeable';
import { faTimesRectangle } from "@fortawesome/free-regular-svg-icons";

const HomeStatisticsSection = () => {

    useEffect(()=> {
        let timer = setInterval(automate, 200);

        return () => {
            clearInterval(timer);
        };

    });
   
    const [currentIndex, setCurrentIndex] = useState(0);

    const automate = () => {
        setCurrentIndex(currentIndex - 7 >= -(880 - window.innerWidth) ? currentIndex - 7 : 0);
    };

    const swipingHandler = (e) => {
        
        if(e.deltaX > 0) {
            setCurrentIndex(currentIndex < 0 ? currentIndex + 7 : currentIndex);
        } else {
            setCurrentIndex(currentIndex - 7 >= -(880 - window.innerWidth) ? currentIndex - 7 : currentIndex);
            
        }
    };


   const handlers = useSwipeable({
    onSwiping: (e) => {window.innerWidth <= 875 && swipingHandler(e)},
    trackMouse: true,
   });
    

    return (
        <>
            <div {...handlers} className={classes['container']} style={{marginLeft: currentIndex}}>
                <div className={classes['partition1']}>
                    <p className={classes['number']}>30K+</p>
                    <p className={classes['text']}>Women Empowered</p>
                </div>
                <div className={classes['partition2']}>
                    <p className={classes['number']}>10K+</p>
                    <p className={classes['text']}>Businesses supported since 2019</p>
                </div>
                <div className={classes['partition3']}>
                    <p className={classes['number']}>60%</p>
                    <p className={classes['text']}>Increase in Revenue after Shecluded Incubation</p>
                </div>
                <div className={classes['partition4']}>
                    <p className={classes['number']}>1K+</p>
                    <p className={classes['text']}>Jobs Created</p>
                </div>
            </div>
            
            <p className={classes['footnote']}><span className={classes['bold']}>We're changing the Game</span>
            <span className={classes['pink']}>.</span> One business at a time </p>
        </>
    );
};

export default HomeStatisticsSection;