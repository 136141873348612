import React from "react";
import classes from "./vision.module.css";

const BussSupportVision = () => {
    return (
        <div className={classes['banner__container']}>
            <p className={classes['tag']}>Our Vision</p>
            <h1 className={classes['title']}>Changing the Narrative <br></br>for <span>Women in Business</span></h1>

            <div className={classes['vision-list']}>
                <div className={classes['vision-tabs']}>
                    <div className={classes['vision-tab']}>
                        <div className={classes['dot']}>
                            <img src="/images/square-icon.png" className={classes['dot-icon']}></img>
                        </div>
                        <div className={classes['vision-story']}>
                            <h1 className={classes['story-title']}>Breaking Barriers for Women in Business</h1>
                            <p className={classes['word_three']}>Women face unique challenges in business from limited access to capital to systemic barriers in business networks. Shecluded exists to remove these barriers and transform the narrative around women’s entrepreneurship, mostly in underserved regions
                                like Africa.
                            </p>
                        </div>
                    </div>
                    <div className={classes['vision-tab']}>
                        <div className={classes['dot']}>
                            <img src="/images/square-icon.png" className={classes['dot-icon']}></img>
                        </div>
                        <div className={classes['vision-story']}>
                            <h1 className={classes['story-title']}>Empowering Women Entrepreneurs with Tools for Success</h1>
                            <p className={classes['word_three']}>We are not just a support system, we are changemakers, and partners in women’s entrepreneurial journeys. Our approach focuses on closing the gender gap in business by equipping women with the tools, networks, and financial resources needed to compete on a level.
                            </p>
                        </div>
                    </div>
                </div>

                <div className={classes['vision-b']}>
                    <img src="/images/Rich_Aunty.png" className={classes['vision-illu']}></img>
                </div>
            </div>
        </div>
    );
};

export default BussSupportVision;