import React from "react";
import Button from "../UI/Button";
import classes from "./CallToActionSection.module.css";


const CallToActionSection = () => {

    const signUpBtn = {
        color: '#FFF',
        textAlign: 'center',
        fontFamily: "'Lato', sans-serif",
        fontSize: window.innerWidth <= 1024 ? "14px" :'17px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
        borderRadius: window.innerWidth <= 1024 ? "9px" : '16px',       
        background: '#000',
        width: window.innerWidth <= 1024 ? "9em" : '11.125em',
        margin: window.innerWidth <= 1024 && "auto",
        height: window.innerWidth <= 1024 ? "3.792em" : '4.602em',
        marginRight: '1.5em',
        // display: window.innerWidth <= 1024 && "block",
    };

    const getLoansBtn = {
        borderRadius: window.innerWidth <= 1024 ? "9px" : '16px', 
        border: '1px solid var(--Primary, #EB2590)',
        background: 'var(--P2, #F598D8)',
        color: '#FFF',
        textAlign: 'center',
        fontFamily: "'Lato', sans-serif",
        fontSize: window.innerWidth <= 1024 ? "14px" : '17px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        width: window.innerWidth <= 1024 ? "9em" : '11.125em',
        height: window.innerWidth <= 1024 ? "3.792em" : '4.602em',
        // display: window.innerWidth <= 1024 && "block",
        margin: window.innerWidth <= 1024 && "auto",
        marginTop: '2em',
        marginLeft: "1em",
    };

    return (
        <div className={classes['container']}>
            <div className={classes['sub_container']}>
                <p className={classes['title']}>Join a community of <span className={classes['next_line']}>women loved by many.</span></p>
                <div className={classes['btn_container']}> 
                    <Button text = "Sign Up Now" to = "/" styles = {signUpBtn} />
                    <Button text= "Get Loans" to = "/" styles = {getLoansBtn} />
                </div>
            </div>

        </div>
    );
};

export default CallToActionSection;