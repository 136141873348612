import React from 'react';
import classes from "./CardFrame.module.css";

const CardFrame = (props) => {
    return (
        <div className={classes['card_container']} style={props.style}>
            {props.children}
        </div>
    );
};

export default CardFrame;