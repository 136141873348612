import React from "react";
import classes from "./StoryLeadershipMissionSection.module.css";


const StoryLeadershipMissionSection = () => {
    return (
        <div className={classes['container']}>
            <div className={classes['sub_container']}>
                <div className={classes['partition-one']}>
                    <img src="/images/cash.png" alt="" className={classes['cash']} />
                    <img src="/images/kettle.png" alt="" className={classes['kettle']} />
                    <img src="/images/mission.png" alt="" className={classes['img']} />
                    <div className={classes['step-one']}><span>Grow</span></div>
                    <div className={classes['step-two']}><span>Loan</span></div>
                    <div className={classes['step-three']}><span>Learn</span></div>
                </div>
                <div className={classes['partition-two']}>
                    <p className={classes['title']}>The <span className={classes['mission']}>Mission</span></p>
                    <p className={classes['text-body']}>
                        Shecluded is committed to providing 
                        tailored debt and equity capital solutions 
                        that support the aspirations and ambitions  
                        of women entrepreneurs.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default StoryLeadershipMissionSection;