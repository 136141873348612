import React from "react";
import classes from "./BookingForm.module.css";


const BookingForm = () => {
    return (
        <div className={classes['container']}>
            <p className={classes['title']}>Book a Space</p>
            <p className={classes['note']}>Please fill your application form carefully, We'd respond to your application within 72hrs.</p>
            <div className={classes['sub_container']}>
                <form className={classes['form']}>
                    <div className={classes['row']}>
                        <div className={classes['form_group']}>
                            <label className={classes['label']} htmlFor="fullname">Your Full Name</label>
                            <input type="text" name="fullname" id="fullname" autoComplete="name" placeholder="e.g Theresa Sarah " />
                        </div>
                        <div className={classes['form_group']}>
                            <label className={classes['label']} htmlFor="email">Email Address</label>
                            <input type="email" name="email" id="email" autoComplete="email" placeholder="Email" />
                        </div>
                    </div>
                    <div className={classes['row']}>
                        <div className={classes['form_group_single']}>
                            <label className={classes['label']} htmlFor="type_space">Type of Space</label>
                            <select id="type_space" className={classes['select']} name="type_space">
                                <option>Personal Space</option>
                                <option>2 Sitter Cubicle</option>
                                <option>A meeting Room</option>
                                <option>Sound Proof Office</option>
                                <option>Board Room</option>
                                <option>Private Room</option>
                            </select>
                        </div>
                    </div>
                    <div className={classes['row']}>
                        <div className={classes['form_group_single']}>
                            <label htmlFor="resume" className={classes['label']}>CAC Certificate 
                                <input type="file" id="resume" name="resume" placeholder="Business Certificate  (Perrable Format PDF ,Word doc)" />
                                <div className={classes['file']}>
                                    <p>Business Certificate  (Perrable Format PDF ,Word doc)</p>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={classes['row']}>
                        <div className={classes['form_group']}>
                            <label className={classes['label']} htmlFor="telephone">Telephone</label>
                            <input type="text" name="telephone" id="telephone" placeholder="Telephone" autoComplete="phone" />
                        </div>
                        <div className={classes['form_group']}>
                            <label className={classes['label']} htmlFor="business_name">Business Name</label>
                            <input type="text" name="business_name" id="business_name" placeholder="e.g Alabi Ventures LTD" autoComplete="business" />
                            
                        </div>
                    </div>

                    <input type="submit" name="sub" value="Book Space" className={classes['submit']} />
                    
                    
                </form>
            </div>
        </div>
    );
};

export default BookingForm;