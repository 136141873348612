import React from "react";
import classes from "./RoleDescriptionSection.module.css";


const RoleDescriptionSection = (props) => {
    return (
        <div className={classes['container']}>
            <div className={classes['heading']}>
                <p className={classes['title']}>Role Description</p>
                {props.deadline && <p className={classes['deadline']}>{props.deadline}</p>}
            </div>
            {props.description.map( (desc, index) => <p className={classes['text_body']} key={index}>{desc}</p>)}
        </div>
    );
};

export default RoleDescriptionSection;