import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import classes from "./../Footer/FooterNav.module.css";

const FooterLink = (props) => {

    const clickHandler = () => {
        window.scrollTo(0, 0);
    };
    return(
        <ul className={props.categoryUl}>
            {props.links.map((ftLink) => <li className={classes['footer-nav__li']} key={ftLink.key}>
                {!ftLink.out && <NavLink to= {ftLink.to}  onClick={clickHandler}>{ftLink.text}</NavLink>}
                {ftLink.out && <a href={ftLink.to}>{ftLink.text}</a>}
            </li>)}
        </ul>
    );
};

export default FooterLink;