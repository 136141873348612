import React, { Component } from 'react';
import { useEffect, useState } from "react";
import CareersNav from "./CareersNav";
import ContactDetails from "./ContactDetails";
import LegalNav from "./LegalNav";
import ServicesNav from "./ServicesNav";
import SupportNav from "./SupportNav";
import { useLocation } from "react-router-dom";



const FooterNav = () => {

    

    const [careersNavToggle, setCareersNavToggle] = useState(false);
    const [supportNavToggle, setSupportNavToggle] = useState(false);
    const [servicesNavToggle, setServicesNavToggle] = useState(false);
    const [legalNavToggle, setLegalNavToggle] = useState(false);
    const [contactDetailsToggle, setContactDetailsToggle] = useState(false);
    const currLoc = useLocation();
    // const [loc, setLoc] = useState(currLoc.pathname);

    useEffect(()=> {
            setCareersNavToggle(false);
            setSupportNavToggle(false);
            setServicesNavToggle(false);
            setLegalNavToggle(false);
            setContactDetailsToggle(false);
        
    }, [currLoc]);

    const careersNavTitleClickHandler = () => {
        setCareersNavToggle(!careersNavToggle);
    };
    const supportNavTitleClickHandler = () => {
        setSupportNavToggle(!supportNavToggle);
    };
    const serviceNavTitleClickHandler = () => {
        setServicesNavToggle(!servicesNavToggle);
    };
    const legalNavTitleClickHandler = () => {
        setLegalNavToggle(!legalNavToggle);
    };
    const contactNavTitleClickHandler = () => {
        setContactDetailsToggle(!contactDetailsToggle);
    };

    return (
       <>
            <CareersNav onClick = {careersNavTitleClickHandler} navAccordionState = {careersNavToggle} />
            <SupportNav onClick = {supportNavTitleClickHandler} navAccordionState = {supportNavToggle} />
            <ServicesNav onClick = {serviceNavTitleClickHandler} navAccordionState = {servicesNavToggle} />
            <LegalNav onClick = {legalNavTitleClickHandler} navAccordionState = {legalNavToggle}  />
            <ContactDetails onClick = {contactNavTitleClickHandler} navAccordionState = {contactDetailsToggle} />
       </>
    );
};


export default FooterNav;