import React from "react";
import Button from "../UI/Button";
import classes from "./AnnualCohortSection.module.css";


const AnnualCohortSection = () => {
    return (
        <div id="cohort" className={classes['container']}>
            <div className={classes['top_container']}>
                <div className={classes['text_container']}>
                    <p className={classes['title']}>Annual Cohort</p>
                    <p className={classes['text_body']}>
                    Every year we organize two cohorts to educate upcoming businesses leaders in the same room with industry experts
                    </p>
                    <p className={classes['link']}><a href="/">Register your business today </a></p>
                </div>
                <div className={classes['img_container']}></div>
            </div>
            <div className={classes['bottom_container']}>
                <div className={classes['curriculum']}>
                    <p className={classes['bottom_body']}>Shecluded Hub organizes biannual cohort-based accelerator programs for both experienced and emerging female entrepreneurs. Our goal is to empower visionary women by providing them with seasoned mentorship and leveraging expertise to propel their business ventures to new heights.</p>
                </div>
                <div className={classes['alumni_base']}>
                    <p className={classes['bottom_body']}>Our cohort, organized twice a year, comprises a three-month seminar featuring leading women in finance, manufacturing, software engineering, and agriculture. It parallels our accelerator programs, providing seamless support for successful early starters from curriculum to graduation and alumni engagement.</p>
                </div>
            </div>
            <p className={classes['app_note']}>Applications are Open for the 2024 Cohort !</p>
            <Button text = "Register" to = "/" styles = {{display: "block", margin: "auto", marginTop: "2em", 
                        width: window.innerWidth <= 1024 && "6em", height: window.innerWidth <= 1024 && "2.5em", 
                        fontSize: window.innerWidth <= 1024 && "17px", 
                        borderRadius: window.innerWidth <= 1024 && "9px", background: "#EB2590", color:"#fff"}} />
        </div>
    );
};
export default AnnualCohortSection;