import React from "react";
import FAQ from "../FAQComponents/FAQ";
import classes from "./SupportFormSection.module.css";



const SupportFormSection = () => {

    
    const accordionData = [
        {
            'key': 1, 
            'title' : 'How long does it take to get a response ?', 
            'body' : 'we specialize in providing tailored loans to asset-based businesses across various dynamic industries. Our flexible financing solutions empower companies in sectors such as technology, healthcare, retail, manufacturing, education, hospitality, agriculture, and many more.'
        },
        {
            'key': 2, 
            'title' : 'How do I get a response ?', 
            'body' : 'we specialize in providing tailored loans to asset-based businesses across various dynamic industries. Our flexible financing solutions empower companies in sectors such as technology, healthcare, retail, manufacturing, education, hospitality, agriculture, and many more.'
        },
        {
            'key': 3, 
            'title' : 'Can I shecluded a physical meeting with Shecluded ?', 
            'body' : 'we specialize in providing tailored loans to asset-based businesses across various dynamic industries. Our flexible financing solutions empower companies in sectors such as technology, healthcare, retail, manufacturing, education, hospitality, agriculture, and many more.'
        },
        {
            'key': 4, 
            'title' : 'Is Shecluded Online Support Active on Weekends ?', 
            'body' : 'we specialize in providing tailored loans to asset-based businesses across various dynamic industries. Our flexible financing solutions empower companies in sectors such as technology, healthcare, retail, manufacturing, education, hospitality, agriculture, and many more.'
        },
    
    ];

    return (
        <>
            <div className={classes['container']}>
                <p className={classes['tag']}>SHECLUDED ONLINE SUPPORT </p>
                <p className={classes['title']}>Your Voice <span className={classes['dynamic']}> Deserves an </span> Audience</p>
                <form className = {classes['frmInp']}>
                    <label htmlFor="email"  className={classes['lblEmail']}>Your Email Address</label>
                    <input type="text" id="email" autoComplete="email" placeholder="e.g m57ry@gmail.com" name="email" className={classes['txtEmail']} />

                    <label htmlFor="message" className={classes['lblMessage']}>Message</label>
                    <textarea id="message" placeholder="Type your Message here" name="message" className= {classes['txtMessage']}  >

                    </textarea>

                    <input type="submit" value = "Submit" name = "submit" className = {classes['sub']}   />

                </form>
            </div>
            <FAQ title = "FAQ" contStyle= {{padding: window.innerWidth <= 1024 && "0 0 0 .5em", }} accordionData = {accordionData} pStyle = {{fontSize: "50px", textAlign: "center"}} plusMinus />
        </>
    );
};

export default SupportFormSection;