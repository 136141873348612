import React from "react";
import TitleComponent from "./TitleComponent";
import classes from "./UserOfShecludedSection.module.css";


const UserOfShecludedSection = () => {
    return (
        <>
            <TitleComponent num = {3} text = {" User of Shecluded Account"} />
            <ul className={classes['ul']}>
                <li className={classes['li']}>
                    <b>Account Profile</b> - when you open an account, we may collect your first name, 
                    last name, email address, username and password. You may also provide additional details on your account profile, 
                    such as your profile picture, photos, location data, personal website, Instagram username, Twitter username and 
                    Facebook profile. Applicable legal grounds: contract performance, consent, legitimate interests 
                    (to enable us to perform our obligations and provide our services);
                </li>
                <li className={classes['li']}>
                    <b>Device Information</b> - information that is automatically collected about your device, such as hardware, 
                    operating system, browser, etc. Applicable legal grounds: legitimate interests 
                    (to allow us to provide the content and services on the website), consent, contract performance;
                </li>
                <li className={classes['li']}>
                    <b>Location Information </b> -  information that is automatically collected via analytics systems providers to 
                    determine your location, including your IP address and/or domain name and any external page that referred you to us. 
                    Applicable legal grounds: legitimate interests (to allow us to provide the content and services on the website), 
                    consent, contract performance;
                </li>
                <li className={classes['li']}>
                    <b>Server Log Information</b> - information that is generated by your use of the NM Services that is automatically 
                    collected and stored in our server logs. This may include, but is not limited to, device-specific information, 
                    location information, system activity and any internal and external information related to Nigerian Mockup pages 
                    that you visit. Applicable legal grounds: legitimate interests (to allow us to provide the content and services on 
                    the website), consent, contract performance;
                </li>
                <li className={classes['li']}>
                    <b>Correspondence</b> - information that you provide to us in correspondence, such as when you submit questions or 
                    inquiries and with respect to ongoing customer support. Applicable legal grounds: legitimate interests 
                    (to enable us to perform our obligations and provide our services), consent, contract performance.
                </li>
            </ul>
        </>
    );
};

export default UserOfShecludedSection;