import React, { useEffect, useState } from "react";
import CohortBenefit from "./CohortBenefit";
import classes from "./CohortBenefitsSection.module.css";


const CohortBenefitsSection = () => {


    const benefits = [
        {
            key: 1,
            img_src: "yc.png",
            title: "Access to YC Curriculum ",
            text_body: "Applicants receive YC's curriculum, unlocking entrepreneurial knowledge and potential for growth and success.",
        },
        {
            key: 2,
            img_src: "workspace_p.png",
            title: "Personalized Workspace",
            text_body: "Applicants gain exclusive access to personalized workspaces, finely tuned for productivity, creativity, and entrepreneurial achievement.",
        },
        {
            key: 3,
            img_src: "aws_creds.png",
            title: "AWS Credits",
            text_body: "ventures receive  AWS credits to enhance their tech capabilities and fueling growth through advanced cloud resources.",
        },
        {
            key: 4,
            img_src: "gti.png",
            title: "Guidance to Incorporation",
            text_body: "We're committed to empowering women by providing support in registering business ideas, fostering entrepreneurial success and innovation.",
        },
        {
            key: 5,
            img_src: "gtt.png",
            title: "Guidance to Trademarking",
            text_body: "We're dedicated to helping businesses navigate the trademarking process, safeguarding their brand identity and fostering growth through protected innovation.",
        },
        {
            key: 6,
            img_src: "access.png",
            title: "Access to risk Networks",
            text_body: "Applicants will tap into a vibrant network of fellow entrepreneurs, gaining invaluable connections and insights for their entrepreneurial journey.",
        },
    ];

    useEffect(()=> {
        let timer = setInterval(automate, 2000);

        return () => {
            clearInterval(timer);
        };

    });
   

    const automate = () => {
        setCounter(counter < 5 ? counter + 1 : 0);
    };



    const [counter, setCounter] = useState(0);
    // const max = Math.floor(window.innerWidth / 691.008) - 1;
    // const impactArrLength = window.innerWidth <= 768 ? 5 : 6;
    // (impactArrLength - max)
    

    const leftClickHandler = () => {
        if(counter > 0){
            setCounter(counter - 1);
        }
    };

    const rightClickHandler = () => {
        if(counter < 5){
            setCounter(counter + 1);
        }
    };


    const left = window.innerWidth <= 1024 && - 314.248 * counter + "px";

    const catStyle = {
        marginLeft: window.innerWidth <= 1024 && left,
        transition: "margin-left 1s",
    };

    return (
        <div className={classes['container']}>
            <p className={classes['title']}>Cohort Benefits </p>
            <div className={classes['sub_container']} style={catStyle}>
                {benefits.map((data) => <CohortBenefit data = {data} key = {data.key} />)}
                
            </div>
            <div className={classes['navigators']}>
                <img src="/images/Arrow - Left Circle.png" alt="" className={classes['nav']} onClick={leftClickHandler} />
                <img src="/images/Arrow - Right Circle.png" alt="" className={classes['nav']} onClick={rightClickHandler} />
            </div>
        </div>
    );
};
export default CohortBenefitsSection;