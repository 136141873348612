import React, { Component } from 'react';
import FooterLink from "../UI/FooterLink";
import classes from "./FooterNav.module.css";


const ServicesNav = (props) => {

    const links = [
        {
            key: 1,
            to: '/loan',
            text: 'Loans'
        },
        {
            key: 2,
            to: 'https://hub.shecluded.com/',
            text: 'Rent a Space',
            out: true
        },
        {
            key: 3,
            to: '/shecluded_hub#cohort',
            text: 'Cohort'
        },
        // {
        //     key: 4,
        //     to: '/',
        //     text: 'Sessions'
        // },
    ];

 

    
    const serviceUl = (props.navAccordionState) ? classes['footer-nav__ul'] + " " + classes['display-block'] : classes['footer-nav__ul'];
    const serviceTitle = classes['footer-nav__title'];
    return (
        <div className={classes['footer-nav__container']}>
            <p className={serviceTitle} onClick={props.onClick}>Services</p>        
            <FooterLink links = {links} categoryUl = {serviceUl}  />
        </div>
    );

}


export default ServicesNav;

