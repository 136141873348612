import React from "react";
import classes from "./ContactUsSection.module.css";
import TitleComponent from "./TitleComponent";

const ContactUsSection = () => {
    return (
        <>
            <TitleComponent num = {10} text = {" Contact Us"} />
            <ol className={classes['ol']}>
                <li className={classes['li']}>If you have any questions concerning the terms and conditions, please contact us at <span className={classes['email']}>hello@shecluded.com</span></li>
            </ol>
        </>
    );
};
 
export default ContactUsSection;
