import React, { useEffect, useState } from "react";
import classes from "./ValuesSection.module.css";
import { useSwipeable } from "react-swipeable";



const ValuesSection = (props) => {

    
    useEffect(()=> {
        let timer = setInterval(automate, 2000);

        return () => {
            clearInterval(timer);
        };

    });
   

    const automate = () => {
        setCounter(counter < 5 ? counter + 1 : 0);
    };

    const swipingHandler = (e) => {
        
        if(e.deltaX > 0) {
            rightClickHandler()
        } else {
            leftClickHandler()
            
        }
    };


    const [counter, setCounter] = useState(0);
    // const max = Math.floor(window.innerWidth / 691.008) - 1;
    // const impactArrLength = window.innerWidth <= 768 ? 5 : 6;
    // (impactArrLength - max)
    

    const leftClickHandler = () => {
        if(counter > 0){
            setCounter(counter - 1);
        }
    };

    const rightClickHandler = () => {
        if(counter < 5){
            setCounter(counter + 1);
        }
    };


    const left = (window.innerWidth <= 1024) ? (counter < 5) ? -268 * counter + "px" :  -249 * counter + "px": "auto";


    const catStyle = {
        marginLeft: left,
        transition: "margin-left 1s",
    };

    const handlers = useSwipeable({
        onSwiped: () => {swipingHandler},
    });



   return (
        <div className={classes['container']} style={props.container_style}>
            <p className={classes['values_title']} style={props.title_style}>
                Our Values <span className={classes['define']}>Define</span> <br />
                Our Work and who we are
            </p>
            <p className={classes['side_note']} style={props.side_note_style}>
                These values guide the way we work at Shecluded.
            </p>
            <div {...handlers} className={classes['blocks_container']} style={catStyle}>
                <div className={classes['part']}>
                    <div className={classes['block1']}>
                        <p className={classes['block_title']}>
                            COLLABORATION
                        </p>
                        <p className={classes['block_body']}>
                            Shecluded values the power 
                            of collaboration and team 
                            work, and the strength that 
                            comes from uniting diverse 
                            perspectives, resources, and 
                            energies. We believe that by 
                            working together.
                        </p>
                    </div>
                    <div className={classes['block2']}>
                        <p className={classes['block_title']}>
                            BALANCE
                        </p>
                        <p className={classes['block_body']}>
                            Shecluded recognizes that 
                            success should encompass 
                            all aspects of your life. We 
                            advocate for a harmonious 
                            equilibrium between your 
                            professional and personal 
                            aspirations.
                        </p>
                    </div>
                    <div className={classes['block3']}>
                        <p className={classes['block_title']}>
                            INCLUSIVITY
                        </p>
                        <p className={classes['block_body']}>
                            Inclusivity is a cornerstone 
                            of our ethos at Shecluded. 
                            We celebrate diversity and 
                            will always be committed 
                            to ensuring that all women, 
                            regardless of ethnicity, 
                            religion, race, or 
                            circumstances, have equal 
                            access to opportunities.
                        </p>
                    </div>
                    {/* </div> */}
                    {/* <div className={classes['part']}> */}
                    <div className={classes['block4']}>
                        <p className={classes['block_title']}>
                            GROWTH MINDSET
                        </p>
                        <p className={classes['block_body']}>
                            Embracing challenges and 
                            setbacks as stepping stones to  
                            success is the essence of a 
                            growth mindset at Shecluded. 
                            We encourage you to 
                            approach every obstacle as an 
                            opportunity to learn, adapt, 
                            and evolve.
                        </p>
                    </div>
                    <div className={classes['block5']}>
                        <p className={classes['block_title']}>
                            LEADERSHIP
                        </p>
                        <p className={classes['block_body']}>
                            At Shecluded, leadership 
                            means taking action with 
                            unwavering determination. 
                            We inspire you to be a woman 
                            who seizes opportunities, sets 
                            audacious goals, and executes 
                            with boldness. 
                        </p>
                    </div>
                    <div className={classes['block6']}>
                        <p className={classes['block_title']}>
                            MEANINGFUL WORK
                        </p>
                        <p className={classes['block_body']}>
                            Shecluded empowers women 
                            entrepreneurs to do work and 
                            build businesses that hold 
                            deep significance. We believe 
                            in turning your aspirations 
                            into impactful realities.
                        </p>
                    </div>
                </div>
            </div>
        </div>
   );
};


export default ValuesSection;