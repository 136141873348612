import React from 'react';
import { useState } from "react";
import LoanCalculator from "../components/new components/CalculatorComponents/LoanCalculator";
import PricingCalculator from "../components/new components/CalculatorComponents/PricingCalculator";
import BEPCalculator from "../components/new components/CalculatorComponents/BEPCalculator";



const CalculatorPage = () => {

    const [page, setPage] = useState(1);
    const btnClass = "bg-[#EB2590] text-white p-2 lg:px-3 rounded-full border-none";
    const btnActiveClass = "bg-[#F73]  text-white p-2 lg:px-3 rounded-full focus:outline-none active:outline-none ";

    return(
        <div className="w-[95vw] lg:max-w-[50em] m-auto">
            <div className="flex  justify-between lg:justify-center lg:gap-3  mt-10 text-[12px] font-[550] py-2 ">
                <button onClick={() => setPage(1)} className={page === 1 ? btnActiveClass : btnClass}>Loan ROI Calculator</button>
                <button onClick={() => setPage(2)} className={page === 2 ? btnActiveClass : btnClass}>Pricing Calculator</button>
                <button onClick={() => setPage(3)} className={page === 3 ? btnActiveClass : btnClass}>BEP Calculator</button>
            </div>

            {page === 1 && <LoanCalculator />}
            {page === 2 && <PricingCalculator />}
            {page === 3 && <BEPCalculator />}
        </div>
    );

};

export default CalculatorPage;