import React, { useEffect, useState } from "react";
// import InPageLink from "../UI/InPageLink";
import classes from "./ImpactSoFarSection.module.css";
import { useSwipeable } from "react-swipeable";


const ImpactSoFarSection = () => {


    useEffect(()=> {
        let timer = setInterval(()=>{}, 2000);

        return () => {
            clearInterval(timer);
        };

    });
   

    // const automate = () => {
    //     setCounter(counter < 5 ? counter + 1 : 0);
    // };

    const swipingHandler = (e) => {
        
        if(e.deltaX > 0) {
            rightClickHandler()
        } else {
            leftClickHandler()
            
        }
    };


    const [counter, setCounter] = useState(0);
    // const max = Math.floor(window.innerWidth / 691.008) - 1;
    // const impactArrLength = window.innerWidth <= 768 ? 5 : 6;
    // (impactArrLength - max)
    

    const leftClickHandler = () => {
        if(counter > 0){
            setCounter(counter - 1);
        }
    };

    const rightClickHandler = () => {
        if(counter < 1){
            setCounter(counter + 1);
        }
    };


    const left = (window.innerWidth <= 1024) ? (counter < 5) ? - 279.152 * counter + "px" : -250.152 * counter + "px" : (counter < 5) ? -691.008 * counter + "px" : -585.008 * counter + "px";

    const catStyle = {
        marginLeft: left,
        transition: "margin-left 1s",
    };

    const handlers = useSwipeable({
        onSwiped: () => {swipingHandler}
    });

  

    return (
        <div className={classes['container']}>
            <p className={classes['title']}>
                Impact so far 
            </p>


            <div {...handlers} className={classes['impacts']} style={catStyle}>
                <div className={classes['impact_1']}>
                    <img src="/images/giz.png" alt="" className={classes['img']} />
                    <p className={classes['impact_title']}>An Accelerator by <span className={classes['next_line']}>GIZ for 35 women</span></p>
                    <p className={classes['impact_body']}>Joining Forces With GIZ To Champion Women In Business : A Dynamic Partnership Cultivating the Growth of Over 30 Entrepreneurial Women, Promoting Leadership, And Advancing Economic Empowerment Globally.</p>
                    <p className={classes['link']}><a href="/" >Visit the blog to read article</a></p>
                </div>
                     
                <div className={classes['impact_2']}>
                    <img src="/images/netherlands.png" alt="" className={classes['img']} />
                    <p className={classes['impact_title']}>Create and Improve <span className={classes['next_line']}>Jobs for 3000 women within 2 years</span></p>
                    <p className={classes['impact_body']}>Collaborating with CFYE to Empower Women in Business: A powerful alliance aimed at empowering 1000 women, fostering the creation of 2600 jobs. This empowerment is executed through Shecluded's Incubator, Accelerator, Job Readiness, and Job Creation Initiatives.</p>
                    <p className={classes['link']}><a href="/" >Visit the blog to read article</a></p>
                </div>
                {/* <div className={classes['impact_1']}>
                    <img src="/images/giz.png" alt="" className={classes['img']} />
                    <p className={classes['impact_title']}>An Accelerator by <span className={classes['next_line']}>GIZ for 35 women</span></p>
                    <p className={classes['impact_body']}>Joining forces with GIZ to champion women in business: A dynamic partnership fostering over 30 entrepreneurial women, leadership, and economic empowerment worldwide.</p>
                    <p className={classes['link']}><a href="/" >Visit the blog to read article</a></p>
                </div>
                <div className={classes['impact_2']}>
                    <img src="/images/netherlands.png" alt="" className={classes['img']} />
                    <p className={classes['impact_title']}>Netherlands' 1000 <span className={classes['next_line']}>women Job Creation</span></p>
                    <p className={classes['impact_body']}>Joining forces with GIZ to champion women in business: A dynamic partnership fostering over 30 entrepreneurial women, leadership, and economic empowerment worldwide.</p>
                    <p className={classes['link']}><a href="/" >Visit the blog to read article</a></p>
                </div>
                <div className={classes['impact_1']}>
                    <img src="/images/giz.png" alt="" className={classes['img']} />
                    <p className={classes['impact_title']}>An Accelerator by <span className={classes['next_line']}>GIZ for 35 women</span></p>
                    <p className={classes['impact_body']}>Joining forces with GIZ to champion women in business: A dynamic partnership fostering over 30 entrepreneurial women, leadership, and economic empowerment worldwide.</p>
                    <p className={classes['link']}><a href="/" >Visit the blog to read article</a></p>
                </div>
                <div className={classes['impact_2']}>
                    <img src="/images/netherlands.png" alt="" className={classes['img']} />
                    <p className={classes['impact_title']}>Netherlands' 1000 <span className={classes['next_line']}>women Job Creation</span></p>
                    <p className={classes['impact_body']}>Joining forces with GIZ to champion women in business: A dynamic partnership fostering over 30 entrepreneurial women, leadership, and economic empowerment worldwide.</p>
                    <p className={classes['link']}><a href="/" >Visit the blog to read article</a></p>
                </div> */}
                
                
            </div>

            <div className={classes['navigators']}>
                <img src="/images/Arrow - Left Circle.png" alt="" className={classes['nav']} onClick={leftClickHandler} />
                <img src="/images/Arrow - Right Circle.png" alt="" className={classes['nav']} onClick={rightClickHandler} />
            </div>
        </div>
    );
};

export default ImpactSoFarSection;