import React from "react";
import classes from "./call_to_action.module.css";
import ImageDoorShape from "../UI/ImageDoorShape";
import Button from "../UI/Button";
import { NavLink } from 'react-router-dom';
const BussSuppActionBtn = () => {
 

    const btnStyles = {
        display: window.innerWidth <= 1024 ? 'block' : 'block', 
        margin: window.innerWidth <= 1024 ? 'auto' : 'auto',
        marginBottom: window.innerWidth <= 1024 ? '0' : '0',
        marginTop: window.innerWidth <= 1024 ? '2.875em' : '2.875em',
        borderRadius: window.innerWidth <= 1024 ? "9px" :'16px',
        border: '2px solid var(--Primary, #EB2590)',
        background: 'var(--Primary, #EB2590)',
        color: '#fff',
        padding: window.innerWidth <= 1024 ? ".6em" : ".9em 1.746em",
        fontSize: window.innerWidth <= 1024 ? "14px" : '21px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        width: window.innerWidth <= 1024 ? '10.25em' : "14.063em",
        height: window.innerWidth <= 1024 ? "2.8em" :"3.375em",
        textAlign: "center",
    };


    return (
        <div className={classes['banner__container']}>
             <div className={classes['action_tab']}>
             <h1 className={classes['word_two']}>Have a Program for 
              <br></br>Women Entrepreneurs?</h1>
              <a href = {"/support"} style = {btnStyles}>Contact us today</a>
             </div>
        </div>
    )
}

export default BussSuppActionBtn;