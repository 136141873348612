import React from "react";
import {Button} from '@chakra-ui/react'

const ButtonItem = ({btnLink,btnSize, btnText, btnType, btnAs, targetLink, onClickFn,leftIconValue, rightIconValue, letterSpacingValue, noMarginBottom, disabledValue, secondaryButton}) => {
    const pseudoStyle = {
        bg: "#c40074",
        color:"#fff", 
        textDecoration: "none", 
        outline: "none", 
        transform: "translateY(-3px)"
    }

    return (
        <Button
            mb={noMarginBottom ? "0" :"4"} 
            size={btnSize} 
            color={secondaryButton ? "#EC0082" : "#fff"} 
            bg={secondaryButton ? "#fff" : "#EC0082"} 
            border={secondaryButton ? "1px solid #EC0082" : null }
            onClick={onClickFn}
            letterSpacing={letterSpacingValue}
            borderRadius="9999px"
            position="relative"
            transition="all 0.3s"
            px={60}
            py={15}
            isDisabled={disabledValue}

            //when using button as a link
            as={btnAs} 
            href={btnLink} 
            target={targetLink}

            //when using button within a form

            type={btnType}

            // Button icons
            leftIcon={leftIconValue}
            rightIcon={rightIconValue}

            // Pseudo properties
            _hover={pseudoStyle} 
            _active={pseudoStyle}
            _focus={pseudoStyle} 
        > 
            {btnText} 
        </Button>
    )
}

export default ButtonItem;