import React, { useState } from "react";
import classes from "./ShecludedIncubatorSection.module.css";


const ShecludedIncubatorSection = () => {


    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className={classes['container']}>
            <div className={classes['top_container']}>
                <div className={classes['text_container']}>
                    <p className={classes['title']}>Shecluded Incubator</p>
                    <p className={classes['text_body']}>
                        We incubate operating  businesses with a revenue records beyond 7 months . We do our best to enlist each eligible applicant after their applications are sent in.
                    </p>
                    <p className={classes['link']}><a href="/">Register your business today </a></p>
                </div>
                <div className={classes['img_container']}></div>
            </div>
            <div className={classes['tab']}>
                <div onClick={() => { setActiveTab(0) }} className={ activeTab === 0 ? classes['tab1'] + " " + classes['active']: classes['tab1']}>
                Curriculum
                </div>
                <div onClick={() => { setActiveTab(1) }} className={ activeTab === 1 ? classes['tab2'] +  " " + classes['active']: classes['tab2']}>
                Alumni Base
                </div>
            </div>
            <div className={classes['bottom_container']} style={{marginLeft: activeTab === 0 ? (window.innerWidth <= 1024) && "0" : (window.innerWidth <= 1024) && "-100vw"}}>
                <div className={classes['curriculum']} >
                    <p className={classes['bottom_logo']}></p>
                    <p className={classes['bottom_title']}>Curriculum</p>
                    <p className={classes['bottom_body']}>Our curriculum is meticulously designed to cater to business needs at every level. Whether you're a beginner or an experienced professional, our courses are crafted to provide valuable insights and skills that will empower you to excel in the dynamic world of business.</p>
                </div>
                <div className={classes['alumni_base']}> 
                    <p className={classes['bottom_logo']}></p>
                    <p className={classes['bottom_title']}>Alumni Base</p>
                    <p className={classes['bottom_body']}>Our incubator boasts a history of cultivating top-tier entrepreneurs. Like our accelerators, we provide a seamless, supportive journey for early starters. From our comprehensive curriculum to program development, graduation, and ongoing alumni engagement, we equip entrepreneurs for success.</p>
                </div>
            </div>
        </div>
    );
};

export default ShecludedIncubatorSection;